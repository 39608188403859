export default {
  termOfService: 'Срок оказания услуг',
  privacyPolicy: 'Политика конфиденциальности',
  xCommunications: 'xСвязь',
  xCommunicationsInvestorRelations: 'Связи с инвесторами',
  xCommunicationsMediaRequest: 'Медиа-запрос',
  xCommunicationsOurPositionPrivacy: 'Наша позиция в отношении конфиденциальности',
  xCommunicationsKnowledgeBase: 'База знаний',
  xCommunicationsOurPosition: 'Наша позиция в отношении киберзапугивания',
  premiumTools: 'Премиум Инструменты',
  premiumToolsSayHolaLiveChatPlugin: 'Плагин Live Chat',
  premiumToolsSayHolaBlogPlugin: 'Плагин sayHola Blog',
  premiumToolsTogetherSayHola: 'вместе SayHola',
  premiumToolsLiveLeagueApp: 'Приложение Live League',
  programs: 'Программы',
  programsAdvertiseHola: 'Рекламируйте с Hola',
  programsFreelancerProgram: 'Программа для фрилансеров',
  programsAffiliateRewardsProgram: 'Партнерская программа вознаграждений',
  terms: 'Условия',
  termsAdsPolicy: 'Политика в отношении рекламы',
  termsPrivacyPolicy: 'Политика конфиденциальности',
  termsCommunityStandards: 'Стандарты Сообщества',
  termsTermsUse: 'Условия эксплуатации',
  termsLoyaltyRewardsTerms: 'Политика вознаграждений',
  otp_unconfirmed: 'Отп не подтвержден, попробуйте еще раз',
  pin_unconfirmed: 'Неверный PIN-код',
  otpDescription: 'Вам отправлен sms-код. Пожалуйста, введите код ниже.',
  otpEnterYourOtp: 'Введите ваш код OTP',
  otpEnterYourPin: 'Введите свой пин-код',
  verify_otp: 'Подтвердить',
  otpDontReceiveCode: 'Не получили код?',
  otpRequestSMSAgain: 'Запросить СМС еще раз',
  otpRequestNewCode: 'Запросить новый код в',
  copyright: 'xСвязь. Все права защищены',
  enterPhoneNumber: 'Пожалуйста, выберите свою страну и введите свой полный номер телефона.',
  login: 'Авторизоваться',
  validationSelectFile: 'Выбрать и загрузить фото',
  validationSameAs: 'Пароли не соответствуют',
  validationMaxLengthField: 'Максимальное количество символов поля :field - :count',
  validationMinLengthField: 'Минимальное количество символов поля :field - :count',
  validationRequired: 'Заполните это поле',
  validationEmail: 'Введите действующий адрес электронной почты',
  validationUrl: 'Введите действительный URL',
  needChangePhone: 'Вам нужно изменить свой номер?',
  recovery: 'Восстановление',
  needMoreInfo: 'Нужна дополнительная информация?',
  learnMore: 'Узнать больше',
  startUsingHola: 'Начните использовать sayHola для просмотра <br> Социального пространства',
  startUsingHolaFollow: 'Начните использовать sayHola, чтобы подписаться',
  socialSpaceWord: 'социальное пространство',
  socialSpaceInvite: 'Приглашение в социальное пространство',
  socialSpaceInviteMessage: 'безопасное и безопасное личное пространство для бизнеса, знаменитостей, блоггеров, влиятельных лиц, сообщества и отдельных лиц без комментариев и фейковых новостей',
  enterPhone: 'Введите телефон',
  footerPrivateAppJobsPage: 'Возможности работы',
  socialPost: {
    image: 'Изображение',
    isEnabledComments: 'Комментарии',
    isEnabledLikes: 'Кнопка "Нравится"',
    newPostTitle: 'Новый пост',
    placeholder: 'Начните свое сообщение и #хэштеги здесь',
    editPostTitle: 'Редактировать пост',
    deleteButton: 'Удалить сообщение',
    editButton: 'Редактировать пост',
    reportButton: 'Отчет',
    reportConfirmation: 'Вы уверены, что хотите пожаловаться на это сообщение?',
    reportText: 'Спасибо, что дали нам знать. Ваш отзыв помогает в практике использования сообщества. Другие шаги, которые вы можете предпринять, - это заблокировать пользователя. После этого вы не сможете видеться или связываться друг с другом.',
    likes: 'нравится',
    hideComments: 'Скрыть комментарии',
    viewLatestComments: 'Посмотреть последние комментарии',
    reply: 'Ответить',
    replies: 'ответы',
    placeholderComment: 'Написать комментарий',
    placeholderReply: 'Ответить {name}',
    commentReportConfirmation: 'Вы уверены, что хотите пожаловаться на этот комментарий?'
  },
  btnHide: 'Скрывать',
  btnShow: 'Показать',
  btnReply: 'Ответить',
  buttonEdit: 'Редактировать',
  socialComment: {
    deleteConfirmation: 'Вы уверены, что хотите удалить комментарий <b>{text}</b>?'
  },
  buttonLoadMore: 'Загрузи больше',
  socialSpace: {
    searchTitle: 'Искать',
    accounts: 'Аккаунты',
    places: 'Места',
    tags: 'Теги'
  },
  searchNoResults: 'Никаких результатов',
  startTypingForSearch: 'Начните вводить для поиска',
  userStatus: {
    btnAdd: 'Добавить статус',
    tabSetStatus: 'Установить статус',
    tabCreateStatus: 'Создать статус',
    titlePlaceholder: 'Заголовок статуса',
    textPlaceholder: 'Начните свой статус здесь (не более 250 символов)',
    editStatusTitle: 'Изменить статус'
  },
  buttonCancel: 'Отмена',
  buttonDelete: 'Удалить',
  blogSubscribeBtn: 'Подписывайся',
  blogUnsubscribeBtn: 'Отписаться',
  popupConfirmTitle: 'Подтверждение',
  popupConfirmText: 'Вы уверены?',
  buttonAdd: 'Добавлять',
  buttonConfirm: 'Подтверждать',
  socialProfile: {
    categoryCompany: 'Компания',
    categoryIndividual: 'Индивидуальный',
    extendedSettings: 'Расширенные настройки',
    firstName: 'Имя',
    lastName: 'Фамилия',
    nickname: 'Ник',
    isActive: 'Социальный профиль (Live)',
    profileViewOptions: 'Параметры просмотра профиля',
    isPublic: 'Общедоступный (с возможностью поиска)',
    isChatActive: 'Напиши мне (скажи Хола)',
    showFollowers: 'Количество подписчиков',
    showSocialSpace: 'Показать социальное пространство',
    textLocation: 'Местоположение (город, страна)',
    pictureError: 'Пожалуйста, загрузите изображение вашего профиля',
    errorNicknameExists: 'Профиль с таким ником уже существует',
    editTitle: 'Редактировать мой премиум-профиль',
    btnCancelRequest: 'Отменить запрос',
    btnFollow: 'Следовать',
    btnUnfollow: 'Отписаться',
    followers: 'Подписчики',
    pending: 'В ожидании',
    createTitle: 'Создать новый профиль',
    successCreateProfile: 'Профиль успешно создан!',
    successEditProfile: 'Информация в профиле обновлена!',
    successDeleteProfile: 'Профиль успешно удален!',
    showCountPosts: 'Количество сообщений',
    showCountFollowing: 'После подсчета',
    socialProfile: 'Социальный профиль'
  },
  authLogout: 'Выйти',
  logout: {
    confirmation: 'Вы действительно хотите выйти?',
    allSessions: 'Выйти из всех сеансов',
    title: 'Выйти'
  },
  buttonCreate: 'Создать',
  buttonRegistration: 'Завершить регистрацию',
  buttonEnter: 'Входит Хола.',
  buttonPay: 'Платить',
  buttonUpgrade: 'Обновление',
  buttonCopy: 'Копировать',
  buttonSave: 'Сохранить',
  buttonSend: 'Отправить',
  successTitle: 'Успешно',
  errorTitle: 'Ошибка',
  error: 'Извините, что-то пошло не так',
  errorEmailInvalid: 'Введите корректный электронный адрес',
  errorPhoneInvalid: 'Неправильный номер телефона',
  errorEmptyField: 'Это поле обязательно к заполнению',
  errorAlphanumericDashUnderscore: 'Это поле может содержать только буквенно-цифровые символы, тире и подчеркивание [a-z0-9-_]',
  category: 'Категория',
  chooseCategory: 'Выберите категорию',
  chooseCategoryError: 'Пожалуйста, выберите категорию!',
  errorMinLength_begin: 'Убедитесь, что в этом поле есть не менее',
  errorMaxLength_begin: 'Убедитесь, что в этом поле не более',
  errorLength_end: 'символы',
  formAttach: 'Прикрепить',
  successCreatePost: 'Сообщение было успешно создано',
  successEditPost: 'Сообщение успешно отредактировано',
  post: {
    author: 'Автор',
    createTitle: 'Создать новый пост',
    footerImages: 'Изображения нижнего колонтитула',
    headerImage: 'Заглавное изображение',
    isPublished: 'Опубликовано',
    subtitle: 'Подзаголовок',
    text: 'Текст',
    textPlaceholder: 'Начните свой блог здесь. Первая буква жирная и большая',
    title: 'Заголовок',
    delete: 'Удалить сообщение',
    edit: 'Редактировать пост',
    footerVideos: 'Видео в нижнем колонтитуле',
    tabPhotos: 'Фото',
    tabVideos: 'Видео'
  },
  deleteConfirmation: 'Вы уверены, что хотите удалить <b>{name}</b>?',
  postNotFound: 'Сообщение не найдено!',
  createProfile: 'Создать профиль',
  removeProfile: 'Удалить профиль',
  editProfile: 'Редактировать профиль',
  createPost: 'Создать сообщение',
  create: 'Создать',
  dontHaveProfile: 'У вас нет профиля в социальных сетях.',
  chooseProfile: 'Выберите социальный профиль',
  needSubscribeMessage: 'Для просмотра профиля необходимо подписаться!',
  profilePost: 'Пост',
  profileFollowing: 'Подписки',
  profileFollowers: 'Подписчики',
  emptyPostList: 'Список сообщений пуст',
  navigationHome: 'главная',
  navigationLiked: 'лайки',
  navigationTrending: 'в тренде',
  navigationFavourites: 'избранное',
  navigationFollowers: 'подписчики',
  postSettings: 'Настройки публикации',
  noResultSearch: 'результаты не найдены',
  profiles: 'Профили',
  location: 'Локация',
  tags: 'Теги',
  showMore: 'Показать больше',
  remove: 'Удалить',
  uploadPhoto: 'Загрузите фото',
  dragAndDrop: 'или просто перетащите',
  emptyList: 'Список пуст!',
  open: 'Открыты',
  advertiseUsingCampaigns: 'Рекламируйте здесь, используя кампании',
  postButton: 'Пост',
  settingsButton: 'Настройки',
  viewButton: 'Просмотр',
  deleteButton: 'Удалить',
  posts: 'Посты',
  profileSettings: 'Настройки профиля',
  backgroundPicture: 'Фоновое изображение',
  profilePicture: 'Изображение профиля',
  fillUserInfo: 'Пожалуйста, заполните информацию:',
  requiredFields: 'Обозначает обязательное поле.',
  formFirstName: 'Имя',
  formLastName: 'Фамилия',
  formNickName: 'Имя пользователя',
  profileEmailInfo: 'Ваш адрес электронной почты будет использован только для восстановления. Введя свой адрес электронной почты, вы соглашаетесь с политикой конфиденциальности и условиями sayHola. Ссылки будут отправлены на вашу электронную почту для восстановления.',
  formEmail: 'Ваш адрес электронной почты',
  error_email_exists: 'Пользователь с этим адресом электронной почты уже существует',
  backToHomePage: 'Вернуться на главную',
  pageNotFoundMessage: 'Мы не можем найти страницу, которую вы ищете',
  resendOtpMessage: 'Было отправлено еще одно SMS.',
  buttonDone: 'Готово',
  viewProfile: 'Просмотреть профиль',
  copyClipboardSuccess: 'Успешная копия в буфер обмена',
  copyClipboardError: 'Ошибка копирования в буфер обмена',
  loginByQRCodeMessage: 'QR-код для входа через смартфон',
  authOption: 'Пожалуйста, выберите вариант для входа.',
  newRegistration: 'новая регистрация',
  registrationOption: 'Для регистрации требуется адрес электронной почты и имя пользователя для использования услуг. После входа в систему у вас есть возможность синхронизации с другими приложениями для обмена сообщениями, используя ваш номер телефона.',
  loginWithApp: 'войти через приложение',
  loginWithAppOption: 'Откройте Hola на своем телефоне, перейдите в меню, расположенное в правом верхнем углу приложения. Щелкните значок QR-кода. Наведите телефон на этот экран, чтобы подтвердить вход.',
  recoveryOption: 'восстановление',
  recoveryOptionText: 'Для восстановления Введите свой зарегистрированный адрес электронной почты, чтобы войти с помощью горячей ссылки. Узнайте больше, посетив раздел часто задаваемых вопросов',
  enterEmailRecovery: 'Введите зарегистрированный адрес электронной почты для восстановления:',
  notFoundUserLabel: 'Пользователь с таким адресом электронной почты не найден',
  successSendRecoveryEmailMessage: 'Проверьте почту и следуйте инструкциям!',
  chatBots: 'Чат-боты',
  BdtlAgencyProgram: 'Агентская программа БДТЛ',
  agencyTerms: 'Условия агентства',
  holaExtensions: 'Хола Расширение',
  holaBlog: 'Hola Блог',
  browserExtensions: 'Расширения браузера',
  agencyTermsNew: 'Политика агентства',
  earnPrograms: 'Программы заработка',
  rewardsProgram: 'Программа вознаграждений',
  despaxoTerms: 'Условия Despaxo',
  faqWord: 'Часто задаваемые вопросы',
  paidServices: 'Базовые и платные услуги',
  multiapp: 'Синхронизация нескольких приложений',
  opportunities: 'Возможности',
  despaxoDrivers: 'Драйверы Despaxo',
  generalTerms: 'Общие условия',
  apps: 'Приложения',
  web: 'Интернет',
  desktop: 'Рабочий стол',
  blogSyncPlugin: 'Плагин синхронизации блога',
  together: 'Вместе',
  chatbotConstructor: 'Конструктор чат-ботов',
  enterpriseProgram: 'Корпоративная программа',
  bdtlProgram: 'Программа БДТЛ',
  despaxoBot: 'Программа синхронизации ботов Despaxo',
  extendetTerms: 'Расширенные условия',
  despaxoPolicy: 'Политика Despaxo',
  despaxoDriverPolicy: 'Политика Despaxo в отношении драйверов',
  driverPolicy: 'Политика драйверов',
  administrativeStatement: 'Административное заявление',
  despaxoDefinitions: 'Определения Despaxo',
  operatingDespaxo: 'Операционный Despaxo'
}
