<template>
  <div class="feed-item" >
    <div class="feed-item__date">
      <span>{{ moment(post.createdAt).format('DD MMMM') }}</span>
    </div>
    <div class="feed-item__wrap">
      <div class="feed-item__top">
        <div class="feed-item__top-circles">
          <v-popover placement="bottom-start" popoverClass="no-arrow" v-if="isShowActionMenu">
            <div class="action-arrow">
              <svg fill="#00b764" width="16px" height="16px" xmlns="http://www.w3.org/2000/svg"
                   viewBox="0 0 426.667 426.667"
                   class="custom-icon tooltip-target">
                <circle cx="42.667" cy="213.333" r="42.667"></circle>
                <circle
                    cx="213.333" cy="213.333" r="42.667"></circle>
                <circle cx="384" cy="213.333"
                        r="42.667"></circle>
              </svg>
            </div>
            <MenuBase
                slot="popover" v-close-popover
                :options="menu"
            />
          </v-popover>
        </div>
        <div class="feed-item__top-time" v-if="post.createdAt">
          {{ moment(post.createdAt).format("hh:mm A") }}
        </div>
      </div>
      <div class="feed-item__img" v-if="post.image">
        <img :src="post.image" alt="">
      </div>
      <div class="feed-item__info" v-if="post.text">
        <span>
          <span class="feed-item__info-nickname" v-if="nickname">{{ nickname }}</span>
        <span>{{ post.text }}</span>
        </span>
      </div>
      <div class="feed-item__buttons action">
        <div class="feed-item__buttons-left">
          <div class="action-like" v-if="post.isEnabledLikes">
            <button class="action-like__button"
                    :class="{ 'active': post.isLiked }"
                    @click="onLike(post)">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 46.949 46.949" style="enable-background:new 0 0 46.949 46.949;" xml:space="preserve">
                <path d="M42.091,1.698c-5.943-5.752-14.715,4.613-17.828,8.751l4.015,7.163l-4.586-6.39    c-0.008,0.012-0.019,0.026-0.027,0.038C21.777,8.777,11.023-4.688,4.598,2.015C-2.318,9.23,6.256,26.776,23.644,46.127    l0.712,0.799c0.129,0.142,0.359-0.436,0.359-0.436C41.363,26.502,49.272,8.648,42.091,1.698z"/>
              </svg>
            </button>
            <button class="action-like__count">
              {{ post.likes }} {{ $t('socialPost.likes') }}
            </button>
          </div>
          <button class="action-comment"
                  v-if="post.isEnabledComments"
                  @click="isShowCommentForm = !isShowCommentForm">
          </button>
        </div>
        <div class="feed-item__buttons-right">
          <button class="action-share" @click.prevent="onShare(post)">
            <svg  fill="#3b3b3b" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
                 id="Layer_1" x="0px" y="0px" viewBox="0 0 320.002 320.002"
                 style="enable-background:new 0 0 320.002 320.002;" xml:space="preserve">
              <path id="XMLID_7_" d="M51.213,175.001h173.785c8.284,0,15-6.716,15-15c0-8.284-6.716-15-15-15H51.213l19.394-19.394 c5.858-5.858,5.858-15.355,0-21.213c-5.857-5.858-15.355-5.858-21.213,0L4.396,149.393c-0.351,0.351-0.683,0.719-0.997,1.103 c-0.137,0.167-0.256,0.344-0.385,0.515c-0.165,0.22-0.335,0.435-0.488,0.664c-0.14,0.209-0.261,0.426-0.389,0.64 c-0.123,0.206-0.252,0.407-0.365,0.619c-0.118,0.22-0.217,0.446-0.323,0.67c-0.104,0.219-0.213,0.435-0.306,0.659 c-0.09,0.219-0.164,0.442-0.243,0.664c-0.087,0.24-0.179,0.477-0.253,0.722c-0.067,0.222-0.116,0.447-0.172,0.672 c-0.063,0.249-0.133,0.497-0.183,0.751c-0.051,0.259-0.082,0.521-0.119,0.782c-0.032,0.223-0.075,0.443-0.097,0.669 c-0.048,0.484-0.073,0.971-0.074,1.457c0,0.007-0.001,0.015-0.001,0.022c0,0.007,0.001,0.015,0.001,0.022 c0.001,0.487,0.026,0.973,0.074,1.458c0.022,0.223,0.064,0.44,0.095,0.661c0.038,0.264,0.069,0.528,0.121,0.79 c0.05,0.252,0.119,0.496,0.182,0.743c0.057,0.227,0.107,0.456,0.175,0.681c0.073,0.241,0.164,0.474,0.248,0.71 c0.081,0.226,0.155,0.453,0.247,0.675c0.091,0.22,0.198,0.431,0.3,0.646c0.108,0.229,0.21,0.46,0.33,0.685 c0.11,0.205,0.235,0.4,0.354,0.599c0.131,0.221,0.256,0.444,0.4,0.659c0.146,0.219,0.309,0.424,0.466,0.635 c0.136,0.181,0.262,0.368,0.407,0.544c0.299,0.364,0.616,0.713,0.947,1.048c0.016,0.016,0.029,0.034,0.045,0.05l45,45.001 c2.93,2.929,6.768,4.394,10.607,4.394c3.838-0.001,7.678-1.465,10.606-4.393c5.858-5.858,5.858-15.355,0.001-21.213L51.213,175.001z"/>
                 <path id="XMLID_8_" d="M305.002,25h-190c-8.284,0-15,6.716-15,15v60c0,8.284,6.716,15,15,15s15-6.716,15-15V55h160v210.001h-160 v-45.001c0-8.284-6.716-15-15-15s-15,6.716-15,15v60.001c0,8.284,6.716,15,15,15h190c8.284,0,15-6.716,15-15V40 C320.002,31.716,313.286,25,305.002,25z"/>
            </svg>
          </button>
          <button class="action-favourite"
                  :class="{ 'active': post.isFavourite }"
                  @click.prevent="addToFavourites(post)">
            <svg stroke="#3b3b3b" stroke-width="50" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                 x="0px" y="0px" viewBox="0 0 512 512" xml:space="preserve">
               <path
                   d="M416.667,0H95.334c-8.284,0-15,6.716-15,15v482c0,6.067,3.655,11.536,9.26,13.858c1.856,0.769,3.805,1.142,5.737,1.142 c3.903,0,7.74-1.523,10.609-4.394l150.063-150.062L406.06,507.606c4.29,4.291,10.741,5.573,16.347,3.252    c5.605-2.322,9.26-7.791,9.26-13.858V15C431.667,6.716,424.952,0,416.667,0z"
                />
              </svg>
          </button>
        </div>
      </div>
      <SocialCommentInput
          v-if="isShowCommentForm"
          :postId="post.id"
          @close="isShowCommentForm = false"/>
      <SocialCommentsList :postId="post.id"/>
    </div>
  </div>
</template>

<!-- scripts-->
<script>
import { mapActions, mapGetters } from 'vuex'

import MenuBase from '@/components/atoms/MenuBase.vue'
import SocialCommentInput from '../components/CommentInput.vue'
import SocialCommentsList from '../components/SocialCommentsList.vue'

export default {
  name: 'post-list-item',
  data () {
    return {
      isShowCommentForm: false,
      menu: [
        {
          name: this.$t('socialPost.editButton'),
          icon: 'edit',
          handler: () => {
            this.$router.push({
              name: 'social-post-edit',
              params: {
                nickname: this.nickname,
                postId: this.post.id
              }
            })
          }
        },
        {
          name: this.$t('socialPost.deleteButton'),
          icon: 'delete',
          handler: () => {
            this.$modal.show('delete-social-post', {
              postId: this.post.id,
              text: this.post.text,
              nickname: this.nickname
            })
          }
        }
      ]
    }
  },
  props: {
    post: {
      type: Object,
      required: true
    },
    nickname: {
      type: String
    }
  },
  components: {
    SocialCommentInput,
    SocialCommentsList,
    MenuBase
  },
  computed: {
    ...mapGetters({
      profile: 'profile/profileData',
      profilePosts: 'profile/profilePosts',
      myProfile: 'user/myProfile'
    }),
    isShowActionMenu () {
      if (this.post.owner) {
        // for likes and favourites page
        return this.post.owner === this.myProfile.userId
      } else {
        // for posts list page (profile)
        return this.profile.isOwn
      }
    }
  },
  created () {},
  methods: {
    ...mapActions({
      toggleLike: 'profile/toggleLike',
      getProfilePosts: 'profile/getProfilePosts',
      addFavourites: 'profile/addToFavourites',
      removeFromFavourites: 'profile/removeFromFavourites'
    }),
    onLike (post) {
      this.toggleLike({
        nickname: this.nickname,
        postId: post.id,
        isLiked: post.isLiked
      })
    },
    onShare (post) {
      const link = window.location.origin + '/' + post.profileDetail.nickname
      this.$modal.show('share', {
        value: link
      })
    },
    addToFavourites (post) {
      if (!post.isFavourite) {
        this.addFavourites({
          profile_id: post.profile,
          data: {
            article: post.id,
            profile: post.profile,
            user_id: this.myProfile.userId
          }
        })
      } else {
        this.removeFromFavourites({
          profile_id: post.profile,
          data: {
            article: post.id,
            profile: post.profile,
            user_id: this.myProfile.userId
          }
        })
      }
    }
  }
}
</script>

<!-- style-->
<style lang="scss" scoped>
.feed{
  &-item{
    width: 100%;
    margin-bottom: 20px;
    &__wrap{
      width: 100%;
      background: $white;
    }
    &__date{
      text-align: center;
      margin: 15px 0;
      color: #fff;
      span {
        display: inline-block;
        background-color: rgba(0,183,100,0.8);
        padding: 6px 15px 8px;
        border-radius: 15px;
        font-size: 16px;
        @include respondTo(600px){
          font-size: 14px;
        }
      }
    }
    &__top{
      padding: 20px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      @include respondTo(600px){
        padding: 15px 10px;
      }
      &-circles{
        span{
          display: inline-block;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          border: 1px solid $black;
          &:not(:last-child){
            margin-right: 5px;
          }
        }
      }
      &-time{
        font-size: 12px;
        line-height: 1;
        color: #020202;
      }
    }
    &__img{
      text-align: center;
      img{
        margin: 0 auto;
        max-width: 100%;
      }
    }
    &__info{
      padding: 20px;
      font-size: 19px;
      line-height: 1.3;
      color: #3b3b3b;
      @include respondTo(600px){
        padding: 15px 10px;
        font-size: 14px;
        line-height: 1.2;
      }
      &-nickname{
        font-size: 16px;
        color: #00b764;
        font-weight: 500;
        margin-right: 5px;
      }
      & > span {
        display: inline;
        overflow-wrap: break-word;
      }
    }
    &__buttons{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      &-left,&-right{
        display: flex;
        flex-direction: row;
      }
    }
  }
}
.action{
  padding: 0 20px 10px;
  @include respondTo(600px){
    padding: 0 15px 10px;
  }
  &-like{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    @include respondTo(600px){
      margin-right: 10px;
    }
    &__button{
      width: 28px;
      height: 32px;
      background-size: 28px 28px;
      background-position: 50%;
      background-repeat: no-repeat;
      padding: 0;
      cursor: pointer;
      svg {
        width: 100%;
        height: 95%;
        path {
          fill: white;
          stroke-width: 3;
          stroke: black;
          transition: all $trans;
        }
      }
      &:hover svg path, &.active svg path{
        fill: red;
      }
      @include respondTo(600px){
        width: 20px;
        height: 20px;
        background-size: 20px 20px;
      }
    }
    &__count{
      color: #00b764;
      font-weight: 500;
      font-size: 16px;
      line-height: 1;
      margin-top: 5px;
      @include respondTo(600px){
        font-size: 14px;
      }
    }
  }
  &-comment{
    width: 30px;
    height: 30px;
    padding: 0;
    margin: 0;
    background: url('../../../assets/img/home/comment-btn.png') center center no-repeat;
    background-size: contain;
    @include respondTo(600px){
      width: 20px;
      height: 20px;
    }
  }
  &-share{
    padding: 0;
    margin-right: 30px;
    display: block;
    width: 30px;
    height: 30px;
    background-size: contain;
    @include respondTo(600px){
      width: 20px;
      height: 20px;
      margin-right: 15px;
    }
    svg{
      transform: rotate(90deg);
      transition: fill $trans;
    }
    &:hover{
      svg {
        fill: #00b764;
      }
    }
  }
  &-favourite {
    padding: 0;
    display: block;
    width: 30px;
    height: 30px;
    position: relative;
    @include respondTo(600px){
      width: 20px;
      height: 20px;
    }
    svg{
      transition: stroke $trans, fill $trans;
      fill: transparent;
    }
    &:hover{
      svg  {
        stroke: #00b764;
      }
    }
    &.active svg path{
      fill: $black;
      stroke: $black
    }
  }
}
.infinite-loading{
  height: 50px;
  padding: 20px 0;
}
</style>
