var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"select-container"},[(_vm.isLabelArray)?_vm._l((_vm.label),function(l,i){return _c('div',{key:i,staticClass:"form--label"},[_vm._v(" "+_vm._s(l)+" ")])}):(_vm.label)?_c('div',{staticClass:"form--label"},[_vm._v(_vm._s(_vm.label))]):_vm._e(),_c('Multiselect',_vm._g(_vm._b({scopedSlots:_vm._u([_vm._l((_vm.$scopedSlots),function(_,name){return {key:name,fn:function(slotData){return [_vm._t(name,null,null,slotData)]}}})],null,true)},'Multiselect',{
      value: _vm.value,
      placeholder: _vm.placeholder,
      options: _vm.options,
      trackBy: _vm.trackBy,
      label: _vm.optionLabel,
      showLabels: _vm.showLabels,
      disabled: _vm.disabled,
      internalSearch: _vm.internalSearch
    },false),_vm.$listeners),[_vm._l((_vm.$slots),function(_,name){return _vm._t(name,null,{"slot":name})})],2),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.invalid && _vm.helpText),expression:"!invalid && helpText"}],staticClass:"form--help-text"},[_vm._v(" "+_vm._s(_vm.helpText)+" ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.invalid),expression:"invalid"}],staticClass:"form--error-msg"},[_c('span',[_vm._v(" "+_vm._s(_vm.error)+" ")])]),(_vm.showError)?_c('p',{staticClass:"form--error"},[_vm._v(" "+_vm._s(_vm.$t('chooseCategoryError'))+" ")]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }