import api from '@/api'
import store from '@/store'

const state = {
  favouritesList: null,
  favouritesListLoading: false,
  favouritesListCount: 0,
  favouritesListNextPage: null
}

const getters = {
  favouritesList: (state) => state.favouritesList,
  favouritesListLoading: (state) => state.favouritesListLoading,
  favouritesListCount: (state) => state.favouritesListCount,
  favouritesListNextPage: (state) => state.favouritesListNextPage
}

const actions = {
  async getFavouritesList ({ commit }, { id, limit, offset }) {
    try {
      commit('changeFavouritesListLoading', true)
      const response = await api.favourites.getFavouritesLists({ id, limit, offset })
      store.commit('profile/saveProfilePosts', response)
      commit('setFavouritesListCount', response.count)
      commit('setFavouritesListNextPage', response.next)
    } catch (e) {
      throw e.response
    } finally {
      commit('changeFavouritesListLoading', false)
    }
  }
}

const mutations = {
  changeFavouritesListLoading (state, status) {
    state.favouritesListLoading = status
  },
  setFavouritesListCount (state, count) {
    state.favouritesListCount = count
  },
  setFavouritesListNextPage (state, page) {
    state.favouritesListNextPage = page
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}
