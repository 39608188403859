<template>
  <div class="feed-list__wrap">
    <div class="feed-list" v-if="profilePosts.results">
      <div
          class="feed-wrap__content"
          v-for="post in profilePosts.results"
          :key="post.id">
        <post-list-item
            :nickname="nickname"
            :post="post"
        ></post-list-item>
      </div>
    </div>
    <infinite-loading
        :identifier="infiniteId"
        @infinite="infiniteHandler"
        class="infinite-loading">
      <Spinner slot="spinner" />
      <div slot="no-more"></div>
      <div slot="no-results"></div>
    </infinite-loading>
    <div class="feed-list__empty"
         v-if="!profilePostsLength && !profilePostsLoading">
      <span v-if="!profile.isPublic">This user's public profile is hidden</span>
      <span v-if="profile.isPublic">{{ $t('emptyPostList') }}</span>
    </div>
  </div>

</template>

<!-- scripts-->
<script>
import { mapActions, mapGetters } from 'vuex'

import InfiniteLoading from 'vue-infinite-loading'
import Spinner from '@/components/atoms/Spinner'
import PostListItem from '../components/PostListItem.vue'

const OFFSET = 10

export default {
  name: 'post-list',
  data () {
    return {
      infiniteId: +new Date(),
      page: 0,
      postsCount: 0
    }
  },
  components: {
    InfiniteLoading,
    Spinner,
    PostListItem
  },
  watch: {
    '$route.path' () {
      this.getProfilePosts({
        nickname: this.nickname,
        limit: OFFSET,
        offset: OFFSET * this.page
      })
    }
  },
  computed: {
    ...mapGetters({
      profile: 'profile/profileData',
      profilePosts: 'profile/profilePosts',
      isNextPageProfilePosts: 'profile/isNextPageProfilePosts',
      profilePostsLength: 'profile/profilePostsLength',
      profilePostsLoading: 'profile/profilePostsLoading'
    }),
    nickname () {
      return this.$route.params.nickname
    }
  },
  methods: {
    ...mapActions({
      getProfilePosts: 'profile/getProfilePosts'
    }),
    async infiniteHandler ($state) {
      await this.getProfilePosts({
        nickname: this.nickname,
        limit: OFFSET,
        offset: OFFSET * this.page
      })
      if (!this.isNextPageProfilePosts) {
        $state.complete()
      } else {
        $state.loaded()
        this.page += 1
      }
    }
  }
}
</script>

<!-- style-->
<style lang="scss" scoped>
.profile{
  .feed{
    &-list{
      display: flex;
      flex-direction: column;
      &__empty{
        color: $white;
        font-size: 22px;
        text-align: center;
      }
    }
  }
}
</style>
