export default {
  termOfService: '服務期限',
  privacyPolicy: '隱私政策',
  xCommunications: 'x通訊',
  xCommunicationsInvestorRelations: '投資者關係',
  xCommunicationsMediaRequest: '媒體請求',
  xCommunicationsOurPositionPrivacy: '我們對隱私的立場',
  xCommunicationsKnowledgeBase: '知識庫',
  xCommunicationsOurPosition: '我們對網絡欺凌的立場',
  premiumTools: '高級工具',
  premiumToolsSayHolaLiveChatPlugin: 'Live Chat插件',
  premiumToolsSayHolaBlogPlugin: 'sayHola Blog插件',
  premiumToolsTogetherSayHola: '一起說sayHola',
  premiumToolsLiveLeagueApp: '直播聯賽應用',
  programs: '程式',
  programsAdvertiseHola: '用Hola做廣告',
  programsFreelancerProgram: '自由職業者計劃',
  programsAffiliateRewardsProgram: '會員獎勵計劃',
  terms: '條款',
  termsAdsPolicy: '廣告政策',
  termsPrivacyPolicy: '隱私政策',
  termsCommunityStandards: '社區標準',
  termsTermsUse: '使用條款',
  termsLoyaltyRewardsTerms: '獎勵政策',
  otp_unconfirmed: 'otp未確認，再試一次',
  pin_unconfirmed: 'PIN码错误',
  otpDescription: '短信代碼已發送給您。請在下面輸入代碼。',
  otpEnterYourOtp: '輸入您的 otp 代碼',
  otpEnterYourPin: '輸入您的密碼',
  verify_otp: '確認',
  otpDontReceiveCode: '沒有收到驗證碼？',
  otpRequestSMSAgain: '再次請求短信',
  otpRequestNewCode: '請求一個新的代碼',
  copyright: 'x通訊。版權所有',
  enterPhoneNumber: '請選擇您的國家並輸入您的完整電話號碼。',
  login: '登錄',
  validationSelectFile: '選擇並上傳照片',
  validationSameAs: '密碼不匹配',
  validationMaxLengthField: '最大字段字符數 :field - :count',
  validationMinLengthField: '最少字段字符數 :field - :count',
  validationRequired: '填寫此字段',
  validationEmail: '輸入有效的電子郵件',
  validationUrl: '輸入有效的網址',
  needChangePhone: '需要更改您的號碼嗎？',
  recovery: '恢復',
  needMoreInfo: '需要更多信息？',
  learnMore: '學到更多',
  startUsingHola: '開始使用 sayHola 查看 <br> 社交空間',
  startUsingHolaFollow: '開始使用 sayHola 關注',
  socialSpaceWord: '社交空間',
  socialSpaceInvite: '社交空間邀請',
  socialSpaceInviteMessage: '為企業、名人、博主、有影響力的人、社區和個人提供安全可靠的私人空間，沒有評論和虛假新聞',
  enterPhone: '輸入電話',
  footerPrivateAppJobsPage: '工作機會',
  socialPost: {
    image: '圖片',
    isEnabledComments: '註釋',
    isEnabledLikes: '喜歡按鈕',
    newPostTitle: '最新帖子',
    placeholder: '在此處開始您的消息和#hashtags',
    editPostTitle: '編輯帖子',
    deleteButton: '刪除帖子',
    editButton: '編輯帖子',
    reportButton: '報告',
    reportConfirmation: '您確定要舉報此帖子嗎？',
    reportText: '謝謝你讓我們知道。您的反饋有助於社區使用實踐。您可以採取的其他步驟是阻止用戶。執行此操作後，您將無法彼此見面或聯繫。',
    likes: '喜歡',
    hideComments: '隱藏評論',
    viewLatestComments: '查看最新評論',
    reply: '回复',
    replies: '回复',
    placeholderComment: '寫一個評論',
    placeholderReply: '回复 {name}',
    commentReportConfirmation: '您確定要舉報此評論嗎？'
  },
  btnHide: '隱藏',
  btnShow: '展示',
  btnReply: '回复',
  buttonEdit: '編輯',
  socialComment: {
    deleteConfirmation: '您確定要刪除評論嗎 <b>{text}</b>?'
  },
  buttonLoadMore: '裝載更多',
  socialSpace: {
    searchTitle: '搜索好啦',
    accounts: '帳戶',
    places: '地點',
    tags: '標籤'
  },
  searchNoResults: '沒有結果',
  startTypingForSearch: '開始輸入搜索',
  userStatus: {
    btnAdd: '添加狀態',
    tabSetStatus: '設置狀態',
    tabCreateStatus: '創建狀態',
    titlePlaceholder: '狀態標題',
    textPlaceholder: '在此處開始您的狀態（最多 250 個字符）',
    editStatusTitle: '編輯狀態'
  },
  buttonCancel: '取消',
  buttonDelete: '刪除',
  blogSubscribeBtn: '訂閱',
  blogUnsubscribeBtn: '退訂',
  popupConfirmTitle: '確認',
  popupConfirmText: '你確定嗎？',
  buttonAdd: '添加',
  buttonConfirm: '確認',
  socialProfile: {
    categoryCompany: '公司',
    categoryIndividual: '個人',
    extendedSettings: '擴展設置',
    firstName: '名',
    lastName: '姓',
    nickname: '暱稱',
    isActive: '社交資料（直播）',
    profileViewOptions: '個人資料視圖選項',
    isPublic: '公開（可搜索）',
    isChatActive: '給我留言（sayHola）',
    showFollowers: '關注人數',
    showSocialSpace: '展示社交空間',
    textLocation: '地點（城市、國家）',
    pictureError: '請上傳您的個人資料圖片',
    errorNicknameExists: '具有此暱稱的個人資料已存在',
    editTitle: '編輯我的高級個人資料',
    btnCancelRequest: '取消請求',
    btnFollow: '跟隨',
    btnUnfollow: '取消關注',
    followers: '追隨者',
    pending: '待辦的',
    createTitle: '創建新配置文件',
    successCreateProfile: '個人資料創建成功！',
    successEditProfile: '個人資料更新！',
    successDeleteProfile: '個人資料刪除成功！',
    showCountPosts: '帖子數',
    showCountFollowing: '跟隨計數',
    socialProfile: '社交資料'
  },
  authLogout: '登出',
  logout: {
    confirmation: '您確定要退出嗎？',
    allSessions: '從所有會話中註銷',
    title: '登出'
  },
  buttonCreate: '創建',
  buttonRegistration: '完成註冊',
  buttonEnter: '進入你好',
  buttonPay: '支付',
  buttonUpgrade: '升級',
  buttonCopy: '複製',
  buttonSave: '節省',
  buttonSend: '發送',
  successTitle: '成功地',
  errorTitle: '錯誤',
  error: '抱歉，出了一些問題',
  errorEmailInvalid: '輸入一個有效的電子郵件地址',
  errorPhoneInvalid: '無效的電話號碼',
  errorEmptyField: '此字段是必需的',
  errorAlphanumericDashUnderscore: '此字段只能包含字母數字字符、破折號和下劃線 [a-z0-9-_]',
  category: '類別',
  chooseCategory: '選擇類別',
  chooseCategoryError: '請選擇一個分類！',
  errorMinLength_begin: '確保該字段至少有',
  errorMaxLength_begin: '確保該字段不超過',
  errorLength_end: '人物',
  formAttach: '附',
  successCreatePost: '帖子創建成功',
  successEditPost: '帖子編輯成功',
  post: {
    author: '作者',
    createTitle: '創建新帖子',
    footerImages: '頁腳圖片',
    headerImage: '標題圖片',
    isPublished: '已發表',
    subtitle: '字幕',
    text: '文本',
    textPlaceholder: '在這裡開始你的博客。第一個字母粗體大',
    title: '標題',
    delete: '刪除帖子',
    edit: '編輯帖子',
    footerVideos: '頁腳視頻',
    tabPhotos: '相片',
    tabVideos: '視頻'
  },
  deleteConfirmation: '你確定你要刪除 <b>{name}</b>?',
  postNotFound: '找不到帖子！',
  createProfile: '創建個人資料',
  removeProfile: '刪除配置文件',
  editProfile: '編輯個人資料',
  createPost: '創建帖子',
  create: '創建',
  dontHaveProfile: '您沒有社交資料。',
  chooseProfile: '選擇社交資料',
  needSubscribeMessage: '您需要訂閱才能查看個人資料！',
  profilePost: '郵政',
  profileFollowing: '下列的',
  profileFollowers: '追隨者',
  emptyPostList: '帖子列表為空',
  navigationHome: '家',
  navigationLiked: '喜歡',
  navigationTrending: '趨勢',
  navigationFavourites: '收藏夾',
  navigationFollowers: '追隨者',
  postSettings: '帖子設置',
  noResultSearch: '未找到結果',
  profiles: '簡介',
  location: '地點',
  tags: '標籤',
  showMore: '展示更多',
  remove: '消除',
  uploadPhoto: '上傳圖片',
  dragAndDrop: '或者只是拖放',
  emptyList: '名單為空！',
  open: '打開',
  advertiseUsingCampaigns: '使用廣告系列在此處做廣告',
  postButton: '郵政',
  settingsButton: '設置',
  viewButton: '看法',
  deleteButton: '刪除',
  posts: '帖子',
  profileSettings: '配置文件設置',
  backgroundPicture: '背景圖片',
  profilePicture: '個人資料圖片',
  fillUserInfo: '請填寫信息：',
  requiredFields: '表示必填字段。',
  formFirstName: '名',
  formLastName: '姓',
  formNickName: '用戶名',
  profileEmailInfo: '您的電子郵件將僅用於恢復。輸入您的電子郵件，即表示您接受 sayHola 隱私政策和條款和條件。鏈接將發送到您的電子郵件以進行恢復。',
  formEmail: '你的郵件',
  error_email_exists: '使用此電子郵件的用戶已存在',
  backToHomePage: '返回首頁',
  pageNotFoundMessage: '我們找不到您要找的頁面',
  resendOtpMessage: '又發了一條短信。',
  buttonDone: '完畢',
  viewProfile: '查看資料',
  copyClipboardSuccess: '成功複製到剪貼板',
  copyClipboardError: '複製到剪貼板時出錯複製到剪貼板時出錯',
  loginByQRCodeMessage: '通過智能手機登錄的二維碼',
  authOption: '請選擇登錄選項。',
  newRegistration: '新註冊',
  registrationOption: '註冊需要電子郵件和用戶名才能使用服務。登錄後，您可以選擇使用您的電話號碼與其他信使應用程序同步',
  loginWithApp: '使用應用程序登錄',
  loginWithAppOption: '在您的手機上打開 Hola，轉到位於應用程序右上角的菜單。單擊二維碼圖標。將您的手機指向此屏幕以確認登錄。',
  recoveryOption: '恢復',
  recoveryOptionText: '為恢復輸入您的註冊電子郵件以使用熱鏈接登錄。通過訪問常見問題部分了解更多信息',
  enterEmailRecovery: '輸入您註冊的電子郵件以進行恢復：',
  notFoundUserLabel: '未找到使用此電子郵件的用戶',
  successSendRecoveryEmailMessage: '檢查您的電子郵件並按照說明進行操作！',
  chatBots: '聊天機器人',
  BdtlAgencyProgram: 'BDTL 代理計劃',
  agencyTerms: '代理條款',
  holaExtensions: 'Hola 擴展',
  holaBlog: '你好博客',
  browserExtensions: '瀏覽器擴展',
  agencyTermsNew: '代理政策',
  earnPrograms: '賺取程序',
  rewardsProgram: '獎勵計劃',
  despaxoTerms: 'Despaxo條款',
  faqWord: '經常問的問題',
  paidServices: '基本和付費服務',
  multiapp: '多應用同步',
  opportunities: '機會',
  despaxoDrivers: 'Despaxo 驅動程序',
  generalTerms: '一般條款',
  apps: '應用',
  web: '網絡',
  desktop: '桌面',
  blogSyncPlugin: '博客同步插件',
  together: '一起',
  chatbotConstructor: '聊天機器人構造器',
  enterpriseProgram: '企業計劃',
  bdtlProgram: 'BDTL計劃',
  despaxoBot: 'Despaxo 機器人同步程序',
  extendetTerms: '擴展條款',
  despaxoPolicy: 'Despaxo政策',
  despaxoDriverPolicy: 'Despaxo 司機政策',
  driverPolicy: '司機政策',
  administrativeStatement: '行政聲明',
  despaxoDefinitions: 'Despaxo定義',
  operatingDespaxo: '經營德斯帕索'
}
