<template>
  <modal
    :classes="$style.modal"
    :name="modalName"
    width="90%"
    height="auto"
    adaptive
    @before-open="beforeOpen"
    @before-close="beforeClose"
  >

    <div :class="$style.buttons">
      <BaseButton
        oval
        secondary
        @click="hide">
        {{ $t('buttonCancel') }}
      </BaseButton>
      <BaseButton
        oval
        @click="done">
        {{ $t('buttonDone') }}
      </BaseButton>
    </div>
    <VueCropper
      v-if="imgSrc"
      ref="cropper"
      :aspect-ratio="aspectRatio"
      :src="imgSrc"
      :imgStyle="{
        maxHeight: '80vh'
      }"
    />
  </modal>
</template>

<script>
import BaseButton from '@/components/atoms/_FormButton.vue'
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'

export default {
  name: 'cropper-modal',
  components: {
    VueCropper,
    BaseButton
  },
  data () {
    return {
      modalName: 'cropper',
      aspectRatio: null,
      imgSrc: null,
      onDone: () => {}
    }
  },
  methods: {
    async beforeOpen ({ params: { file, src, aspectRatio, onDone = () => {} } }) {
      if (file) {
        this.imgSrc = await this.getSrc(file)
      } else {
        this.imgSrc = src
      }
      this.onDone = onDone
      this.aspectRatio = aspectRatio
    },
    beforeClose () {
      this.aspectRatio = null
      this.imgSrc = null
      this.onDone = () => {}
    },
    hide () {
      this.$modal.hide(this.modalName)
    },
    done () {
      const canvas = this.$refs.cropper.getCroppedCanvas()
      this.onDone(canvas)
      this.hide()
    },
    getSrc (file) {
      return new Promise((resolve, reject) => {
        const fr = new FileReader()
        fr.onerror = reject
        fr.onload = event => {
          resolve(fr.result)
        }
        if (file) {
          fr.readAsDataURL(file)
        }
      })
    }
  }
}
</script>

<style module lang="scss">
.modal {
  background: $main-bg-light; // $transparent;
  box-shadow: 0 3px 11px 1px rgba(0, 0, 0, 0.15);
  font-weight: 400;
  border-radius: 0;
}
.closeBtn {
  cursor: pointer;
  color: $text-dark;
  font-size: 20px;
  position: absolute;
  right: 15px;
  top: 15px;
  transition: color .3s;
  &:hover {
    color: $text-darkest;
  }
}
.buttons {
  display: flex;
  justify-content: center;
  margin: 10px 0;
}
</style>
