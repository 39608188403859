import api from '@/api'
import store from '@/store'
import Vue from 'vue'

const state = {
  profileData: null,
  profileDataLoading: false,
  profilePosts: [],
  isNextPageProfilePosts: null,
  profilePostsLength: 0,
  profilePostsLoading: false,
  toggleLikeLoading: false,

  getComment: null,
  postCommentsList: [],
  getCommentRepliesCount: null,

  sendCommentLoading: false,
  deleteCommentLoading: false,
  fetchReplyLoading: false,
  fetchCommentsLoading: false,
  editCommentLoading: false,
  subscribeLoading: false,
  unSubscribeLoading: false,

  subscribersList: [],
  subscribersLoading: false,

  subscribeManageLoading: false,
  deleteSubscriptionLoading: false,
  createProfileLoading: false,
  editProfileLoading: false,
  addToFavouritesLoading: false
}

const getters = {
  profileData: (state) => state.profileData,
  profileDataLoading: (state) => state.profileDataLoading,
  profilePosts: (state) => state.profilePosts,
  profilePostsLength: (state) => state.profilePostsLength,
  isNextPageProfilePosts: (state) => state.isNextPageProfilePosts,
  profilePostsLoading: (state) => state.profilePostsLoading,
  toggleLikeLoading: (state) => state.toggleLikeLoading,

  getComment: (state) => state.getComment,
  postCommentsList: (state) => state.postCommentsList,
  getCommentRepliesCount: (state) => state.getCommentRepliesCount,

  sendCommentLoading: (state) => state.sendCommentLoading,
  deleteCommentLoading: (state) => state.deleteCommentLoading,
  fetchReplyLoading: (state) => state.fetchReplyLoading,
  fetchCommentsLoading: (state) => state.fetchCommentsLoading,
  editCommentLoading: (state) => state.editCommentLoading,
  subscribeLoading: (state) => state.subscribeLoading,
  unSubscribeLoading: (state) => state.unSubscribeLoading,

  subscribersList: (state) => state.subscribersList,
  subscribersLoading: (state) => state.subscribersLoading,

  subscribeManageLoading: (state) => state.subscribeManageLoading,
  deleteSubscriptionLoading: (state) => state.deleteSubscriptionLoading,
  createProfileLoading: (state) => state.createProfileLoading,
  editProfileLoading: (state) => state.editProfileLoading,
  addToFavouritesLoading: (state) => state.addToFavouritesLoading
}

const actions = {
  async getProfileData ({ commit }, nickname) {
    try {
      commit('changeProfileDataLoading', true)
      const response = await api.profile.getProfileData(nickname)
      commit('saveProfileData', response)
    } catch (e) {
      throw e.response
    } finally {
      commit('changeProfileDataLoading', false)
    }
  },
  async getProfilePosts ({ commit }, { nickname, limit, offset }) {
    try {
      commit('changeProfilePostsLoading', true)
      const response = await api.profile.getProfilePosts(nickname, limit, offset)
      commit('saveProfilePosts', response)
      commit('saveProfilePostsLength', response.count)
    } catch (e) {
      throw e.response
    } finally {
      commit('changeProfilePostsLoading', false)
    }
  },
  async toggleLike ({ commit }, { nickname, postId }) {
    try {
      commit('changeToggleLikeLoading', true)
      await api.profile.likePost(nickname, postId)
    } catch (e) {
      throw e.response
    } finally {
      commit('changeToggleLikeLoading', false)
      commit('toggleLike', postId)
    }
  },
  async fetchPostComments ({ commit }, { postId, limit, offset }) {
    try {
      commit('changeFetchCommentsLoading', true)
      const { results, next } = await api.profile.getPostComments(postId, { limit, offset })
      const data = {
        comments: results,
        isNext: !!next,
        postId
      }
      commit('setCommentList', data)
    } catch (e) {
      throw e.response
    } finally {
      commit('changeFetchCommentsLoading', false)
    }
  },
  async sendComment ({ commit }, { postId, data }) {
    try {
      commit('changeSendCommentLoading', true)
      const comment = await api.profile.createComment(postId, data)
      let result = {
        ...comment,
        postId,
        isOwn: true
      }
      if (data.replyTo) {
        result = {
          ...comment,
          postId,
          replyTo: data.replyTo,
          isReplyComment: true,
          commentId: data.commentId,
          isOwn: true
        }
      }
      commit('setComment', result)
    } catch (e) {
      throw e.response
    } finally {
      commit('changeSendCommentLoading', false)
    }
  },
  async editComment ({ commit }, { postId, commentId, id, text, isReplyComment }) {
    try {
      commit('changeEditCommentLoading', true)
      const comment = await api.profile.editComment(postId, id, { text })
      const data = {
        postId,
        commentId,
        id,
        isReplyComment,
        comment
      }
      commit('editComment', data)
    } catch (e) {
      throw e.response
    } finally {
      commit('changeEditCommentLoading', false)
    }
  },
  async deleteComment ({ commit }, { postId, commentId, isReplyComment, replyId }) {
    try {
      commit('changeDeleteCommentLoading', true)
      await api.profile.deleteComment(postId, replyId || commentId)
      const data = {
        postId,
        commentId,
        isReplyComment,
        replyId
      }
      commit('deleteComment', data)
    } catch (e) {
      throw e.response
    } finally {
      commit('changeDeleteCommentLoading', false)
    }
  },
  async fetchReplies ({ commit }, { postId, commentId }) {
    try {
      commit('changeFetchReplyLoading', true)
      const { results } = await api.profile.getCommentReplies(postId, commentId)
      const data = {
        replies: results,
        postId,
        commentId,
        replyTo: commentId
      }
      commit('setReplyData', data)
    } catch (e) {
      throw e.response
    } finally {
      commit('changeFetchReplyLoading', false)
    }
  },
  async subscribe ({ commit }, nickname) {
    try {
      commit('changeSubscribeLoading', true)
      await api.profile.subscribe(nickname)
      commit('subscribeToProfile', nickname)
    } catch (e) {
      throw e.response
    } finally {
      commit('changeSubscribeLoading', false)
    }
  },
  async unsubscribe ({ commit }, nickname) {
    try {
      commit('changeUnSubscribeLoading', true)
      await api.profile.unsubscribe(nickname)
      commit('unSubscribeToProfile', nickname)
    } catch (e) {
      throw e.response
    } finally {
      commit('changeUnSubscribeLoading', false)
    }
  },
  async fetchSubscribers ({ commit, dispatch }, profileNickname) {
    try {
      commit('changeFetchSubscribersLoading', true)
      const { results } = await api.profile.getSubscribers(profileNickname)
      const data = results.map(subscription => ({
        ...subscription,
        profileNickname
      }))
      commit('setSubscribersList', data)
    } catch (e) {
      throw e.response
    } finally {
      commit('changeFetchSubscribersLoading', false)
    }
  },
  async manageSubscription ({ commit }, obj) {
    try {
      commit('changeSubscribeManageLoading', true)
      await api.profile.manageSubscriptions(obj)

      if (obj.data.status === 1) {
        commit('manageSubscriberAccept', obj)
      }
      if (obj.data.status === 2) {
        commit('manageSubscriberDecline', obj)
      }
    } catch (e) {
      throw e.response
    } finally {
      commit('changeSubscribeManageLoading', false)
    }
  },
  async deleteSubscription ({ commit }, obj) {
    try {
      commit('changeDeleteSubscriptionLoading', true)
      await api.profile.deleteSubscription(obj)

      commit('deleteSubscription', obj)
    } catch (e) {
      throw e.response
    } finally {
      commit('changeDeleteSubscriptionLoading', false)
    }
  },
  async createProfile ({ commit }, data) {
    try {
      commit('changeCreateProfileLoading', true)
      const formData = new FormData()
      for (const key in data) {
        if (data[key] instanceof File) {
          formData.append(key, data[key])
        } else {
          formData.set(key, data[key])
        }
      }
      await api.profile.createProfile(formData)
    } catch (e) {
      throw e.response
    } finally {
      commit('changeCreateProfileLoading', false)
    }
  },
  async updateProfile ({ commit }, { nickname, data }) {
    try {
      commit('changeEditProfileLoading', true)
      const formData = new FormData()
      for (const key in data) {
        if (data[key] instanceof File) {
          formData.append(key, data[key])
        } else {
          formData.set(key, data[key])
        }
      }
      await api.profile.editProfile(nickname, formData)
    } catch (e) {
      throw e.response
    } finally {
      commit('changeEditProfileLoading', false)
    }
  },
  async addToFavourites ({ commit }, data) {
    try {
      commit('changeAddToFavouritesLoading', true)
      await api.profile.addToFavourites(data)
      commit('toggleFavourite', data)
    } catch (e) {
      throw e.response
    } finally {
      commit('changeAddToFavouritesLoading', false)
    }
  },
  async removeFromFavourites ({ commit }, data) {
    try {
      commit('changeAddToFavouritesLoading', true)
      await api.profile.removeFromFavourites(data)
      commit('toggleFavourite', data)
    } catch (e) {
      throw e.response
    } finally {
      commit('changeAddToFavouritesLoading', false)
    }
  }
}

const mutations = {
  clearCommentsList (state, status) {
    if (status) {
      state.postCommentsList = []
    }
  },
  clearPostsList (state, status) {
    if (status) {
      state.profilePosts = []
    }
  },
  saveProfileData (state, data) {
    data.isOwn = data.owner === store.getters['user/myProfile'].userId
    data.type = data.isOwn ? 'MY' : 'LOOKING_FOR'
    state.profileData = data
  },
  changeProfileDataLoading (state, status) {
    state.profileDataLoading = status
  },
  changeCreateProfileLoading (state, status) {
    state.createProfileLoading = status
  },
  changeFetchSubscribersLoading (state, status) {
    state.subscribersLoading = status
  },
  changeSendCommentLoading (state, status) {
    state.sendCommentLoading = status
  },
  changeSubscribeLoading (state, status) {
    state.subscribetLoading = status
  },
  changeEditProfileLoading (state, status) {
    state.editProfileLoading = status
  },
  changeDeleteSubscriptionLoading (state, status) {
    state.deleteSubscriptionLoading = status
  },
  changeUnSubscribeLoading (state, status) {
    state.unSubscribetLoading = status
  },
  changeSubscribeManageLoading (state, status) {
    state.subscribeManageLoading = status
  },
  saveProfilePosts (state, data) {
    if (state.profilePosts?.results?.length) {
      const newData = [...state.profilePosts.results, ...data.results]
      state.isNextPageProfilePosts = data.next
      Vue.set(state.profilePosts, 'results', newData)
    } else {
      state.isNextPageProfilePosts = data.next
      Vue.set(state.profilePosts, 'results', data.results)
    }
  },
  saveProfilePostsLength (state, count) {
    state.profilePostsLength = count
  },
  changeProfilePostsLoading (state, status) {
    state.profilePostsLoading = status
  },
  changeDeleteCommentLoading (state, status) {
    state.deleteCommentLoading = status
  },
  changeToggleLikeLoading (state, status) {
    state.toggleLikeLoading = status
  },
  toggleLike (state, postId) {
    const index = state.profilePosts.results.findIndex(e => e.id === postId)
    if (state.profilePosts.results[index].isLiked) {
      state.profilePosts.results[index].isLiked = 0
      state.profilePosts.results[index].likes--
    } else {
      state.profilePosts.results[index].isLiked = 1
      state.profilePosts.results[index].likes++
    }
  },
  toggleFavourite (state, data) {
    const postId = data.data.article
    const index = state.profilePosts.results.findIndex(e => e.id === postId)
    if (state.profilePosts.results[index].isFavourite) {
      state.profilePosts.results[index].isFavourite = 0
    } else {
      state.profilePosts.results[index].isFavourite = 1
    }
  },
  setCommentList (state, data) {
    if (data.comments.length) {
      data.comments.map((item) => {
        item.isOwn = item.user === store.getters['user/myProfile'].userId
        return item
      })
      const index = state.postCommentsList.findIndex((e) => e.postId === data.postId)
      if (index !== -1) {
        state.postCommentsList[index].isNext = data.isNext
        const newPostCommentsList = [...state.postCommentsList[index].comments, ...data.comments]
        Vue.set(state.postCommentsList[index], 'comments', newPostCommentsList)
      } else {
        state.postCommentsList.unshift(data)
      }
    } else {
      state.postCommentsList.unshift(data)
    }
  },
  setComment (state, data) {
    if (!data.isReplyComment) {
      const index = state.postCommentsList.findIndex((e) => e.postId === data.postId)
      state.postCommentsList[index].comments.unshift(data)
    } else {
      const postIndex = state.postCommentsList.findIndex((e) => e.postId === data.postId)
      const commentIndex = state.postCommentsList[postIndex].comments.findIndex((e) => e.id === data.commentId)
      state.postCommentsList[postIndex].comments[commentIndex].repliesCount += 1
      if (state.postCommentsList[postIndex].comments[commentIndex].replies) {
        state.postCommentsList[postIndex].comments[commentIndex].replies.unshift(data)
      }
    }
  },
  deleteComment (state, data) {
    if (!data.isReplyComment) {
      const postIndex = state.postCommentsList.findIndex((e) => e.postId === data.postId)
      const commentIndex = state.postCommentsList[postIndex].comments.findIndex((e) => e.id === data.commentId)
      state.postCommentsList[postIndex].comments.splice(commentIndex, 1)
    } else {
      const postIndex = state.postCommentsList.findIndex((e) => e.postId === data.postId)
      const commentIndex = state.postCommentsList[postIndex].comments.findIndex((e) => e.id === data.commentId)
      const replyIndex = state.postCommentsList[postIndex].comments[commentIndex].replies.findIndex((e) => e.id === data.replyId)
      Vue.delete(state.postCommentsList[postIndex].comments[commentIndex].replies, replyIndex)
      state.postCommentsList[postIndex].comments[commentIndex].repliesCount -= 1
    }
  },
  editComment (state, data) {
    const postIndex = state.postCommentsList.findIndex((e) => e.postId === data.postId)
    if (!data.isReplyComment) {
      const commentIndex = state.postCommentsList[postIndex].comments.findIndex((e) => e.id === data.id)
      Vue.set(state.postCommentsList[postIndex].comments[commentIndex], 'text', data.comment.text)
    } else {
      const commentIndex = state.postCommentsList[postIndex].comments.findIndex((e) => e.id === data.commentId)
      const replyIndex = state.postCommentsList[postIndex].comments[commentIndex].replies.findIndex((e) => e.id === data.id)
      Vue.set(state.postCommentsList[postIndex].comments[commentIndex].replies[replyIndex], 'text', data.comment.text)
    }
  },
  changeFetchReplyLoading (state, status) {
    state.fetchReplyLoading = status
  },
  changeEditCommentLoading (state, status) {
    state.editCommentLoading = status
  },
  changeFetchCommentsLoading (state, status) {
    state.fetchCommentsLoading = status
  },
  changeAddToFavouritesLoading (state, status) {
    state.addToFavouritesLoading = status
  },
  setReplyData (state, data) {
    if (data.replies.length) {
      data.replies.map((item) => {
        item.isOwn = item.user === store.getters['user/myProfile'].userId
        item.isReplyComment = true
        return item
      })
      const postIndex = state.postCommentsList.findIndex((e) => e.postId === data.postId)
      const commentIndex = state.postCommentsList[postIndex].comments.findIndex((e) => e.id === data.commentId)
      const newObjData = { ...state.postCommentsList[postIndex].comments[commentIndex], ...data }
      Vue.set(state.postCommentsList[postIndex].comments, commentIndex, newObjData)
    }
  },
  subscribeToProfile (state, nickname) {
    Vue.set(state.profileData, 'subscribeStatus', 3)
  },
  unSubscribeToProfile (state, nickname) {
    Vue.set(state.profileData, 'subscribeStatus', null)
  },
  setSubscribersList (state, data = []) {
    state.subscribersList = data
  },
  manageSubscriberAccept (state, data) {
    const index = state.subscribersList.findIndex(e => e.id === data.subscriptionId)
    const profileNickname = state.profileData.nickname
    if (index !== -1) {
      state.subscribersList[index].status = 1
      state.profileData.subscribers += 1
      const profileIndex = store.getters['user/profilesData'].findIndex(item => item.nickname === profileNickname)
      store.getters['user/profilesData'][profileIndex].pending -= 1
    }
  },
  manageSubscriberDecline (state, data) {
    const index = state.subscribersList.findIndex(e => e.id === data.subscriptionId)
    const profileNickname = state.profileData.nickname
    const profileIndex = store.getters['user/profilesData'].findIndex(item => item.nickname === profileNickname)
    state.subscribersList.splice(index, 1)
    store.getters['user/profilesData'][profileIndex].pending -= 1
  },
  deleteSubscription (state, data) {
    const index = state.subscribersList.findIndex(e => e.id === data.subscriptionId)
    state.subscribersList.splice(index, 1)
    state.profileData.subscribers -= 1
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}
