<template>
  <label class="form--file"
         :class="{'with-drag': withDragAndDrop}">
    <slot/>
    <span class="form-label"
          v-if="withDragAndDrop">
      {{ $t('uploadPhoto') }}
      <span>
        {{ $t('dragAndDrop') }}
      </span>
    </span>
    <span v-if="displayLabel"
          class="form--file-label">
      {{ displayLabel }}
    </span>
    <input
        :accept="accept"
        :multiple="multiple"
        type="file"
        class="form--file-input"
        @change="handleFileChange"
    >
  </label>
</template>

<script>
export default {
  name: 'file-input',
  props: {
    value: [File, FileList],
    label: String,
    accept: String,
    multiple: Boolean,
    withName: Boolean,
    withDragAndDrop: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    fileName () {
      if (this.withName && this.value) {
        return this.value.name
      }
      return null
    },
    displayLabel () {
      return this.fileName ? this.fileName : this.label
    }
  },
  methods: {
    handleFileChange (e) {
      const files = e.target.files
      const value = this.multiple ? files : files[0]
      this.$emit('input', value)
    }
  }
}
</script>

<style lang="scss">
.form--file {
  cursor: pointer;
  &-label {
    font-weight: 600;
    text-decoration: underline;
    color: $text-colored;
  }
  &-input {
    display: none;
  }
}
.form--file {
  cursor: pointer;
  &-label {
    font-weight: 600;
    text-decoration: underline;
    color: $text-colored;
  }
  &-input {
    display: none;
  }
  &.with-drag{
    position: relative;
    height: 120px;
    border: 3px dashed #ccc;
    min-height: 80px;
    padding: 20px 15px;
    cursor: pointer;
    &::after{
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      background: #074605;
      opacity: .05;
      transition: opacity $trans;
      cursor: pointer;
    }
    &:hover{
      &::after{
        background: #074605;
        opacity: .1;
      }
    }
    input{
      display: block;
      position: absolute;
      opacity: 0;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 3;
      cursor: pointer;
    }
    .form-label{
      text-align: center;
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      transform: translateY(-50%);
      z-index: 1;
      font-size: 14px;
      line-height: 1.2;
      color: #00b764;
      @include respondTo(600px){
        font-size: 12px;
        line-height: 1.5;
      }
      span {
        color: #074605;
        opacity: .5;
      }
    }
  }
}

</style>
