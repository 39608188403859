export default {
  advertiseUsingCampaigns: 'Anuncie aquí usando campañas',
  termOfService: 'Términos de Servicios',
  privacyPolicy: 'Política de Privacidad',
  xCommunications: 'xCommunications',
  xCommunicationsInvestorRelations: 'Relaciones con inversionistas',
  xCommunicationsMediaRequest: 'Solicitud de Medios',
  xCommunicationsOurPositionPrivacy: 'Nuestra Posición sobre Privacidad',
  xCommunicationsKnowledgeBase: 'Base de Conocimientos',
  xCommunicationsOurPosition: 'Nuestra Posición sobre Ciberacoso',
  premiumTools: 'Herramientas Premium',
  premiumToolsSayHolaLiveChatPlugin: 'Live Chat plugin',
  premiumToolsSayHolaBlogPlugin: 'sayHola Blog plugin',
  premiumToolsTogetherSayHola: 'together por sayHola',
  premiumToolsLiveLeagueApp: 'Live League App',
  programs: 'Programas',
  programsAdvertiseHola: 'Anunciece con Hola',
  programsFreelancerProgram: 'Programa Freelancer',
  programsAffiliateRewardsProgram: 'Programa de Recompensa de Afiliados',
  terms: 'Términos',
  termsAdsPolicy: 'Política sobre Anuncios',
  termsPrivacyPolicy: 'Política de Privacidad',
  termsCommunityStandards: 'Estandares de la Comunidad',
  termsTermsUse: 'Términos de Uso',
  termsLoyaltyRewardsTerms: 'Política de recompensas',
  otp_unconfirmed: 'Otp no confirmado, vuelve a intentarlo',
  pin_unconfirmed: 'Pin incorrecto',
  otpDescription: 'Se le ha enviado un código sms. Ingrese el código a continuación.',
  otpEnterYourOtp: 'Ingrese su código OTP',
  otpEnterYourPin: 'Ingrese su Pin',
  verify_otp: 'Confirmar',
  otpDontReceiveCode: 'No recibiste el código?',
  otpRequestSMSAgain: 'Vovler a solicitar el SMS',
  otpRequestNewCode: 'Volver a solicitar código',
  copyright: 'xCommunications. Todos los derechos reservados',
  enterPhoneNumber: 'Elija su país e ingrese su número de teléfono completo.',
  login: 'Ingresar',
  validationSelectFile: 'Elija y suba foto',
  validationSameAs: 'La contraseña no coincide',
  validationMaxLengthField: 'Número máximo de caracteres de campo: :field - :count',
  validationMinLengthField: 'Número mínimo de caracteres de campo: :field - :count',
  validationRequired: 'Llene este campo',
  validationEmail: 'Ingrese un correo electrónico válido',
  validationUrl: 'Ingrese un url válido',
  needChangePhone: 'Necesitas cambiar tu número?',
  recovery: 'Recuperar',
  needMoreInfo: 'Necesitas mas información?',
  learnMore: 'Aprende más',
  startUsingHola: 'Empiece a utilizar sayHola para ver el <br> espacio social',
  startUsingHolaFollow: 'Comience a usar sayHola para seguir',
  socialSpaceWord: 'espacio social',
  socialSpaceInvite: 'Invitación al Espacio Social',
  socialSpaceInviteMessage: 'un espacio privado seguro para empresas, celebridades, blogueros, personas influyentes, comunidad e individuos sin comentarios ni noticias falsas',
  enterPhone: 'Ingrese su teléfono',
  socialPost: {
    image: 'Imágen',
    isEnabledComments: 'Comentarios',
    isEnabledLikes: 'Boton me gusta',
    newPostTitle: 'Nueva Publicación',
    placeholder: 'Comience su mensaje y #hashtags aquí',
    editPostTitle: 'Editar publicación',
    deleteButton: 'Eliminar publicación',
    editButton: 'Editar publicación',
    reportButton: 'Reportar',
    reportConfirmation: '¿Estás seguro de que quieres denunciar esta publicación??',
    reportText: 'Gracias por hacernos saber. Sus comentarios ayudan con las prácticas de uso de la comunidad. Otros pasos que puedes tomar es bloquear al usuario. No podrán verse ni comunicarse entre sí una vez que hagan esto.',
    likes: 'me gustan',
    hideComments: 'Ocultar comentarios',
    viewLatestComments: 'Ver últimos comentarios',
    reply: 'responder',
    replies: 'respuestas',
    placeholderComment: 'Escribe un comentario',
    placeholderReply: 'Responder a {name}',
    commentReportConfirmation: '¿Estás seguro de que quieres denunciar este comentario??'
  },
  btnHide: 'Ocultar',
  btnShow: 'Ver',
  btnReply: 'Responder',
  buttonEdit: 'Editar',
  socialComment: {
    deleteConfirmation: '¿Estás seguro de que quieres eliminar el comentario? <b>{text}</b>?'
  },
  buttonLoadMore: 'Cargar mas',
  socialSpace: {
    searchTitle: 'Buscar',
    accounts: 'Cuentas',
    places: 'Lugares',
    tags: 'Etiquetas'
  },
  searchNoResults: 'No hay resultados',
  startTypingForSearch: 'Empieza a escribir para buscar',
  userStatus: {
    btnAdd: 'Agregar estado',
    tabSetStatus: 'Establecer estado',
    tabCreateStatus: 'Crear estado',
    titlePlaceholder: 'Título de estado',
    textPlaceholder: 'Inicia tu estado aquí (max 250 caracteres)',
    editStatusTitle: 'Editar estado'
  },
  buttonCancel: 'Cancelar',
  buttonDelete: 'Eliminar',
  blogSubscribeBtn: 'Subscribirse',
  blogUnsubscribeBtn: 'Darse de baja',
  popupConfirmTitle: 'Confirmación',
  popupConfirmText: 'Estas seguro?',
  buttonAdd: 'Agregar',
  buttonConfirm: 'Confirmar',
  footerPrivateAppJobsPage: 'Oportunidades de Trabajo',
  socialProfile: {
    categoryCompany: 'Compañía',
    categoryIndividual: 'Individual',
    extendedSettings: 'Configuración Extendida',
    firstName: 'Primer nombre',
    lastName: 'Apellido',
    nickname: 'Sobrenombre',
    isActive: 'Perfil Social (En Vivo)',
    profileViewOptions: 'Opciones de visualización de perfil',
    isPublic: 'Público (buscable)',
    isChatActive: 'Enviame un mensaje (sayHola)',
    showFollowers: 'Conteo de Seguidores',
    showSocialSpace: 'Ver Espacio Social',
    textLocation: 'Ubicación (ciudad, pais)',
    pictureError: 'Sube tu foto de perfil',
    errorNicknameExists: 'El perfil con este sobrenombre ya existe',
    editTitle: 'Editar mi perfil premium',
    btnCancelRequest: 'Cancelar solicitud',
    btnFollow: 'Seguir',
    btnUnfollow: 'Dejar de Seguir',
    followers: 'Seguidores',
    pending: 'Pendientes',
    createTitle: 'Crear un nuevo perfil',
    successCreateProfile: 'Perfil creado exitosamente!',
    successEditProfile: 'Información de Perfil actualizada!',
    successDeleteProfile: 'Perfil eliminado exitosamente!',
    showCountPosts: 'Conteo de Publicaciones',
    showCountFollowing: 'Conteo de Seguimientos',
    socialProfile: 'Perfil Social'
  },
  authLogout: 'Cerrar sesión',
  logout: {
    confirmation: '¿Estás seguro de que quieres cerrar sesión?',
    allSessions: 'Cerrar sesión en todas las sesiones',
    title: 'Cerrar sesión'
  },
  buttonCreate: 'Crear',
  buttonRegistration: 'Finalizar registro',
  buttonEnter: 'Ingresar a Hola',
  buttonPay: 'Pagar',
  buttonUpgrade: 'Subir de nivel',
  buttonCopy: 'Copiar',
  buttonSave: 'Guardar',
  buttonSend: 'Enviar',
  successTitle: 'Exitoso',
  errorTitle: 'Error',
  error: 'Perdón, algo salió mal',
  errorEmailInvalid: 'Introduzca una dirección de correo electrónico válida',
  errorPhoneInvalid: 'Numero de telefono invalido',
  errorEmptyField: 'Este campo es obligatorio',
  errorAlphanumericDashUnderscore: 'Este campo solo puede contener caracteres alfanuméricos, guiones y guiones bajos [a-z0-9-_]',
  category: 'Categoría',
  chooseCategory: 'Elegir categoría',
  chooseCategoryError: '¡Elija una categoría!',
  errorMinLength_begin: 'Asegúrese de que este campo tenga al menos',
  errorMaxLength_begin: 'Asegúrese de que este campo no tenga más de',
  errorLength_end: 'caracteres',
  formAttach: 'Adjuntar',
  successCreatePost: 'La publicación se creó correctamente',
  successEditPost: 'Publicación editada correctamente',
  post: {
    author: 'Autor',
    createTitle: 'Crear nueva publicación',
    footerImages: 'Imágenes de pie de página',
    headerImage: 'Imagen de encabezado',
    isPublished: 'Publicado',
    subtitle: 'Subtítulo',
    text: 'Texto',
    textPlaceholder: 'Inicie su blog aquí. La primera letra es grande y en negrita ',
    title: 'Título',
    delete: 'Eliminar publicación',
    edit: 'Editar publicación',
    footerVideos: 'Videos de pie de página',
    tabPhotos: 'Fotos',
    tabVideos: 'Videos'
  },
  deleteConfirmation: '¿Está seguro de que desea eliminar <b> {nombre} </b>? ',
  postNotFound: '¡Publicación no encontrada!',
  createProfile: 'Crear perfil',
  removeProfile: 'Eliminar perfil',
  editProfile: 'Editar perfil',
  createPost: 'Crear publicación',
  create: 'Crear',
  dontHaveProfile: 'No tienes perfil social.',
  chooseProfile: 'Elija un perfil social',
  needSubscribeMessage: '¡Debes suscribirte para ver el perfil!',
  profilePost: 'Publicación',
  profileFollowing: 'Siguiendo',
  profileFollowers: 'Seguidores',
  emptyPostList: 'La lista de publicaciones está vacía',
  navigationHome: 'inicio',
  navigationLiked: 'me gusta',
  navigationTrending: 'trending',
  navigationFavourites: 'favoritos',
  navigationFollowers: 'seguidores',
  postSettings: 'Configuración de publicación',
  noResultSearch: 'No se encontraron resultados',
  profiles: 'Perfiles',
  location: 'Ubicación',
  tags: 'Etiquetas',
  showMore: 'Mostrar más',
  remove: 'Eliminar',
  uploadPhoto: 'Subir foto',
  dragAndDrop: 'o simplemente arrastrar y soltar',
  emptyList: '¡La lista está vacía!',
  open: 'Abrir',
  postButton: 'Publicar',
  settingsButton: 'Configuración',
  viewButton: 'Ver',
  deleteButton: 'Eliminar',
  posts: 'Publicaciones',
  profileSettings: 'Configuración de perfil',
  backgroundPicture: 'Imagen de fondo',
  profilePicture: 'Imagen de perfil',
  fillUserInfo: 'Por favor complete la información:',
  requiredFields: 'Indica campo requerido.',
  formFirstName: 'Primer nombre',
  formLastName: 'Apellido',
  formNickName: 'Nombre de usuario',
  profileEmailInfo: 'Su correo electrónico se utilizará solo para la recuperación. Al ingresar su correo electrónico, acepta la política de privacidad y los términos y condiciones de sayHola. Se enviarán enlaces a su correo electrónico para su recuperación.',
  formEmail: 'Tu correo electrónico',
  error_email_exists: 'El usuario con este correo electrónico ya existe',
  backToHomePage: 'Volver a la página de inicio',
  pageNotFoundMessage: 'No podemos encontrar la página que estás buscando.',
  resendOtpMessage: 'Se envió un SMS más.',
  buttonDone: 'Hecho',
  viewProfile: 'Ver perfil',
  copyClipboardSuccess: 'Copia exitosa al portapapeles',
  copyClipboardError: 'Error al copiar al portapapeles',
  loginByQRCodeMessage: 'Código QR para iniciar sesión a través de un teléfono inteligente',
  authOption: 'Seleccione una opción para iniciar sesión.',
  newRegistration: 'nuevo registro',
  registrationOption: 'El registro requiere un correo electrónico y un nombre de usuario para utilizar los servicios. Una vez que haya iniciado sesión, tiene la opción de sincronizar con otras aplicaciones de mensajería usando su número de teléfono',
  loginWithApp: 'iniciar sesión con la aplicación',
  loginWithAppOption: 'Abra Hola en su teléfono, vaya al menú ubicado en la esquina superior derecha de la aplicación. Haga clic en el icono del código QR. Apunte su teléfono a esta pantalla para confirmar el inicio de sesión.',
  recoveryOption: 'recuperación',
  recoveryOptionText: 'Para recuperación Ingrese su correo electrónico registrado para iniciar sesión usando Hot-link. Obtenga más información visitando la sección de preguntas frecuentes',
  enterEmailRecovery: 'Ingrese su correo electrónico registrado para la recuperación:',
  notFoundUserLabel: 'Usuario con este correo electrónico no encontrado',
  successSendRecoveryEmailMessage: '¡Revisa tu correo electrónico y sigue las instrucciones!',
  chatBots: 'ChatBots',
  BdtlAgencyProgram: 'Programa de Agencia BDTL',
  agencyTerms: 'Términos de la agencia',
  holaExtensions: 'Hola Extensión',
  holaBlog: 'Hola Blog',
  browserExtensions: 'Extensiones del navegador',
  agencyTermsNew: 'Política de la agencia',
  earnPrograms: 'Programas de ganancias',
  rewardsProgram: 'Programa de recompensas',
  despaxoTerms: 'Términos de Despaxo',
  faqWord: 'Preguntas frecuentes',
  paidServices: 'Servicios básicos y de pago',
  multiapp: 'Sincronización de aplicaciones múltiples',
  opportunities: 'Oportunidades',
  despaxoDrivers: 'Controladores despaxo',
  generalTerms: 'Términos generales',
  apps: 'Aplicaciones',
  web: 'Web',
  desktop: 'Escritorio',
  blogSyncPlugin: 'Complemento de sincronización de blogs',
  together: 'Juntos',
  chatbotConstructor: 'Constructor de chatbots',
  enterpriseProgram: 'Programa Empresarial',
  bdtlProgram: 'Programa BDTL',
  despaxoBot: 'Programa de sincronización de bots de Despaxo',
  extendetTerms: 'Términos extendidos',
  despaxoPolicy: 'Política de Despaxo',
  despaxoDriverPolicy: 'Política de conductores de Despaxo',
  driverPolicy: 'Política del conductor',
  administrativeStatement: 'Declaración Administrativa',
  despaxoDefinitions: 'Definiciones de Despaxo',
  operatingDespaxo: 'Operando Despaxo'
}
