import Vue from 'vue'
import Toasted from 'vue-toasted'
import VueHead from 'vue-head'
import VueTelInput from 'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css'
import Vuelidate from 'vuelidate'
import { VTooltip, VPopover, VClosePopover } from 'v-tooltip'
import VModal from 'vue-js-modal'
import VueClipboard from 'vue-clipboard2'

Vue.directive('tooltip', VTooltip)
Vue.directive('close-popover', VClosePopover)
Vue.component('v-popover', VPopover)
// Use plugin
Vue.use(Toasted, {
  duration: 5000
})
Vue.use(VueHead)
Vue.use(VueTelInput)
Vue.use(Vuelidate)
Vue.use(VModal)
Vue.use(VueClipboard)
