import apiReq from './request'

const BASE_URL = `${process.env.VUE_APP_ROOT_API}/social`
const USER_URL = `${process.env.VUE_APP_ROOT_API}/user`

export default {
  getMyProfile () {
    return apiReq.get(`${USER_URL}/user/my_profile`)
  },
  getMyProfiles (params) {
    return apiReq.get(`${BASE_URL}/profile/my/`, { params })
  },
  getEditedProfile (nickname) {
    return apiReq.get(`${BASE_URL}/profile/my/${nickname}/`)
  },
  deleteProfile (nickname) {
    return apiReq.delete(`${BASE_URL}/profile/my/${nickname}/`)
  },
  getPost (nickname, postId) {
    return apiReq.get(`${BASE_URL}/profile/${nickname}/post/${postId}/`)
  },
  createPost (nickname, data) {
    return apiReq.post(`${BASE_URL}/profile/${nickname}/post/`, data)
  },
  editPost (nickname, postId, data) {
    return apiReq.patch(`${BASE_URL}/profile/${nickname}/post/${postId}/`, data)
  },
  deletePost (nickname, postId) {
    return apiReq.delete(`${BASE_URL}/profile/${nickname}/post/${postId}/`)
  },
  checkUsername (nickname) {
    return apiReq.post(`${BASE_URL}/profile/my/check-unique-nickname?nickname=${nickname}`, { nickname: nickname })
  },
  checkHolaUsername (nickname) {
    return apiReq.post(`${USER_URL}/user/check-unique-nickname?nickname=${nickname}`, { nickname: nickname })
  },
  saveProfile (data) {
    return apiReq.patch(`${USER_URL}/user/my_profile`, data)
  }
}
