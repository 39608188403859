import api from '@/api'
import router from '@/router'
import sha512 from 'js-sha512'

const state = {
  isAuthenticated: false,
  isLoading: false,
  otpResponse: null,
  isLoadingConfirm: false,
  isLoadingLogin: false,
  socialInfo: null,
  socialInfoLoading: false,
  accessToken: null,
  refreshToken: null,

  authByCodeResponse: null,
  authByCodeLoading: false
}

const getters = {
  isAuthenticated: (state) => state.isAuthenticated,
  isLoading: (state) => state.isLoading,
  otpResponse: state => state.otpResponse,
  isLoadingConfirm: state => state.isLoadingConfirm,
  isLoadingLogin: state => state.isLoadingLogin,
  socialInfo: state => state.socialInfo,
  socialInfoLoading: state => state.socialInfoLoading,
  accessToken: state => state.accessToken,
  refreshToken: state => state.refreshToken,

  authByCodeResponse: state => state.authByCodeResponse,
  authByCodeLoading: state => state.authByCodeLoading
}

const actions = {
  async getOtp ({ commit }, { phone }) {
    try {
      commit('changeLoading', true)
      const prefix = 'sc!FdyA7^2PP^Lee&-5!Y%H-Y(V!9aC(Hs(map9#VfDanfQ+bcu$8r'
      const sufix = 'Af)8D]vc~stz>v{/8U7L4a)K+NH9H}cfUqYaZ=~*Z/n?94#Pm-y*'
      const secret = sha512(prefix + phone + sufix)
      const data = {
        phone: phone,
        secret: secret
      }
      const response = await api.auth.getOtp(data)
      commit('saveOtpResponse', {
        ...data,
        ...response.data
      })
    } catch (e) {
      throw e.response
    } finally {
      commit('changeLoading', false)
    }
  },
  async confirmOtp ({ commit }, payload) {
    try {
      commit('changeConfirmLoading', true)
      const response = await api.auth.confirmOtp(payload)
      commit('authSaveToken', response.data.token)
      return response
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      throw e
    } finally {
      commit('changeConfirmLoading', false)
    }
  },
  async getSocialInfo ({ commit }, nickname) {
    try {
      commit('changeSocialLoading', true)
      const response = await api.auth.getSocialInfo(nickname)
      commit('setSocialData', response)
      return response
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      throw e
    } finally {
      commit('changeSocialLoading', false)
    }
  },
  async refresh ({ dispatch, commit, state }) {
    const handleError = () => {
      dispatch('logout')
      window.location = '/'
    }
    try {
      const { status, data } = await api.auth.refreshToken({
        refreshToken: state.refreshToken
      })
      if (status === 'error') {
        handleError()
        return Promise.reject(data)
      }
      commit('authSaveToken', data)
    } catch (e) {
      // console.log('CATCH refresh error', e.response && e.response.data)
      handleError()
      return e
    }
  },
  async logout ({ dispatch, state }, allSessions = false) {
    const { refreshToken } = state
    try {
      if (refreshToken) {
        await api.auth.logout({
          refreshToken,
          allSessions
        })
      } else {
        dispatch('logoutLocal')
      }
    } catch (error) {
      //
    } finally {
      dispatch('cleanUp')
      router.push({ name: 'start' })
      setTimeout(() => {
        window.location.reload()
      }, 400)
    }
  },
  async logoutLocal ({ dispatch, state }, allSessions = false) {
    dispatch('cleanUp')
    router.push({ name: 'start' })
    setTimeout(() => {
      window.location.reload()
    }, 400)
  },
  cleanUp ({ commit, dispatch }) {
    commit('logout')
  },
  async authByCode ({ commit }, payload) {
    try {
      commit('changeAuthByCodeLoading', true)
      const response = await api.auth.authByCode(payload)
      if (response.data && Object.keys(response.data).length === 0 && Object.getPrototypeOf(response.data) === Object.prototype) {

      } else {
        commit('authSaveToken', response.data.token)
      }
      return response
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      throw e
    } finally {
      commit('changeAuthByCodeLoading', false)
    }
  },
  async sendQRCode ({ commit }, payload) {
    // eslint-disable-next-line no-useless-catch
    try {
      await api.auth.sendQRCode(payload)
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      throw e
    }
  },
  async requestMagicRecovery ({ state }, email) {
    const params = {
      email,
      device: {
        id: '73617948-6f6c-6120-426c-6f6720777000',
        type: 'web'
      }
    }
    await api.auth.requestMagicRecovery(params)
  }
}

const mutations = {
  authSaveToken (state, token) {
    localStorage.setItem('accessToken', token.accessToken)
    localStorage.setItem('refreshToken', token.refreshToken)
    state.isAuthenticated = true
    state.accessToken = token.accessToken
    state.refreshToken = token.refreshToken
  },
  changeLoading (state, status) {
    state.isLoading = status
  },
  changeSocialLoading (state, status) {
    state.socialInfoLoading = status
  },
  changeConfirmLoading (state, status) {
    state.isLoadingConfirm = status
  },
  changeLoginLoading (state, status) {
    state.isLoadingLogin = status
  },
  saveOtpResponse (state, params = null) {
    state.otpResponse = params
  },
  changeAuthStatus (state, status) {
    state.isAuthenticated = status
  },
  setSocialData (state, data) {
    state.socialInfo = data
  },
  logout (state) {
    state.otpResponse = null
    state.isAuthenticated = false
    state.accessToken = null
    state.refreshToken = null
    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')
  },
  clearAuth (state) {
    // state.accessToken = null
    // state.otpResponse = null
    // state.isAuthenticated = false
    // localStorage.removeItem('accessToken')
  },
  authSaveAuthByCode (state, data) {
    console.log('response------', data)
    state.authByCodeResponse = data
  },
  changeAuthByCodeLoading (state, status) {
    state.authByCodeLoading = status
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}
