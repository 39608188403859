<template>
  <router-link class="trending-item__wrap"
               :to="$localize({ name: 'start', params: { nickname: item.nickname } })">
    <div class="trending-item__logo">
      <span class="line"></span>
      <img :src="item.picture"
           :alt="item.nickname"
           :title="item.nickname">
    </div>
    <div class="trending-item__nickname">
                <span :title="item.nickname">
                  ({{ item.nickname }})
                </span>
    </div>
    <div class="trending-item__name">
      <span v-if="item.firstName">{{ item.firstName }}</span>
      <span v-if="item.lastName">{{ item.lastName }}</span>
    </div>
    <div class="trending-item__info">
      <img :src="item.background" alt="" v-if="item.background">
      <img src="/img/default-list-bg.jpg" alt="" v-else>
      <p v-if="item.countPosts"><span>{{ $t('posts') }}</span> {{ item.countPosts }}</p>
      <p><span>{{ $t('socialProfile.followers') }}</span> {{ nFormatter(item.subscribers, 1)  }}</p>
    </div>
  </router-link>
</template>

<script>
export default {
  name: 'TrendingListItem',
  props: {
    item: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: {}
    }
  },
  methods: {
    nFormatter (num, digits) {
      const lookup = [
        { value: 1, symbol: '' },
        { value: 1e3, symbol: 'k' },
        { value: 1e6, symbol: 'M' },
        { value: 1e9, symbol: 'G' },
        { value: 1e12, symbol: 'T' },
        { value: 1e15, symbol: 'P' },
        { value: 1e18, symbol: 'E' }
      ]
      const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
      const item = lookup.slice().reverse().find(function (item) {
        return num >= item.value
      })
      return item ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol : '0'
    }
  }
}
</script>

<style lang="scss" scoped>
.trending{
  &-item{
    &:hover {
      .trending-item__wrap{
        border-color: $black;
      }
    }
    &__wrap{
      text-decoration: none;
      color: $black;
      font-size: 16px;
      line-height: 1.1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      width: 100%;
      height: 100%;
      overflow: hidden;
      border: 1px solid rgba(0,0,0,0.1);
      border-radius: 5px;
      transition: border-color $trans;
    }
    &__logo{
      position: absolute;
      top: 0;
      right: 0;
      width: 70%;
      height: 50%;
      margin-bottom: 15px;
      transition: border-color $trans;
      @include respondTo(441px){
        width: 65%;
      }
      &:after{
        content: '';
        position: absolute;
        left: -133px;
        top: 14px;
        width: 207px;
        height: 213px;
        transform: rotate(64deg);
        background: #ffffff;
        @include respondTo(441px){
         display: none;
        }
      }
      &:before{
        content: '';
        position: absolute;
        left: 98px;
        top: 88px;
        width: 134px;
        height: 75px;
        transform: rotate(-25deg);
        background: $white;
        @include respondTo(441px){
          display: none;
        }
      }
      .line{
        content: '';
        position: absolute;
        left: 91px;
        top: 88px;
        width: 134px;
        height: 68px;
        transform: rotate(-25deg);
        background: transparent;
        @include respondTo(441px){
          display: none;
        }
        &:after{
          content: '';
          display: block;
          position: absolute;
          left: 5px;
          top: 8px;
          width: calc(100% - 10px);
          height: 2px;
          background: #000;
        }
        &:before{
          content: '';
          display: block;
          position: absolute;
          left: 15px;
          top: 15px;
          width: calc(100% - 30px);
          height: 2px;
          background: #000;
        }
      }
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &__name{
      position: absolute;
      z-index: 2;
      font-size: 13px;
      left: 95px;
      top: 12px;
      transform: rotate(64deg);
      transform-origin: 0 0;
      width: 140px;
      @include truncate();
      text-align: center;
      @include respondTo(1100px){
        left: 85px;
      }
      @include respondTo(441px){
        left: 40px;
      }
      span + span {
        margin-left: 5px;
      }
    }
    &__nickname{
      position: absolute;
      z-index: 2;
      font-size: 13px;
      left: 70px;
      top: 12px;
      transform: rotate(64deg);
      transform-origin: 0 0;
      width: 140px;
      @include truncate();
      text-align: center;
      @include respondTo(1100px){
        left: 60px;
      }
      @include respondTo(441px){
        left: 20px;
      }
    }
    &__info{
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 110px;
      display: flex;
      flex-direction: column;
      padding: 10px;
      img{
        object-fit: cover;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
      }
      p{
        font-size: 12px;
        font-weight: 500;
        background: white;
        padding: 5px;
        position: relative;
        z-index: 3;
        width: max-content;
        border-radius: 6px;
        display: flex;
        align-items: center;
      }
      span {
        margin-right: 5px;
        display: inline-block;
        @include truncate();
        max-width: 80px;
      }
      p + p {
        margin-top: 5px;
      }
    }
  }
}
</style>
