<template>
  <div class="header-navigation">
    <div class="header-navigation__list">
      <router-link :to="$localize({name: 'home'})" class="header-navigation__item">
        <div class="header-navigation__item-icon">
          <icon name="homeNavigationBig"></icon>
        </div>
        <span class="header-navigation__item-label">{{ $t('navigationHome') }}</span>
      </router-link>
      <router-link class="header-navigation__item" :to="$localize({name: 'likes'})">
        <div class="header-navigation__item-icon">
          <icon name="likesNavigationBig"></icon>
        </div>
        <span class="header-navigation__item-label">{{ $t('navigationLiked') }}</span>
      </router-link>
      <router-link class="header-navigation__item" :to="$localize({name: 'trending'})">
        <div class="header-navigation__item-icon">
          <icon name="trendingNavigationBig"></icon>
        </div>
        <span class="header-navigation__item-label">{{ $t('navigationTrending') }}</span>
      </router-link>
      <router-link class="header-navigation__item" :to="$localize({name: 'favourites'})">
        <div class="header-navigation__item-icon">
          <icon name="favouritesNavigationBig"></icon>
        </div>
        <span class="header-navigation__item-label">{{ $t('navigationFavourites') }}</span>
      </router-link>
      <div
        class="header-navigation__item"
        v-if="isShowFollowerMenuButton">
        <div class="followers-wrap" @click.prevent="changeShowFollowerMenu">
          <div class="header-navigation__item-icon">
            <icon name="followersNavigationBig"></icon>
          </div>
          <span class="header-navigation__item-label">{{ $t('navigationFollowers') }}</span>
          <div class="followers-count" v-if="getMyProfile.pending > 0">{{ getMyProfile.pending }}</div>
        </div>
        <FollowersList
          @close="close"
          :followersCount="getMyProfile.pending"
          v-if="isShowFollowerMenu"/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import FollowersList from '@/components/atoms/Followers.vue'
import Icon from '@/components/atoms/Icon.vue'

export default {
  name: 'HeaderNavigation',
  data () {
    return {
      isShowFollowerMenu: false,
      followersType: 'followers'
    }
  },
  components: {
    FollowersList,
    Icon
  },
  watch: {
    '$route.path' () {
      this.isShowFollowerMenu = false
    }
  },
  computed: {
    ...mapGetters({
      subscribersList: 'profile/subscribersList',
      profile: 'profile/profileData',
      profilesData: 'user/profilesData'
    }),
    isShowFollowerMenuButton () {
      return this.$route.name === 'start' && this.profile && this.profile?.type === 'MY'
    },
    getMyProfile () {
      const nickname = this.$route.params.nickname
      if (this.profilesData) {
        const index = this.profilesData.findIndex(item => item.nickname === nickname)
        if (index !== -1) {
          return this.profilesData[index]
        } else {
          return false
        }
      } else {
        return false
      }
    }
  },
  methods: {
    ...mapActions({
      fetchSubscribers: 'profile/fetchSubscribers'
    }),
    changeShowFollowerMenu () {
      this.isShowFollowerMenu = true
    },
    changeActiveTab (tab) {
      this.followersType = tab
    },
    close (variable) {
      this.isShowFollowerMenu = variable !== 'close'
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  &-navigation {
    &__list {
      margin-top: 30px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      @include respondTo(1200px){
        display: none;
      }
    }
    &__item {
      width: 20%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      text-decoration: none;
      position: relative;
      cursor: pointer;
      svg path {
        transition: fill $trans;
      }
      &.router-link-exact-active {
        span {
          color: #fcad31;
        }
        svg path {
          fill: #fcad31;
        }
      }
      &:hover {
        span {
          color: #fcad31;
        }
        svg path {
          fill: #fcad31;
        }
      }
      &-icon {
        width: 35px;
        height: 35px;
        margin-bottom: 15px;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      &-label {
        text-transform: uppercase;
        font-size: 14px;
        line-height: 1.2;
        color: #2e2e2e;
        transition: color $trans;
        text-decoration: none;
      }
    }
  }
}
.followers-count{
  position: absolute;
  border-radius: 8px;
  background-color: #00b764;
  color: #fff;
  font-size: 10px;
  padding: 2px 4px;
  top: -6px;
  right: 18px;
  text-align: center;
  min-width: 16px;
  font-weight: 600;
}
.followers-wrap{
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
}
</style>
