<template>
  <ul class="container">
    <slot>
      <li
        v-for="item in renderList" :key="item.name"
        :class="['item', { ['itemDisabled']: item.disabled }]"
        @click="item.handler"
      >
        <svg v-if="item.icon === 'edit'" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="pencil-alt" role="img"
             xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
             class="svg-inline--fa fa-pencil-alt fa-w-16 MenuBase_icon_vbnBs icon">
          <path fill="currentColor"
                d="M497.9 142.1l-46.1 46.1c-4.7 4.7-12.3 4.7-17 0l-111-111c-4.7-4.7-4.7-12.3 0-17l46.1-46.1c18.7-18.7 49.1-18.7 67.9 0l60.1 60.1c18.8 18.7 18.8 49.1 0 67.9zM284.2 99.8L21.6 362.4.4 483.9c-2.9 16.4 11.4 30.6 27.8 27.8l121.5-21.3 262.6-262.6c4.7-4.7 4.7-12.3 0-17l-111-111c-4.8-4.7-12.4-4.7-17.1 0zM124.1 339.9c-5.5-5.5-5.5-14.3 0-19.8l154-154c5.5-5.5 14.3-5.5 19.8 0s5.5 14.3 0 19.8l-154 154c-5.5 5.5-14.3 5.5-19.8 0zM88 424h48v36.3l-64.5 11.3-31.1-31.1L51.7 376H88v48z"
                class=""></path>
        </svg>
        <svg v-if="item.icon === 'delete'" width="20px" height="20px" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="trash-alt" role="img"
             xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"
             class="svg-inline--fa fa-trash-alt fa-w-14 MenuBase_icon_vbnBs icon">
          <path fill="currentColor"
                d="M32 464a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128H32zm272-256a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zM432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16z"
                class=""></path>
        </svg>
        <div>{{ item.name }}</div>
      </li>
    </slot>
  </ul>
</template>

<script>
export default {
  name: 'menu-base',
  props: {
    options: Array
  },
  computed: {
    renderList () {
      if (!this.options) return []
      return this.options.filter(item => {
        if (!item.displayCondition) return true
        return item.displayCondition()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  color: $text-darkest;
  padding: 6px 20px 6px 0;
}
.item {
  height: 42px;
  display: flex;
  font-size: 14px;
  align-items: center;
  cursor: pointer;
  transition: color $trans;
  &Disabled {
    color: $text-mid;
    cursor: default;
  }
  &:not(.itemDisabled):hover {
    color: $text-colored;
    .icon {
      color: $text-colored;
    }
    svg path{
      fill: $text-colored;
    }
  }
  .icon {
    width: 16px;
    height: 16px;
    margin: 0 12px;
    color: $text-light;
    font-size: 16px;
    path {
      transition: fill $trans;
    }
  }
}
</style>
