import api from '@/api'
import Vue from 'vue'

const state = {
  trendingList: [],
  trendingListLoading: false,
  trendingListCount: 0,
  trendingListNextPage: null
}

const getters = {
  trendingList: (state) => state.trendingList,
  trendingListLoading: (state) => state.trendingListLoading,
  trendingListCount: (state) => state.trendingListCount,
  trendingListNextPage: (state) => state.trendingListNextPage
}

const actions = {
  async getTrendingList ({ commit }, { limit, offset }) {
    try {
      commit('changeTrendingListLoading', true)
      const response = await api.trending.getTrendingList({ limit, offset })
      commit('saveTrendingList', response)
      commit('setTrendingListCount', response.count)
      commit('setTrendingListNextPage', response.next)
    } catch (e) {
      throw e.response
    } finally {
      commit('changeTrendingListLoading', false)
    }
  }
}

const mutations = {
  saveTrendingList (state, data) {
    if (state.trendingList?.results?.length) {
      const newData = [...state.trendingList.results, ...data.results]
      state.trendingListNextPage = data.next
      Vue.set(state.trendingList, 'results', newData)
    } else {
      state.trendingListNextPage = data.next
      Vue.set(state.trendingList, 'results', data.results)
    }
  },
  changeTrendingListLoading (state, status) {
    state.trendingListLoading = status
  },
  setTrendingListCount (state, count) {
    state.trendingListCount = count
  },
  setTrendingListNextPage (state, page) {
    state.trendingListNextPage = page
  },
  clearTrendingList (state) {
    state.trendingList = []
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}
