export default {
  termOfService: 'Termo de Serviços',
  privacyPolicy: 'Política de Privacidade',
  xCommunications: 'xComunicações',
  xCommunicationsInvestorRelations: 'Relações com Investidores',
  xCommunicationsMediaRequest: 'Solicitação de mídia',
  xCommunicationsOurPositionPrivacy: 'Nossa posição sobre privacidade',
  xCommunicationsKnowledgeBase: 'Base de Conhecimento',
  xCommunicationsOurPosition: 'Nossa posição sobre cyberbullying',
  premiumTools: 'Ferramentas Premium',
  premiumToolsSayHolaLiveChatPlugin: 'plugin Live Chat',
  premiumToolsSayHolaBlogPlugin: 'plugin do sayHola Blog',
  premiumToolsTogetherSayHola: 'juntos por sayHola',
  premiumToolsLiveLeagueApp: 'App Live League',
  programs: 'Programas',
  programsAdvertiseHola: 'Anuncie com Hola',
  programsFreelancerProgram: 'Programa Freelancer',
  programsAffiliateRewardsProgram: 'Programa de recompensas para afiliados',
  terms: 'Termos',
  termsAdsPolicy: 'Política de Anúncios',
  termsPrivacyPolicy: 'Política de Privacidade',
  termsCommunityStandards: 'Padrões da Comunidade',
  termsTermsUse: 'Termos de uso',
  termsLoyaltyRewardsTerms: 'Política de recompensas',
  otp_unconfirmed: 'Otp não confirmado, tente novamente',
  pin_unconfirmed: 'Código PIN incorreto',
  otpDescription: 'Um código sms foi enviado para você. Por favor, insira o código abaixo.',
  otpEnterYourOtp: 'Digite seu código otp',
  otpEnterYourPin: 'Digite seu código PIN',
  verify_otp: 'confirme',
  otpDontReceiveCode: 'Não recebeu o código?',
  otpRequestSMSAgain: 'Solicite SMS novamente',
  otpRequestNewCode: 'Solicite um novo código em',
  copyright: 'xComunicações. Todos os direitos reservados',
  enterPhoneNumber: 'Escolha seu país e digite seu número de telefone completo.',
  login: 'Conecte-se',
  validationSelectFile: 'Selecione e faça upload da foto',
  validationSameAs: 'As senhas não coincidem',
  validationMaxLengthField: 'Número máximo de caracteres de campo :field - :count',
  validationMinLengthField: 'Número mínimo de caracteres de campo :field - :count',
  validationRequired: 'Preencha este campo',
  validationEmail: 'Entre com um email válido',
  validationUrl: 'Insira um url válido',
  needChangePhone: 'Precisa mudar seu número?',
  recovery: 'Recuperação',
  needMoreInfo: 'Precisa de mais informações?',
  learnMore: 'Saber mais',
  startUsingHola: 'Comece a usar sayHola para visualizar o <br> Espaço Social',
  startUsingHolaFollow: 'Comece a usar sayHola para seguir',
  socialSpaceWord: 'espaço social',
  socialSpaceInvite: 'Convite para espaço social',
  socialSpaceInviteMessage: 'um espaço privado e seguro para empresas, celebridades, blogueiros, influenciadores, comunidade e indivíduos, sem comentários e notícias falsas',
  enterPhone: 'Digite o telefone',
  footerPrivateAppJobsPage: 'Oportunidades de Trabalho',
  socialPost: {
    image: 'Imagem',
    isEnabledComments: 'Comentários',
    isEnabledLikes: 'Botão Curtir',
    newPostTitle: 'Nova postagem',
    placeholder: 'Comece sua mensagem e #hashtags aqui',
    editPostTitle: 'Editar post',
    deleteButton: 'Apague a postagem',
    editButton: 'Editar post',
    reportButton: 'Relatório',
    reportConfirmation: 'Tem certeza de que quer denunciar este post?',
    reportText: 'Obrigado por nos informar. Seu feedback ajuda nas práticas de uso da comunidade. Outras etapas que você pode seguir são bloquear o usuário. Vocês não poderão se ver ou entrar em contato depois de fazer isso.',
    likes: 'gostos',
    hideComments: 'Ocultar comentários',
    viewLatestComments: 'Ver os comentários mais recentes',
    reply: 'responder',
    replies: 'respostas',
    placeholderComment: 'Escreva um comentário',
    placeholderReply: 'Responder a {name}',
    commentReportConfirmation: 'Tem certeza de que quer denunciar este comentário?'
  },
  btnHide: 'Esconder',
  btnShow: 'Mostrar',
  btnReply: 'Responder',
  buttonEdit: 'Editar',
  socialComment: {
    deleteConfirmation: 'Tem certeza que deseja deletar o comentário <b>{text}</b>?'
  },
  buttonLoadMore: 'Carregue mais',
  socialSpace: {
    searchTitle: 'Pesquisa',
    accounts: 'Contas',
    places: 'Locais',
    tags: 'Tag'
  },
  searchNoResults: 'Sem resultados',
  startTypingForSearch: 'Comece a digitar para pesquisar',
  userStatus: {
    btnAdd: 'Adicionar status',
    tabSetStatus: 'Definir status',
    tabCreateStatus: 'Criar status',
    titlePlaceholder: 'Título de status',
    textPlaceholder: 'Comece seu status aqui (no máximo 250 caracteres)',
    editStatusTitle: 'Editar status'
  },
  buttonCancel: 'Cancelar',
  buttonDelete: 'Excluir',
  blogSubscribeBtn: 'Se inscrever',
  blogUnsubscribeBtn: 'Cancelar subscrição',
  popupConfirmTitle: 'Confirmação',
  popupConfirmText: 'Tem certeza?',
  buttonAdd: 'Adicionar',
  buttonConfirm: 'confirme',
  socialProfile: {
    categoryCompany: 'Empresa',
    categoryIndividual: 'Individual',
    extendedSettings: 'Configurações estendidas',
    firstName: 'Primeiro nome',
    lastName: 'Último nome',
    nickname: 'Apelido',
    isActive: 'Perfil social (ao vivo)',
    profileViewOptions: 'Opções de visualização de perfil',
    isPublic: 'Público (pesquisável)',
    isChatActive: 'Envie uma mensagem para mim (digaHola)',
    showFollowers: 'Contagem de seguidores',
    showSocialSpace: 'Mostrar Espaço Social',
    textLocation: 'Localização (cidade, país)',
    pictureError: 'Por favor carregue sua foto de perfil',
    errorNicknameExists: 'Já existe um perfil com este apelido',
    editTitle: 'Editar meu perfil premium',
    btnCancelRequest: 'Cancelar pedido',
    btnFollow: 'Seguir',
    btnUnfollow: 'Deixar de seguir',
    followers: 'Seguidores',
    pending: 'Pendente',
    createTitle: 'Crie um novo perfil',
    successCreateProfile: 'Perfil criado com sucesso!',
    successEditProfile: 'Informações do perfil atualizadas!',
    successDeleteProfile: 'Perfil excluído com sucesso!',
    showCountPosts: 'Contagem de postagens',
    showCountFollowing: 'Contagem de seguidores',
    socialProfile: 'Perfil Social'
  },
  authLogout: 'Sair',
  logout: {
    confirmation: 'Tem certeza que deseja sair?',
    allSessions: 'Saia de todas as sessões',
    title: 'Sair'
  },
  buttonCreate: 'Crio',
  buttonRegistration: 'Concluir o registro',
  buttonEnter: 'Entra Hola',
  buttonPay: 'Pagar',
  buttonUpgrade: 'Melhoria',
  buttonCopy: 'cópia de',
  buttonSave: 'Salve',
  buttonSend: 'Mandar',
  successTitle: 'Com sucesso',
  errorTitle: 'Erro',
  error: 'Desculpe, algo deu errado',
  errorEmailInvalid: 'Digite um endereço de e-mail válido',
  errorPhoneInvalid: 'Número de telefone inválido',
  errorEmptyField: 'Este campo é obrigatório',
  errorAlphanumericDashUnderscore: 'Este campo só pode conter caracteres alfanuméricos, traço e sublinhado [a-z0-9-_]',
  category: 'Categoria',
  chooseCategory: 'Escolher categoria',
  chooseCategoryError: 'Por favor escolha uma categoria!',
  errorMinLength_begin: 'Certifique-se de que este campo tenha pelo menos',
  errorMaxLength_begin: 'Certifique-se de que este campo não tenha mais do que',
  errorLength_end: 'personagens',
  formAttach: 'Anexar',
  successCreatePost: 'A postagem foi criada com sucesso',
  successEditPost: 'Postagem editada com sucesso',
  post: {
    author: 'Autor',
    createTitle: 'Criar nova postagem',
    footerImages: 'Imagens de rodapé',
    headerImage: 'Imagem de cabeçalho',
    isPublished: 'Publicados',
    subtitle: 'Legenda',
    text: 'Texto',
    textPlaceholder: 'Comece seu blog aqui. A primeira letra está em negrito e grande',
    title: 'Título',
    delete: 'Apague a postagem',
    edit: 'Editar post',
    footerVideos: 'Vídeos de rodapé',
    tabPhotos: 'Fotos',
    tabVideos: 'Vídeos'
  },
  deleteConfirmation: 'Tem certeza de que deseja excluir <b>{name}</b>?',
  postNotFound: 'Postagem não encontrada!',
  createProfile: 'Criar perfil',
  removeProfile: 'Remover Perfil',
  editProfile: 'Editar Perfil',
  createPost: 'Criar postagem',
  create: 'Crio',
  dontHaveProfile: 'Você não tem perfil social.',
  chooseProfile: 'Escolha um perfil social',
  needSubscribeMessage: 'Você precisa se inscrever para ver o perfil!',
  profilePost: 'Publicar',
  profileFollowing: 'Seguindo',
  profileFollowers: 'Seguidores',
  emptyPostList: 'A lista de postagens está vazia',
  navigationHome: 'casa',
  navigationLiked: 'gostos',
  navigationTrending: 'tendendo',
  navigationFavourites: 'favoritos',
  navigationFollowers: 'seguidores',
  postSettings: 'Configurações de postagem',
  noResultSearch: 'Nenhum resultado encontrado',
  profiles: 'Perfis',
  location: 'Localização',
  tags: 'Tag',
  showMore: 'Mostre mais',
  remove: 'Retirar',
  uploadPhoto: 'Carregar foto',
  dragAndDrop: 'ou apenas arrastar e soltar',
  emptyList: 'A lista está vazia!',
  open: 'Abrir',
  advertiseUsingCampaigns: 'Anuncie aqui usando campanhas',
  postButton: 'Publicar',
  settingsButton: 'Definições',
  viewButton: 'Visualizar',
  deleteButton: 'Excluir',
  posts: 'Postagens',
  profileSettings: 'Configurações de perfil',
  backgroundPicture: 'Imagem de fundo',
  profilePicture: 'Foto do perfil',
  fillUserInfo: 'Por favor, preencha as informações:',
  requiredFields: 'Indica campo obrigatório.',
  formFirstName: 'Primeiro nome',
  formLastName: 'Último nome',
  formNickName: 'Nome do usuário',
  profileEmailInfo: 'Seu e-mail será usado apenas para recuperação. Ao inserir o seu e-mail, você aceita a política de privacidade e os termos e condições da sayHola. Os links serão enviados para o seu e-mail para recuperação.',
  formEmail: 'Seu email',
  error_email_exists: 'Já existe usuário com este endereço de email',
  backToHomePage: 'Voltar à página inicial',
  pageNotFoundMessage: 'Não conseguimos encontrar a página que você está procurando',
  resendOtpMessage: 'Mais um SMS foi enviado.',
  buttonDone: 'Feito',
  viewProfile: 'Ver perfil',
  copyClipboardSuccess: 'Cópia de sucesso para a área de transferência',
  copyClipboardError: 'Erro ao copiar para a área de transferência',
  loginByQRCodeMessage: 'Código QR para login via smartphone',
  authOption: 'Selecione uma opção para Login.',
  newRegistration: 'novo registro',
  registrationOption: 'O registro requer um e-mail e nome de usuário para usar os serviços. Uma vez logado, você tem a opção de sincronizar com outros aplicativos de mensagens usando seu número de telefone',
  loginWithApp: 'login com aplicativo',
  loginWithAppOption: 'Abra o Hola no seu telefone, acesse o menu localizado no canto superior direito do aplicativo. Clique no ícone do código QR. Aponte seu telefone para esta tela para confirmar o login.',
  recoveryOption: 'recuperação',
  recoveryOptionText: 'Para Recuperação Digite seu e-mail cadastrado para fazer login usando o Hot-link. Saiba mais visitando a seção de perguntas frequentes',
  enterEmailRecovery: 'Digite seu e-mail cadastrado para recuperação:',
  notFoundUserLabel: 'Usuário com este e-mail não encontrado',
  successSendRecoveryEmailMessage: 'Verifique seu e-mail e siga as instruções!',
  chatBots: 'ChatBots',
  BdtlAgencyProgram: 'Programa de Agência BDTL',
  agencyTerms: 'Termos da agência',
  holaExtensions: 'Extensão Hola',
  holaBlog: 'Hola Blog',
  browserExtensions: 'Extensões do navegador',
  agencyTermsNew: 'Política da agência',
  earnPrograms: 'Ganhe Programas',
  rewardsProgram: 'Programa de Recompensas',
  despaxoTerms: 'Termos do Despaxo',
  faqWord: 'Perguntas frequentes',
  paidServices: 'Serviços básicos e pagos',
  multiapp: 'Sincronização de vários aplicativos',
  opportunities: 'Oportunidades',
  despaxoDrivers: 'Motoristas Despaxo',
  generalTerms: 'Termos gerais',
  apps: 'Formulários',
  web: 'Rede',
  desktop: 'Área de Trabalho',
  blogSyncPlugin: 'Plug-in de sincronização de blog',
  together: 'Junto',
  chatbotConstructor: 'Construtor de chatbots',
  enterpriseProgram: 'Programa Empresarial',
  bdtlProgram: 'Programa BDTL',
  despaxoBot: 'Programa Despaxo Bots Sync',
  extendetTerms: 'Termos Estendidos',
  despaxoPolicy: 'Política Despaxo',
  despaxoDriverPolicy: 'Política do Motorista Despaxo',
  driverPolicy: 'Política de motorista',
  administrativeStatement: 'Declaração Administrativa',
  despaxoDefinitions: 'Definições de Despaxo',
  operatingDespaxo: 'Despaxo Operacional'
}
