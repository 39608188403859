<template>
  <SecondaryModal
    :name="$options.name"
    :title="$t('post.delete')"
    @before-open="reset"
  >
    <div v-if="postText"
         v-html="$t('deleteConfirmation', { name: text })">
    </div>

    <template v-slot:footer>
      <BaseButton
        oval
        secondary
        @click="$modal.hide($options.name)">
        {{ $t('buttonCancel') }}
      </BaseButton>
      <BaseButton
        oval
        red
        :loading="loading"
        @click="deletePostRequest">
        {{ $t('buttonDelete') }}
      </BaseButton>
    </template>
  </SecondaryModal>
</template>

<script>
import SecondaryModal from './SecondaryModal'
import BaseButton from '@/components/atoms/_FormButton.vue'
import { mapActions } from 'vuex'

export default {
  name: 'delete-social-post',
  components: {
    SecondaryModal,
    BaseButton
  },
  data () {
    return {
      loading: false,
      postId: null,
      postText: null,
      nickname: null
    }
  },
  computed: {
    text () {
      if (!this.postText) return
      if (this.postText.length < 20) return this.postText
      return this.postText.slice(0, 20) + '...'
    }
  },
  methods: {
    reset ({ params: { postId, text, nickname } }) {
      this.loading = false
      this.postId = postId
      this.postText = text
      this.nickname = nickname
    },
    ...mapActions({
      deletePost: 'user/deletePost',
      getProfilePosts: 'profile/getProfilePosts'
    }),
    async deletePostRequest () {
      this.loading = true
      try {
        await this.deletePost({ postId: this.postId, nickname: this.nickname })
      } catch (e) {
        if (e.data.detail === 'Not found.' || e.data.detail === 'Not found') {
          this.$toasted.error(this.$t('postNotFound'))
        }
      } finally {
        this.loading = false
        this.$modal.hide(this.$options.name)
      }
    }
  }
}
</script>
