export default {
  termOfService: 'Term of Services',
  privacyPolicy: 'Privacy Policy',
  xCommunications: 'xCommunications',
  xCommunicationsInvestorRelations: 'Investor Relations',
  xCommunicationsMediaRequest: 'Media Request',
  xCommunicationsOurPositionPrivacy: 'Our Position on Privacy',
  xCommunicationsKnowledgeBase: 'Knowledge Base',
  xCommunicationsOurPosition: 'Our Position on Cyberbullying',
  premiumTools: 'Premium Tools',
  premiumToolsSayHolaLiveChatPlugin: 'Live Chat plugin',
  premiumToolsSayHolaBlogPlugin: 'sayHola Blog plugin',
  premiumToolsTogetherSayHola: 'together by sayHola',
  premiumToolsLiveLeagueApp: 'Live League App',
  programs: 'Programs',
  programsAdvertiseHola: 'Advertise with Hola',
  programsFreelancerProgram: 'Freelancer Program',
  programsAffiliateRewardsProgram: 'Affiliate Rewards Program',
  terms: 'Terms',
  termsAdsPolicy: 'Ads Policy',
  termsPrivacyPolicy: 'Privacy Policy',
  termsCommunityStandards: 'Community Standards',
  termsTermsUse: 'Terms of Use',
  termsLoyaltyRewardsTerms: 'Rewards Policy',
  otp_unconfirmed: 'Otp unconfirmed, try again',
  pin_unconfirmed: 'Wrong PIN code',
  otpDescription: 'An sms code has been sent to you. Please enter code below.',
  otpEnterYourOtp: 'Enter your otp code',
  otpEnterYourPin: 'Enter your pin code',
  verify_otp: 'Confirm',
  otpDontReceiveCode: "Didn't receive the code?",
  otpRequestSMSAgain: 'Request SMS again',
  otpRequestNewCode: 'Request a new code in',
  copyright: 'xCommunications. All rights reserved',
  enterPhoneNumber: 'Please choose your country and enter your full phone number.',
  login: 'Login',
  validationSelectFile: 'Select and upload photo',
  validationSameAs: 'Passwords do not match',
  validationMaxLengthField: 'Maximum number of field characters :field - :count',
  validationMinLengthField: 'Minimum number of field characters :field - :count',
  validationRequired: 'Fill out this field',
  validationEmail: 'Enter a valid email',
  validationUrl: 'Enter a valid url',
  needChangePhone: 'Need to change your number?',
  recovery: 'Recovery',
  needMoreInfo: 'Need more info?',
  learnMore: 'Learn more',
  startUsingHola: 'Start using sayHola to view the <br> Social Space',
  startUsingHolaFollow: 'Start using sayHola to Follow',
  socialSpaceWord: 'social space',
  socialSpaceInvite: 'Social Space invite',
  socialSpaceInviteMessage: 'a safe and secure, private space for business, celebrities, bloggers, influencers, community, and individuals without comments and fake news',
  enterPhone: 'Enter phone',
  socialPost: {
    image: 'Image',
    isEnabledComments: 'Comments',
    isEnabledLikes: 'Like button',
    newPostTitle: 'New Post',
    placeholder: 'Start your message and #hashtags here',
    editPostTitle: 'Edit post',
    deleteButton: 'Delete post',
    editButton: 'Edit post',
    reportButton: 'Report',
    reportConfirmation: 'Are you sure you want to report this post?',
    reportText: "Thank you for letting us know. Your feedback helps with community use practices. Other steps you can take is block user. You won't be able to see or contact each other once you do this.",
    likes: 'likes',
    hideComments: 'Hide comments',
    viewLatestComments: 'View latest comments',
    reply: 'reply',
    replies: 'replies',
    placeholderComment: 'Write a comment',
    placeholderReply: 'Reply to {name}',
    commentReportConfirmation: 'Are you sure you want to report this comment?'
  },
  btnHide: 'Hide',
  btnShow: 'Show',
  btnReply: 'Reply',
  buttonEdit: 'Edit',
  socialComment: {
    deleteConfirmation: 'Are you sure you want to delete comment <b>{text}</b>?'
  },
  buttonLoadMore: 'Load more',
  socialSpace: {
    searchTitle: 'Search',
    accounts: 'Accounts',
    places: 'Places',
    tags: 'Tags'
  },
  searchNoResults: 'No results',
  startTypingForSearch: 'Start typing for search',
  userStatus: {
    btnAdd: 'Add status',
    tabSetStatus: 'Set status',
    tabCreateStatus: 'Create status',
    titlePlaceholder: 'Status title',
    textPlaceholder: 'Start your status here (max 250 characters)',
    editStatusTitle: 'Edit status'
  },
  buttonCancel: 'Cancel',
  buttonDelete: 'Delete',
  blogSubscribeBtn: 'Subscribe',
  blogUnsubscribeBtn: 'Unsubscribe',
  popupConfirmTitle: 'Confirmation',
  popupConfirmText: 'Are you sure?',
  buttonAdd: 'Add',
  buttonConfirm: 'Confirm',
  footerPrivateAppJobsPage: 'Work Opportunities',
  socialProfile: {
    categoryCompany: 'Company',
    categoryIndividual: 'Individual',
    extendedSettings: 'Extended settings',
    firstName: 'First name',
    lastName: 'Last name',
    nickname: 'Nickname',
    isActive: 'Social Profile (Live)',
    profileViewOptions: 'Profile view options',
    isPublic: 'Public (searchable)',
    isChatActive: 'Message me (sayHola)',
    showFollowers: 'Follower count',
    showSocialSpace: 'Show Social Space',
    textLocation: 'Location (city, country)',
    pictureError: 'Please upload your profile picture',
    errorNicknameExists: 'Profile with this nickname already exists',
    editTitle: 'Edit my premium profile',
    btnCancelRequest: 'Cancel request',
    btnFollow: 'Follow',
    btnUnfollow: 'Unfollow',
    followers: 'Followers',
    pending: 'Pending',
    createTitle: 'Create new profile',
    successCreateProfile: 'Profile created successfully!',
    successEditProfile: 'Profile information updated!',
    successDeleteProfile: 'Profile deleted successfully!',
    showCountPosts: 'Posts count',
    showCountFollowing: 'Following count',
    socialProfile: 'Social Profile'
  },
  authLogout: 'Logout',
  logout: {
    confirmation: 'Are you sure you want to log out?',
    allSessions: 'Log out from all sessions',
    title: 'Logout'
  },
  buttonCreate: 'Create',
  buttonRegistration: 'Finish registration',
  buttonEnter: 'Enter Hola',
  buttonPay: 'Pay',
  buttonUpgrade: 'Upgrade',
  buttonCopy: 'Copy',
  buttonSave: 'Save',
  buttonSend: 'Send',
  successTitle: 'Successfully',
  errorTitle: 'Error',
  error: 'Sorry, something went wrong',
  errorEmailInvalid: 'Enter a valid email address',
  errorPhoneInvalid: 'Invalid phone number',
  errorEmptyField: 'This field is required',
  errorAlphanumericDashUnderscore: 'This field can only contain alphanumeric characters, dash and underscore [a-z0-9-_]',
  category: 'Category',
  chooseCategory: 'Choose category',
  chooseCategoryError: 'Please choose a category!',
  errorMinLength_begin: 'Ensure this field has at least',
  errorMaxLength_begin: 'Ensure this field has no more than',
  errorLength_end: 'characters',
  formAttach: 'Attach',
  successCreatePost: 'The post was created successfully',
  successEditPost: 'Post successfully edited',
  post: {
    author: 'Author',
    createTitle: 'Create new post',
    footerImages: 'Footer images',
    headerImage: 'Header image',
    isPublished: 'Published',
    subtitle: 'Subtitle',
    text: 'Text',
    textPlaceholder: 'Start your blog here. The first letter is bold and large',
    title: 'Title',
    delete: 'Delete post',
    edit: 'Edit post',
    footerVideos: 'Footer videos',
    tabPhotos: 'Photos',
    tabVideos: 'Videos'
  },
  deleteConfirmation: 'Are you sure you want to delete <b>{name}</b>?',
  postNotFound: 'Post not found!',
  createProfile: 'Create Profile',
  removeProfile: 'Remove Profile',
  editProfile: 'Edit Profile',
  createPost: 'Create Post',
  create: 'Create',
  dontHaveProfile: 'You don\'t have Social profile.',
  chooseProfile: 'Choose a Social Profile',
  needSubscribeMessage: 'You need to subscribe to view the profile!',
  profilePost: 'Post',
  profileFollowing: 'Following',
  profileFollowers: 'Followers',
  emptyPostList: 'The posts list is empty',
  navigationHome: 'home',
  navigationLiked: 'likes',
  navigationTrending: 'trending',
  navigationFavourites: 'favourites',
  navigationFollowers: 'followers',
  postSettings: 'Post Settings',
  noResultSearch: 'No results found',
  profiles: 'Profiles',
  location: 'Location',
  tags: 'Tags',
  showMore: 'Show more',
  remove: 'Remove',
  uploadPhoto: 'Upload photo',
  dragAndDrop: 'or just Drag and Drop',
  emptyList: 'The list is empty!',
  open: 'Open',
  advertiseUsingCampaigns: 'Advertise here using campaigns',
  postButton: 'Post',
  settingsButton: 'Settings',
  viewButton: 'View',
  deleteButton: 'Delete',
  posts: 'Posts',
  profileSettings: 'Profile Settings',
  backgroundPicture: 'Background picture',
  profilePicture: 'Profile picture',
  fillUserInfo: 'Please fill in the information:',
  requiredFields: 'Indicates required field.',
  formFirstName: 'First Name',
  formLastName: 'Last Name',
  formNickName: 'Username',
  profileEmailInfo: 'Your email will be used for recovery only. By entering your email you accept sayHola privacy policy and terms and conditions. Links will be sent to your email for recovery.',
  formEmail: 'Your email',
  error_email_exists: 'User with this email already exists',
  backToHomePage: 'Back to homepage',
  pageNotFoundMessage: 'We can\'t find the page that you\'re looking for',
  resendOtpMessage: 'One more SMS was sent.',
  buttonDone: 'Done',
  viewProfile: 'View profile',
  copyClipboardSuccess: 'Success copy to clipboard',
  copyClipboardError: 'Error copy to clipboard',
  loginByQRCodeMessage: 'QR code for login via smartphone',
  authOption: 'Please select an option to Login.',
  newRegistration: 'new registration',
  registrationOption: 'Registration requires an email and username to use the services. Once logged-in you have the choice to sync with other messenger Apps using your phone number',
  loginWithApp: 'login with app',
  loginWithAppOption: 'Open Hola on your phone, go to the menu located in top right hand corner of the App. Click the QR code icon. Point your phone at this screen to confirm login.',
  recoveryOption: 'recovery',
  recoveryOptionText: 'For Recovery Enter your registered email to login using Hot-link. Learn more by visiting FAQ section',
  enterEmailRecovery: 'Enter your registered email to recovery:',
  notFoundUserLabel: 'User with this email not found',
  successSendRecoveryEmailMessage: 'Check your email and follow the instructions!',
  chatBots: 'ChatBots',
  BdtlAgencyProgram: 'BDTL Agency Program',
  agencyTerms: 'Agency Terms',
  holaExtensions: 'Hola Extension',
  holaBlog: 'Hola Blog',
  browserExtensions: 'Browser Extensions',
  agencyTermsNew: 'Agency Policy ',
  earnPrograms: 'Earn Programs',
  rewardsProgram: 'Rewards Program',
  despaxoTerms: 'Despaxo Terms',
  faqWord: 'Frequently Asked Questions',
  paidServices: 'Basic & Paid Services',
  multiapp: 'Multi App Synchronization',
  opportunities: 'Opportunities',
  despaxoDrivers: 'Despaxo Drivers',
  generalTerms: 'General Terms',
  apps: 'Applications',
  web: 'Web',
  desktop: 'Desktop',
  blogSyncPlugin: 'Blog Sync Plugin',
  together: 'Together',
  chatbotConstructor: 'Chatbots Constructor',
  enterpriseProgram: 'Enterprise Program',
  bdtlProgram: 'BDTL Program',
  despaxoBot: 'Despaxo Bots Sync Program',
  extendetTerms: 'Extended Terms',
  despaxoPolicy: 'Despaxo Policy',
  despaxoDriverPolicy: 'Despaxo Driver Policy',
  driverPolicy: 'Driver Policy',
  administrativeStatement: 'Administrative Statement',
  despaxoDefinitions: 'Despaxo Definitions',
  operatingDespaxo: 'Operating Despaxo'
}
