export default {
  termOfService: 'Durée des services',
  privacyPolicy: 'Politique de confidentialité',
  xCommunications: 'xCommunications',
  xCommunicationsInvestorRelations: 'Relations avec les investisseurs',
  xCommunicationsMediaRequest: 'Demande média',
  xCommunicationsOurPositionPrivacy: 'Notre position sur la confidentialité',
  xCommunicationsKnowledgeBase: 'Base de connaissances',
  xCommunicationsOurPosition: 'Notre position sur la cyberintimidation',
  premiumTools: 'Outils premium',
  premiumToolsSayHolaLiveChatPlugin: 'Plugin de chat en direct',
  premiumToolsSayHolaBlogPlugin: 'Plugin de blog sayHola',
  premiumToolsTogetherSayHola: 'ensemble par sayHola',
  premiumToolsLiveLeagueApp: 'Application de la ligue en direct',
  programs: 'Programmes',
  programsAdvertiseHola: 'Faites de la publicité avec Hola',
  programsFreelancerProgram: 'Programme de pigistes',
  programsAffiliateRewardsProgram: 'Programme de récompenses d\'affiliation',
  terms: 'termes',
  termsAdsPolicy: 'Politique relative aux annonces',
  termsPrivacyPolicy: 'Politique de confidentialité',
  termsCommunityStandards: 'Normes communautaires',
  termsTermsUse: 'Conditions d\'utilisation',
  termsLoyaltyRewardsTerms: 'Politique de récompenses',
  otp_unconfirmed: 'Otp non confirmé, réessayez',
  pin_unconfirmed: 'Mauvais code PIN',
  otpDescription: 'Un code SMS vous a été envoyé. Veuillez entrer le code ci-dessous.',
  otpEnterYourOtp: 'Entrez votre code OTP',
  otpEnterYourPin: 'Entrez votre code PIN',
  verify_otp: 'Confirmer',
  otpDontReceiveCode: "Vous n'avez pas reçu le code?",
  otpRequestSMSAgain: 'Demander à nouveau un SMS',
  otpRequestNewCode: 'Demander un nouveau code dans',
  copyright: 'xCommunications. Tous les droits sont réservés',
  enterPhoneNumber: 'Veuillez choisir votre pays et entrer votre numéro de téléphone complet.',
  login: 'Connexion',
  validationSelectFile: 'Sélectionnez et téléchargez une photo',
  validationSameAs: 'Les mots de passe ne correspondent pas',
  validationMaxLengthField: 'Nombre maximal de caractères de champ :field - :count',
  validationMinLengthField: 'Nombre minimum de caractères de champ :field - :count',
  validationRequired: 'Remplissez ce champ',
  validationEmail: 'Entrer un email valide',
  validationUrl: 'Entrez une URL valide',
  needChangePhone: 'Besoin de changer de numéro ?',
  recovery: 'Récupération',
  needMoreInfo: 'Besoin de plus d\'informations ?',
  learnMore: 'Apprendre encore plus',
  startUsingHola: 'Commencez à utiliser sayHola pour afficher <br> l\'espace social',
  startUsingHolaFollow: 'Commencez à utiliser sayHola pour suivre',
  socialSpaceWord: 'espace social',
  socialSpaceInvite: 'Invitation à l\'espace social',
  socialSpaceInviteMessage: 'un espace privé sûr et sécurisé pour les entreprises, les célébrités, les blogueurs, les influenceurs, la communauté et les individus sans commentaires ni fausses nouvelles',
  enterPhone: 'Entrer le téléphone',
  footerPrivateAppJobsPage: 'Opportunités de travail',
  socialPost: {
    image: 'Image',
    isEnabledComments: 'Commentaires',
    isEnabledLikes: "J'aime le bouton",
    newPostTitle: 'Nouveau poste',
    placeholder: 'Commencez votre message et #hashtags ici',
    editPostTitle: 'Modifier le message',
    deleteButton: 'Supprimer le message',
    editButton: 'Modifier le message',
    reportButton: 'Signaler',
    reportConfirmation: 'Voulez-vous vraiment signaler cette publication?',
    reportText: "Merci de nous en avoir informé. Vos commentaires aident avec les pratiques d'utilisation de la communauté. D'autres mesures que vous pouvez prendre sont le blocage de l'utilisateur. Vous ne pourrez plus vous voir ou vous contacter une fois que vous aurez fait cela.",
    likes: 'aime',
    hideComments: 'Masquer les commentaires',
    viewLatestComments: 'Voir les derniers commentaires',
    reply: 'répondre',
    replies: 'réponses',
    placeholderComment: 'Écrire un commentaire',
    placeholderReply: 'Répondre à {name}',
    commentReportConfirmation: 'Êtes-vous sûr de vouloir signaler ce commentaire?'
  },
  btnHide: 'Cacher',
  btnShow: 'Spectacle',
  btnReply: 'Répondre',
  buttonEdit: 'Éditer',
  socialComment: {
    deleteConfirmation: 'Êtes-vous sûr de vouloir supprimer le commentaire <b>{text}</b>?'
  },
  buttonLoadMore: 'Charger plus',
  socialSpace: {
    searchTitle: 'Rechercher',
    accounts: 'Comptes',
    places: 'Des endroits',
    tags: 'Mots clés'
  },
  searchNoResults: 'Aucun résultat',
  startTypingForSearch: 'Commencez à taper pour la recherche',
  userStatus: {
    btnAdd: 'Ajouter un statut',
    tabSetStatus: 'Définir le statut',
    tabCreateStatus: 'Créer un statut',
    titlePlaceholder: 'Titre du statut',
    textPlaceholder: 'Commencez votre statut ici (max 250 caractères)',
    editStatusTitle: 'Modifier le statut'
  },
  buttonCancel: 'Cancel',
  buttonDelete: 'Effacer',
  blogSubscribeBtn: "S'abonner",
  blogUnsubscribeBtn: 'Se désabonner',
  popupConfirmTitle: 'Confirmation',
  popupConfirmText: 'Es-tu sûr?',
  buttonAdd: 'Ajouter',
  buttonConfirm: 'Confirmer',
  socialProfile: {
    categoryCompany: 'Société',
    categoryIndividual: 'Individuel',
    extendedSettings: 'Paramètres étendus',
    firstName: 'Prénom',
    lastName: 'Nom de famille',
    nickname: 'Surnom',
    isActive: 'Profil social (en direct)',
    profileViewOptions: "Options d'affichage du profil",
    isPublic: 'Public (recherchable)',
    isChatActive: 'Envoyez-moi un message (dites Hola)',
    showFollowers: "Nombre d'abonnés",
    showSocialSpace: "Afficher l'espace social",
    textLocation: 'Lieu (ville, pays)',
    pictureError: 'Veuillez télécharger votre photo de profil',
    errorNicknameExists: 'Le profil avec ce pseudo existe déjà',
    editTitle: 'Modifier mon profil premium',
    btnCancelRequest: "Demande d'annulation",
    btnFollow: 'Suivre',
    btnUnfollow: 'Ne plus suivre',
    followers: 'Suiveurs',
    pending: 'En attente',
    createTitle: 'Créer un nouveau profil',
    successCreateProfile: 'Profil créé avec succès!',
    successEditProfile: 'Informations de profil mises à jour!',
    successDeleteProfile: 'Profil supprimé avec succès!',
    showCountPosts: 'Les messages comptent',
    showCountFollowing: 'Comptage suivant',
    socialProfile: 'Profil social'
  },
  authLogout: 'Se déconnecter',
  logout: {
    confirmation: 'Êtes-vous sûr de vouloir vous déconnecter?',
    allSessions: 'Se déconnecter de toutes les sessions',
    title: 'Se déconnecter'
  },
  buttonCreate: 'Créer',
  buttonRegistration: "Terminer l'inscription",
  buttonEnter: 'Entre Hola',
  buttonPay: 'Payer',
  buttonUpgrade: 'Améliorer',
  buttonCopy: 'Copie',
  buttonSave: 'Sauvegarder',
  buttonSend: 'Envoyer',
  successTitle: 'Avec succès',
  errorTitle: 'Erreur',
  error: "Désolé, quelque chose s'est mal passé",
  errorEmailInvalid: 'entrez une adresse e-mail valide',
  errorPhoneInvalid: 'Numéro de téléphone invalide',
  errorEmptyField: 'Ce champ est obligatoire',
  errorAlphanumericDashUnderscore: 'Ce champ ne peut contenir que des caractères alphanumériques, tiret et trait de soulignement [a-z0-9-_]',
  category: 'Catégorie',
  chooseCategory: 'Choisissez la catégorie',
  chooseCategoryError: 'Veuillez choisir une catégorie !',
  errorMinLength_begin: 'Assurez-vous que ce champ a au moins',
  errorMaxLength_begin: "Assurez-vous que ce champ n'a pas plus de",
  errorLength_end: 'personnages',
  formAttach: 'Attacher',
  successCreatePost: 'Le message a été créé avec succès',
  successEditPost: 'Message modifié avec succès',
  post: {
    author: 'Auteur',
    createTitle: 'Créer un nouveau message',
    footerImages: 'Images de pied de page',
    headerImage: "Image d'en-tête",
    isPublished: 'Publié',
    subtitle: 'Sous-titre',
    text: 'Texte',
    textPlaceholder: 'Commencez votre blog ici. La première lettre est en gras et grande',
    title: 'Titre',
    delete: 'Supprimer le message',
    edit: 'Modifier le message',
    footerVideos: 'Vidéos de pied de page',
    tabPhotos: 'Photos',
    tabVideos: 'Vidéos'
  },
  deleteConfirmation: 'Etes-vous sûr que vous voulez supprimer <b>{name}</b>?',
  postNotFound: 'Message introuvable !',
  createProfile: 'Créer un profil',
  removeProfile: 'Supprimer le profil',
  editProfile: 'Editer le profil',
  createPost: 'Créer un article',
  create: 'Créer',
  dontHaveProfile: 'Vous n\'avez pas de profil social.',
  chooseProfile: 'Choisissez un profil social',
  needSubscribeMessage: 'Vous devez vous inscrire pour voir le profil!',
  profilePost: 'Poster',
  profileFollowing: 'Suivant',
  profileFollowers: 'Suiveurs',
  emptyPostList: 'La liste des messages est vide',
  navigationHome: 'domicile',
  navigationLiked: 'aime',
  navigationTrending: 'tendance',
  navigationFavourites: 'favoris',
  navigationFollowers: 'suiveurs',
  postSettings: 'Paramètres de publication',
  noResultSearch: 'Aucun résultat trouvé',
  profiles: 'Profils',
  location: 'Emplacement',
  tags: 'Mots clés',
  showMore: 'Montre plus',
  remove: 'Supprimer',
  uploadPhoto: 'Envoyer la photo',
  dragAndDrop: 'ou simplement glisser-déposer',
  emptyList: 'La liste est vide!',
  open: 'Ouvert',
  advertiseUsingCampaigns: 'Annoncez ici à l\'aide de campagnes',
  postButton: 'Poster',
  settingsButton: 'Paramètres',
  viewButton: 'Vue',
  deleteButton: 'Effacer',
  posts: 'Des postes',
  profileSettings: 'Paramètres de profil',
  backgroundPicture: 'Image de fond',
  profilePicture: 'Image de profil',
  fillUserInfo: 'Veuillez remplir les informations :',
  requiredFields: 'Indique le champ obligatoire.',
  formFirstName: 'Prénom',
  formLastName: 'Nom de famille',
  formNickName: 'Nom d\'utilisateur',
  profileEmailInfo: 'Votre e-mail sera utilisé uniquement pour la récupération. En entrant votre e-mail, vous acceptez la politique de confidentialité et les conditions générales de sayHola. Des liens seront envoyés à votre adresse e-mail pour la récupération.',
  formEmail: 'Votre e-mail',
  error_email_exists: 'Utilisateur avec ce courriel existe déjà',
  backToHomePage: 'retour à la page d\'accueil',
  pageNotFoundMessage: 'Nous ne trouvons pas la page que vous recherchez',
  resendOtpMessage: 'Un autre SMS a été envoyé.',
  buttonDone: 'Fait',
  viewProfile: 'Voir le profil',
  copyClipboardSuccess: 'Copie réussie dans le presse-papiers',
  copyClipboardError: 'Erreur de copie dans le presse-papiers',
  loginByQRCodeMessage: 'Code QR pour la connexion via smartphone',
  authOption: 'Veuillez sélectionner une option pour vous connecter.',
  newRegistration: 'nouvelle inscription',
  registrationOption: 'L\'inscription nécessite un e-mail et un nom d\'utilisateur pour utiliser les services. Une fois connecté, vous avez le choix de vous synchroniser avec d\'autres applications de messagerie en utilisant votre numéro de téléphone',
  loginWithApp: 'se connecter avec l\'application',
  loginWithAppOption: 'Ouvrez Hola sur votre téléphone, accédez au menu situé dans le coin supérieur droit de l\'application. Cliquez sur l\'icône du code QR. Pointez votre téléphone vers cet écran pour confirmer la connexion.',
  recoveryOption: 'récupération',
  recoveryOptionText: 'Pour la récupération Entrez votre e-mail enregistré pour vous connecter en utilisant Hot-link. En savoir plus en visitant la section FAQ',
  enterEmailRecovery: 'Entrez votre e-mail enregistré pour récupérer:',
  notFoundUserLabel: 'Utilisateur avec cet e-mail introuvable',
  successSendRecoveryEmailMessage: 'Vérifiez votre messagerie et suivez les instructions !',
  chatBots: 'ChatBots',
  BdtlAgencyProgram: 'Programme Agence BDTL',
  agencyTerms: "Conditions d'utilisation de l'agence",
  holaExtensions: 'Hola Extension',
  holaBlog: 'Hola Blog',
  browserExtensions: 'Extensions de navigateur',
  agencyTermsNew: 'Politique de l\'agence',
  earnPrograms: 'Gagnez des programmes',
  rewardsProgram: 'Programme de récompenses',
  despaxoTerms: 'Conditions d\'utilisation',
  faqWord: 'Questions fréquemment posées',
  paidServices: 'Services de base et payants',
  multiapp: 'Synchronisation multi-applications',
  opportunities: 'Opportunités',
  despaxoDrivers: 'Pilotes Despaxo',
  generalTerms: 'Conditions générales',
  apps: 'Applications',
  web: 'la toile',
  desktop: 'Bureau',
  blogSyncPlugin: 'Plugin de synchronisation de blog',
  together: 'Ensemble',
  chatbotConstructor: 'Constructeur de chatbots',
  enterpriseProgram: 'Programme d\'entreprise',
  bdtlProgram: 'Programme BDTL',
  despaxoBot: 'Programme de synchronisation des robots Despaxo',
  extendetTerms: 'Termes prolongés',
  despaxoPolicy: 'Politique Despaxo',
  despaxoDriverPolicy: 'Politique des chauffeurs Despaxo',
  driverPolicy: 'Politique du conducteur',
  administrativeStatement: 'Déclaration administrative',
  despaxoDefinitions: 'Définitions Despaxo',
  operatingDespaxo: 'Exploitation Despaxo'
}
