export default {
  termOfService: 'सेवा की शर्तें',
  privacyPolicy: 'गोपनीयता नीति',
  xCommunications: 'xसंचार',
  xCommunicationsInvestorRelations: 'निवेशक सम्बन्ध',
  xCommunicationsMediaRequest: 'मीडिया अनुरोध',
  xCommunicationsOurPositionPrivacy: 'गोपनीयता पर हमारी स्थिति',
  xCommunicationsKnowledgeBase: 'ज्ञानधार',
  xCommunicationsOurPosition: 'साइबरबुलिंग पर हमारी स्थिति',
  premiumTools: 'प्रीमियम उपकरण',
  premiumToolsSayHolaLiveChatPlugin: 'लाइव चैट प्लगइन',
  premiumToolsSayHolaBlogPlugin: 'SayHola ब्लॉग प्लगइन',
  premiumToolsTogetherSayHola: 'एक साथ SayHola . द्वारा',
  premiumToolsLiveLeagueApp: 'लाइव लीग ऐप',
  programs: 'कार्यक्रमों',
  programsAdvertiseHola: 'होला के साथ विज्ञापन दें',
  programsFreelancerProgram: 'फ्रीलांसर कार्यक्रम',
  programsAffiliateRewardsProgram: 'संबद्ध पुरस्कार कार्यक्रम',
  terms: 'मामले',
  termsAdsPolicy: 'विज्ञापन नीति',
  termsPrivacyPolicy: 'गोपनीयता नीति',
  termsCommunityStandards: 'समुदाय मानकों',
  termsTermsUse: 'उपयोग की शर्तें',
  termsLoyaltyRewardsTerms: 'पुरस्कार नीति',
  otp_unconfirmed: 'ओटीपी की पुष्टि नहीं हुई है, फिर से कोशिश करें',
  pin_unconfirmed: 'गलत पिन कोड',
  otpDescription: 'आपको एक एसएमएस कोड भेजा गया है। कृपया नीचे कोड दर्ज करें।',
  otpEnterYourOtp: 'अपना ओटीपी कोड दर्ज करें',
  otpEnterYourPin: 'अपना पिन कोड दर्ज करें',
  verify_otp: 'पुष्टि करना',
  otpDontReceiveCode: 'कोड प्राप्त नहीं हुआ?',
  otpRequestSMSAgain: 'फिर से एसएमएस का अनुरोध करें',
  otpRequestNewCode: 'में एक नए कोड का अनुरोध करें',
  copyright: 'एक्स संचार। सर्वाधिकार सुरक्षित',
  enterPhoneNumber: 'कृपया अपना देश चुनें और अपना पूरा फोन नंबर दर्ज करें।',
  login: 'लॉग इन करें',
  validationSelectFile: 'फोटो चुनें और अपलोड करें',
  validationSameAs: 'पासवर्ड मेल नहीं खाते',
  validationMaxLengthField: 'फ़ील्ड वर्णों की अधिकतम संख्या :field - :count',
  validationMinLengthField: 'फ़ील्ड वर्णों की न्यूनतम संख्या :field - :count',
  validationRequired: 'इस फ़ील्ड को भरें',
  validationEmail: 'एक वैध ईमेल प्रविष्ट करें',
  validationUrl: 'एक मान्य यूआरएल दर्ज करें',
  needChangePhone: 'अपना नंबर बदलने की जरूरत है?',
  recovery: 'स्वास्थ्य लाभ',
  needMoreInfo: 'अधिक जानकारी चाहिए?',
  learnMore: 'और अधिक जानें',
  startUsingHola: 'देखने के लिए SayHola का उपयोग शुरू करें <br> सामाजिक स्थान',
  startUsingHolaFollow: 'अनुसरण करने के लिए SayHola का उपयोग करना प्रारंभ करें',
  socialSpaceWord: 'सामाजिक स्थान',
  socialSpaceInvite: 'सोशल स्पेस आमंत्रण',
  socialSpaceInviteMessage: 'व्यापार, मशहूर हस्तियों, ब्लॉगर्स, प्रभावित करने वालों, समुदाय और व्यक्तियों के लिए टिप्पणियों और नकली समाचारों के बिना एक सुरक्षित और सुरक्षित, निजी स्थान',
  enterPhone: 'फ़ोन दर्ज करें',
  socialPost: {
    image: 'छवि',
    isEnabledComments: 'टिप्पणियाँ',
    isEnabledLikes: 'बटन की तरह',
    newPostTitle: 'नई पोस्ट',
    placeholder: 'अपना संदेश और #हैशटैग यहां प्रारंभ करें',
    editPostTitle: 'संपादित पोस्ट',
    deleteButton: 'पोस्ट को हटाएं',
    editButton: 'संपादित पोस्ट',
    reportButton: 'प्रतिवेदन',
    reportConfirmation: 'क्या आप निश्चित रूप से इस पोस्ट की रिपोर्ट करना चाहते हैं?',
    reportText: 'हमें बताने के लिए धन्यवाद। आपकी प्रतिक्रिया सामुदायिक उपयोग प्रथाओं में मदद करती है। अन्य कदम जो आप उठा सकते हैं वह है ब्लॉक यूजर। ऐसा करने के बाद आप एक दूसरे को देख या संपर्क नहीं कर पाएंगे।',
    likes: 'को यह पसंद है',
    hideComments: 'टिप्पणियां छुपाएं',
    viewLatestComments: 'नवीनतम टिप्पणियाँ देखें',
    reply: 'जवाब दे दो',
    replies: 'जवाब',
    placeholderComment: 'एक टिप्पणी लिखें',
    placeholderReply: 'को उत्तर {name}',
    commentReportConfirmation: 'क्या आप वाकई इस टिप्पणी की रिपोर्ट करना चाहते हैं?'
  },
  btnHide: 'छिपाना',
  btnShow: 'प्रदर्शन',
  btnReply: 'जवाब',
  buttonEdit: 'संपादित करें',
  socialComment: {
    deleteConfirmation: 'क्या आप वाकई टिप्पणी हटाना चाहते हैं <b>{text}</b>?'
  },
  buttonLoadMore: 'और लोड करें',
  socialSpace: {
    searchTitle: 'खोज',
    accounts: 'हिसाब किताब',
    places: 'स्थानों',
    tags: 'टैग'
  },
  searchNoResults: 'कोई परिणाम नहीं',
  startTypingForSearch: 'खोज के लिए टाइप करना प्रारंभ करें',
  userStatus: {
    btnAdd: 'स्थिति जोड़ें',
    tabSetStatus: 'स्थिति सेट करें',
    tabCreateStatus: 'स्थिति बनाएं',
    titlePlaceholder: 'स्थिति शीर्षक',
    textPlaceholder: 'अपनी स्थिति यहां प्रारंभ करें (अधिकतम 250 वर्ण)',
    editStatusTitle: 'स्थिति संपादित करें'
  },
  buttonCancel: 'रद्द करें',
  buttonDelete: 'हटाएं',
  blogSubscribeBtn: 'सदस्यता लेने के',
  blogUnsubscribeBtn: 'सदस्यता रद्द',
  popupConfirmTitle: 'पुष्टीकरण',
  popupConfirmText: 'क्या आपको यकीन है?',
  buttonAdd: 'जोड़ें',
  buttonConfirm: 'पुष्टि करना',
  footerPrivateAppJobsPage: 'काम के अवसर',
  socialProfile: {
    categoryCompany: 'कंपनी',
    categoryIndividual: 'व्यक्ति',
    extendedSettings: 'विस्तारित सेटिंग्स',
    firstName: 'पहला नाम',
    lastName: 'अंतिम नाम',
    nickname: 'उपनाम',
    isActive: 'सामाजिक प्रोफ़ाइल (लाइव)',
    profileViewOptions: 'प्रोफ़ाइल देखने के विकल्प',
    isPublic: 'सार्वजनिक (खोज योग्य)',
    isChatActive: 'मुझे संदेश भेजें (कहोहोला)',
    showFollowers: 'अनुयायी संख्या',
    showSocialSpace: 'सामाजिक स्थान दिखाएं',
    textLocation: 'स्थान (शहर, देश)',
    pictureError: 'कृपया अपना प्रोफ़ाइल चित्र अपलोड करें',
    errorNicknameExists: 'इस उपनाम वाली प्रोफ़ाइल पहले से मौजूद है',
    editTitle: 'मेरी प्रीमियम प्रोफ़ाइल संपादित करें',
    btnCancelRequest: 'अनुरोध को रद्द करें',
    btnFollow: 'अनुसरण करना',
    btnUnfollow: 'करें',
    followers: 'समर्थक',
    pending: 'लंबित',
    createTitle: 'नई प्रोफ़ाइल बनाएं',
    successCreateProfile: 'प्रोफ़ाइल सफलतापूर्वक बनाई गई!',
    successEditProfile: 'प्रोफ़ाइल जानकारी अपडेट की गई!',
    successDeleteProfile: 'प्रोफ़ाइल सफलतापूर्वक हटाई गई!',
    showCountPosts: 'पोस्ट गिनती',
    showCountFollowing: 'निम्नलिखित गिनती',
    socialProfile: 'सामाजिक प्रोफ़ाइल'
  },
  authLogout: 'लॉग आउट',
  logout: {
    confirmation: 'क्या आप लॉग आउट करना चाहते हैं?',
    allSessions: 'सभी सत्रों से लॉग आउट करें',
    title: 'लॉग आउट'
  },
  buttonCreate: 'सृजन करना',
  buttonRegistration: 'पंजीकरण समाप्त करें',
  buttonEnter: 'होला दर्ज करें',
  buttonPay: 'वेतन',
  buttonUpgrade: 'अपग्रेड',
  buttonCopy: 'प्रतिलिपि',
  buttonSave: 'सहेजें',
  buttonSend: 'भेजना',
  successTitle: 'सफलतापूर्वक',
  errorTitle: 'त्रुटि',
  error: 'क्षमा करें, कुछ गलत हो गया',
  errorEmailInvalid: 'एक मान्य ईमेल पता दर्ज करें',
  errorPhoneInvalid: 'अवैध फोन नंबर',
  errorEmptyField: 'यह फ़ील्ड आवश्यक है',
  errorAlphanumericDashUnderscore: 'इस फ़ील्ड में केवल अक्षरांकीय वर्ण, डैश और अंडरस्कोर शामिल हो सकते हैं [a-z0-9-_]',
  category: 'वर्ग',
  chooseCategory: 'श्रेणी का चयन करें',
  chooseCategoryError: 'कृपया एक कैटेगरी चुनें!',
  errorMinLength_begin: 'सुनिश्चित करें कि इस क्षेत्र में कम से कम',
  errorMaxLength_begin: 'सुनिश्चित करें कि इस क्षेत्र में अधिक से अधिक नहीं है',
  errorLength_end: 'पात्र',
  formAttach: 'संलग्न करें',
  successCreatePost: 'पोस्ट सफलतापूर्वक बनाया गया था',
  successEditPost: 'पोस्ट सफलतापूर्वक संपादित किया गया',
  post: {
    author: 'लेखक',
    createTitle: 'नई पोस्ट बनाएं',
    footerImages: 'पाद चित्र',
    headerImage: 'प्रवेशिका प्रतिमा',
    isPublished: 'प्रकाशित',
    subtitle: 'उपशीर्षक',
    text: 'मूलपाठ',
    textPlaceholder: 'यहां अपना ब्लॉग शुरू करें। पहला अक्षर बोल्ड और बड़ा है',
    title: 'शीर्षक',
    delete: 'पोस्ट को हटाएं',
    edit: 'संपादित पोस्ट',
    footerVideos: 'पाद वीडियो',
    tabPhotos: 'तस्वीरें',
    tabVideos: 'वीडियो'
  },
  deleteConfirmation: 'बहुत ही पास से <b>{name}</b>?',
  postNotFound: 'पोस्ट नहीं मिला!',
  createProfile: 'प्रोफ़ाइल बनाने',
  removeProfile: 'प्रोफ़ाइल हटाएं',
  editProfile: 'प्रोफ़ाइल संपादित करें',
  createPost: 'पोस्ट बनाएं',
  create: 'सृजन करना',
  dontHaveProfile: 'आपके पास सामाजिक प्रोफ़ाइल नहीं है।',
  chooseProfile: 'एक सामाजिक प्रोफ़ाइल चुनें',
  needSubscribeMessage: 'प्रोफ़ाइल देखने के लिए आपको सदस्यता लेनी होगी!',
  profilePost: 'पद',
  profileFollowing: 'अगले',
  profileFollowers: 'समर्थक',
  emptyPostList: 'पदों की सूची खाली है',
  navigationHome: 'घर',
  navigationLiked: 'को यह पसंद है',
  navigationTrending: 'ट्रेंडिंग',
  navigationFavourites: 'पसंदीदा',
  navigationFollowers: 'अनुयायियों',
  postSettings: 'पोस्ट सेटिंग्स',
  noResultSearch: 'कोई परिणाम नहीं मिला',
  profiles: 'प्रोफाइल',
  location: 'स्थान',
  tags: 'टैग',
  showMore: 'और दिखाओ',
  remove: 'हटाना',
  uploadPhoto: 'फोटो अपलोड करें',
  dragAndDrop: 'या बस खींचें और छोड़ें',
  emptyList: 'सूची खाली है!',
  open: 'खुला हुआ',
  advertiseUsingCampaigns: 'अभियानों का उपयोग करके यहां विज्ञापन दें',
  postButton: 'पद',
  settingsButton: 'समायोजन',
  viewButton: 'राय',
  deleteButton: 'हटाएं',
  posts: 'पदों',
  profileSettings: 'पार्श्वचित्र समायोजन',
  backgroundPicture: 'पृष्ठभूमि चित्र',
  profilePicture: 'प्रोफ़ाइल फोटो',
  fillUserInfo: 'कृपया जानकारी भरें:',
  requiredFields: 'आवश्यक फ़ील्ड दर्शाता है।',
  formFirstName: 'पहला नाम',
  formLastName: 'अंतिम नाम',
  formNickName: 'उपयोगकर्ता नाम',
  profileEmailInfo: 'आपके ईमेल का उपयोग केवल पुनर्प्राप्ति के लिए किया जाएगा। अपना ईमेल दर्ज करके आप SayHola गोपनीयता नीति और नियम और शर्तें स्वीकार करते हैं। पुनर्प्राप्ति के लिए आपके ईमेल पर लिंक भेजे जाएंगे।',
  formEmail: 'आपका ईमेल',
  error_email_exists: 'इस ईमेल के साथ पहले से प्रयोगकर्ता मौजूद है',
  backToHomePage: 'मुखपृष्ठ पर वापस',
  pageNotFoundMessage: 'हमें वह पृष्ठ नहीं मिल रहा है जिसकी आप तलाश कर रहे हैं',
  resendOtpMessage: 'एक और एसएमएस भेजा गया।',
  buttonDone: 'किया हुआ',
  viewProfile: 'प्रोफ़ाइल देखें',
  copyClipboardSuccess: 'क्लिपबोर्ड पर सफलता की प्रतिलिपि',
  copyClipboardError: 'क्लिपबोर्ड पर कॉपी करने में त्रुटि',
  loginByQRCodeMessage: 'स्मार्टफोन के माध्यम से लॉगिन करने के लिए क्यूआर कोड',
  authOption: 'कृपया लॉग इन करने के लिए एक विकल्प चुनें।',
  newRegistration: 'नया पंजीकरण',
  registrationOption: 'सेवाओं का उपयोग करने के लिए पंजीकरण के लिए एक ईमेल और उपयोगकर्ता नाम की आवश्यकता होती है। एक बार लॉग-इन करने के बाद आपके पास अपने फोन नंबर का उपयोग करके अन्य मैसेंजर ऐप्स के साथ सिंक करने का विकल्प होता है',
  loginWithApp: 'ऐप के साथ लॉगिन करें',
  loginWithAppOption: 'अपने फोन पर होला खोलें, ऐप के ऊपरी दाएं कोने में स्थित मेनू पर जाएं। क्यूआर कोड आइकन पर क्लिक करें। लॉगिन की पुष्टि करने के लिए अपने फोन को इस स्क्रीन पर इंगित करें।',
  recoveryOption: 'स्वास्थ्य लाभ',
  recoveryOptionText: 'रिकवरी के लिए हॉट-लिंक का उपयोग करके लॉगिन करने के लिए अपना पंजीकृत ईमेल दर्ज करें। अक्सर पूछे जाने वाले प्रश्न अनुभाग पर जाकर और जानें',
  enterEmailRecovery: 'पुनर्प्राप्ति के लिए अपना पंजीकृत ईमेल दर्ज करें:',
  notFoundUserLabel: 'इस ईमेल वाला उपयोगकर्ता नहीं मिला',
  successSendRecoveryEmailMessage: 'अपना ईमेल जांचें और निर्देशों का पालन करें!',
  chatBots: 'चैटबॉट्स',
  BdtlAgencyProgram: 'बीडीटीएल एजेंसी कार्यक्रम',
  agencyTerms: 'एजेंसी शर्तें',
  holaExtensions: 'होला एक्सटेंशन',
  holaBlog: 'होला ब्लॉग',
  browserExtensions: 'ब्राउज़र एक्सटेंशन',
  agencyTermsNew: 'एजेंसी नीति',
  earnPrograms: 'कार्यक्रम कमाएँ',
  rewardsProgram: 'पुरस्कार कार्यक्रम',
  despaxoTerms: 'डेस्पाक्सो शर्तें',
  faqWord: 'अक्सर पूछे जाने वाले प्रश्नों',
  paidServices: 'बुनियादी और सशुल्क सेवाएं',
  multiapp: 'मल्टी ऐप सिंक्रोनाइज़ेशन',
  opportunities: 'अवसर',
  despaxoDrivers: 'डेस्पाक्सो ड्राइवर्स',
  generalTerms: 'सामान्य शर्तें',
  apps: 'अनुप्रयोग',
  web: 'वेब',
  desktop: 'डेस्कटॉप',
  blogSyncPlugin: 'ब्लॉग सिंक प्लगइन',
  together: 'साथ में',
  chatbotConstructor: 'चैटबॉट्स कंस्ट्रक्टर',
  enterpriseProgram: 'उद्यम कार्यक्रम',
  bdtlProgram: 'बीडीटीएल कार्यक्रम',
  despaxoBot: 'डेस्पाक्सो बॉट्स सिंक प्रोग्राम',
  extendetTerms: 'विस्तारित शर्तें',
  despaxoPolicy: 'डेस्पाक्सो नीति',
  despaxoDriverPolicy: 'डेस्पाक्सो चालक नीति',
  driverPolicy: 'चालक नीति',
  administrativeStatement: 'प्रशासनिक वक्तव्य',
  despaxoDefinitions: 'डेस्पाक्सो परिभाषाएँ',
  operatingDespaxo: 'ऑपरेटिंग डेस्पाक्सो'
}
