import AuthSection from '@/modules/auth/index.vue'
import ProfileSection from '@/modules/profile/index.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'start',
  data () {
    return {}
  },
  components: {
    AuthSection,
    ProfileSection
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated'
    })
  }
}
