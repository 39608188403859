<template>
  <div
      class="comment-container"
      v-observe-visibility="{
    callback: onVisibilityChanged,
    once: true,
    intersection: {
      threshold: 1
    }
  }">
    <div class="social-comment__list" v-if="postCommentsList.length">
      <SocialCommentsListItem
          v-for="comment in commentsDisplay" :key="comment.id"
          :comment="comment"
          :postId="postId"
      />
      <span v-if="isNext"
            @click="showMore"
            class="load-more">
        {{ $t('buttonLoadMore') }}
      </span>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { ObserveVisibility } from 'vue-observe-visibility'
import Vue from 'vue'
import SocialCommentsListItem from '../components/SocialCommentsListItem.vue'
Vue.component('SocialCommentsListItem', SocialCommentsListItem)

const LIMIT = 5

export default {
  name: 'social-comments-list',
  directives: {
    ObserveVisibility
  },
  props: {
    postId: Number
  },
  data () {
    return {
      isOpened: false,
      isNext: false,
      offsetComment: 0
    }
  },
  computed: {
    ...mapGetters({
      postCommentsList: 'profile/postCommentsList',
      fetchCommentsLoading: 'profile/fetchCommentsLoading'
    }),
    comments () {
      return this.postCommentsList.find((item) => item.postId === this.postId)
    },
    commentsDisplay () {
      if (!this.comments?.comments || !this.comments?.comments.length) return []
      return this.comments.comments ? this.comments.comments : []
    }
  },
  watch: {
    isOpened (newVal) {
      if (newVal) {
        this.onFetch(0)
      } else {
        this.clearData()
      }
    }
  },
  methods: {
    ...mapActions({
      fetchPostComments: 'profile/fetchPostComments'
    }),
    ...mapMutations({
      clearCommentsList: 'profile/clearCommentsList'
    }),
    onVisibilityChanged (isVisible) {
      if (!isVisible) return
      this.onFetch(0)
    },
    async onFetch (offset) {
      await this.fetchPostComments({ postId: this.postId, limit: LIMIT, offset }).then((response) => {
        const index = this.postCommentsList.findIndex((e) => e.postId === this.postId)
        if (index !== -1) {
          this.isNext = this.postCommentsList[index].isNext
        }
      }).catch((e) => {
        console.log(e.response)
      })
    },
    showMore () {
      this.offsetComment = this.offsetComment + LIMIT
      this.onFetch(this.offsetComment)
    },
    clearData () {
      this.isNext = true
    }
  },
  destroyed () {
    this.clearCommentsList(true)
  }
}
</script>

<style lang="scss">
.comment-container {
  margin: 10px 0;
  padding: 0 20px 10px;
  position: relative;
  @include respondTo(600px){
    padding: 0 10px 10px;
  }
}
.button {
  color: $black;
  cursor: pointer;
}
.arrow {
  margin-right: 4px;
}
.comments-view,{
  color: $black;
  font-size: 14px;
  cursor: pointer;
}
.load-more{
  color: #a0a0a0;
  font-size: 14px;
  cursor: pointer;
}
</style>
