<template>
  <SecondaryModal
    :name="$options.name"
    :clickToClose="false"
    class="registration-popup">
    <div slot="default"
         class="registration-wrap">
      <template>
        <div class="registration">
          <get-opt-step v-if="!otpResponse"></get-opt-step>
          <confirm-opt-step v-else></confirm-opt-step>
        </div>
      </template>
    </div>
  </SecondaryModal>
</template>

<script>
import SecondaryModal from './SecondaryModal'
import GetOptStep from '@/components/features/GetOTP.vue'
import ConfirmOptStep from '@/components/features/ConfirmOTP.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'registration',
  components: {
    SecondaryModal,
    GetOptStep,
    ConfirmOptStep
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters({
      otpResponse: 'auth/otpResponse',
      isAuthenticated: 'auth/isAuthenticated',
      socialInfo: 'auth/socialInfo'
    }),
    privateAppLink () {
      return process.env.VUE_APP_PRIVATEAPP_LINK
    },
    holaSignInLink () {
      return process.env.VUE_APP_DASHBOARD_SIGNIN_LINK
    }
  },
  methods: {
    ...mapActions({
      authByCode: 'auth/authByCode'
    })
  }
}
</script>
<style lang="scss">
.registration-popup {
  .vm--overlay {
    background: rgba(0,0,0,0.5);
    backdrop-filter: blur(2px);
  }
  .vm--modal{
    @include respondTo(500px) {
      max-width: 95%;
      margin: 0 auto;
      padding: 15px 0;
      left: auto !important;
    }
  }
}
</style>
<style lang="scss" scoped>
</style>
