import MobileAppLinks from '@/components/atoms/MobileAppLinks'
import { mapActions, mapGetters } from 'vuex'

export default {
  data () {
    return {
      params: ''
    }
  },
  components: {
    MobileAppLinks
  },
  computed: {
    ...mapGetters({
      otpResponse: 'auth/otpResponse',
      isAuthenticated: 'auth/isAuthenticated',
      socialInfo: 'auth/socialInfo'
    }),
    privateAppLink () {
      return process.env.VUE_APP_PRIVATEAPP_LINK
    }
  },
  created () {
    this.params = this.$route.params
    if (this.params.nickname) {
      this.getSocialInfo(this.params.nickname).then(() => {}).catch(error => {
        this.$toasted.error(error.response.data.type)
        this.$router.push({ name: 'start' }).catch(() => { console.log() })
      })
    }
  },
  methods: {
    ...mapActions({
      getSocialInfo: 'auth/getSocialInfo'
    }),
    openQRCodePopup () {
      this.$modal.show('login-by-code')
    },
    openRegistrationPopup () {
      this.$modal.show('registration')
    },
    openRecoveryPopup () {
      this.$modal.show('recovery')
    }
  }
}
