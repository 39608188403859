<template>
  <SecondaryModal
    :name="$options.name"
    :clickToClose="false"
    @before-open="beforeOpen"
    class="recovery-popup">
    <div slot="default"
         class="recovery-wrap">
      <template>
        <div class="recovery" v-if="step === 1">
          <div class="recovery-title">
            {{ $t('enterEmailRecovery') }}
          </div>
          <form class="recovery-form"
                @submit.prevent="recovery">
              <BaseInput
                v-model="email"
                :placeholder="$t('formEmail')"
                :invalid="$v.email.$error"
                :error="emailErrors"
                @input="errors = {}"
              />
            <p v-if="isError" class="form--error">
              {{ $t('notFoundUserLabel') }}
            </p>
            <div class="recovery-button">
              <base-button
                :disabled="this.$v.$invalid"
                :progress="loading">
                {{ $t('verify_otp') }}
              </base-button>
            </div>
          </form>
        </div>
        <div class="recovery-success" v-if="step === 2">
          {{ $t('successSendRecoveryEmailMessage') }}
        </div>
      </template>
    </div>
  </SecondaryModal>
</template>

<script>
import SecondaryModal from './SecondaryModal'
import BaseButton from '@/components/atoms/BaseButton.vue'
import BaseInput from '@/components/atoms/BaseInput.vue'
import { mapActions, mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate'
import { email, required } from 'vuelidate/lib/validators'
import { get } from 'lodash'

export default {
  name: 'recovery',
  mixin: [validationMixin],
  components: {
    SecondaryModal,
    BaseButton,
    BaseInput
  },
  data () {
    return {
      email: '',
      loading: false,
      isError: false,
      step: 1
    }
  },
  validations: {
    email: {
      required,
      email
    }
  },
  computed: {
    ...mapGetters({
      otpResponse: 'auth/otpResponse',
      isAuthenticated: 'auth/isAuthenticated',
      socialInfo: 'auth/socialInfo'
    }),
    emailErrors () {
      if (!this.$v.email.required) {
        return this.$t('errorEmptyField')
      } else if (!this.$v.email.email) {
        return this.$t('errorEmailInvalid')
      }
      return this.$t('errorEmptyField')
    }
  },
  methods: {
    ...mapActions({
      requestMagicRecovery: 'auth/requestMagicRecovery'
    }),
    beforeOpen () {
      this.resetForm()
    },
    resetForm () {
      this.email = ''
      this.isError = false
      this.$v.$reset()
      this.step = 1
    },
    async recovery () {
      this.$v.$touch()
      if (this.loading || this.$v.$invalid) return
      this.loading = true
      this.isError = false
      try {
        await this.requestMagicRecovery(this.email)
        this.step = 2
      } catch (e) {
        const error = get(e, 'response.data', {})
        if (error.errorType === 'existence') {
          this.isError = true
        }
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
<style lang="scss">
.recovery-popup {
  .vm--overlay {
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(2px);
  }

  .vm--modal {
    @include respondTo(500px) {
      max-width: 95%;
      margin: 0 auto;
      padding: 15px 0;
      left: auto !important;
    }
  }
  .recovery-form{
    .form--input {
      border: 1px solid rgba(17, 17, 17, 0.15);
      transition: border-color $trans;
      padding: 0 10px;
      &:focus{
        border: 1px solid rgba(17, 17, 17, 1);
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.recovery {
  @include respondTo(500px){
    max-width: 320px;
    margin: 0 auto;
    padding: 20px 15px 30px;
  }
  &-title {
    font-size: 18px;
    line-height: 1.3;
    font-weight: 500;
    color: #3b3b3b;
    max-width: 320px;
    margin: 0 auto;
    text-align: center;
    @include respondTo(600px){
      font-size: 14px;
      line-height: 20px;
      max-width: 230px;
      margin: 0 auto;
    }
  }
  &-success {
    font-size: 18px;
    line-height: 1.3;
    font-weight: 500;
    color: #3b3b3b;
    padding: 30px 10px;
    @include respondTo(600px){
      font-size: 14px;
      line-height: 20px;
      max-width: 230px;
      margin: 0 auto;
    }
  }
}
</style>
