import { mapActions, mapGetters, mapMutations } from 'vuex'
import Loader from '@/components/atoms/Spinner.vue'
import PostListItem from '../profile/components/PostListItem.vue'

const OFFSET = 20

export default {
  name: 'Favourites',
  data () {
    return {
      page: 0
    }
  },
  components: {
    Loader,
    PostListItem
  },
  computed: {
    ...mapGetters({
      favouritesList: 'favourites/favouritesList',
      favouritesListCount: 'favourites/favouritesListCount',
      favouritesListLoading: 'favourites/favouritesListLoading',
      profilePosts: 'profile/profilePosts',
      myProfile: 'user/myProfile',
      favouritesListNextPage: 'favourites/favouritesListNextPage'
    })
  },
  created () {
    this.getInitFavouritesList()
  },
  methods: {
    ...mapMutations({
      clearPostsList: 'profile/clearPostsList'
    }),
    ...mapActions({
      getFavouritesList: 'favourites/getFavouritesList'
    }),
    getInitFavouritesList () {
      this.getFavouritesList({
        id: this.myProfile.userId,
        limit: OFFSET,
        offset: OFFSET * this.page
      })
      this.page += 1
    },
    async infiniteLoading () {
      if (this.favouritesListNextPage) {
        this.getFavouritesList({
          id: this.myProfile.userId,
          limit: OFFSET,
          offset: OFFSET * this.page
        })
        this.page += 1
      }
    }
  },
  destroyed () {
    this.clearPostsList(true)
  }
}
