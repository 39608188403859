<template>
  <div class="comment" v-if="comment">
    <div class="comment-top">
      <span class="comment-user">
        {{ comment.nickname ? comment.nickname : comment.id }}
      </span>
      <span
        class="comment-text">
        {{ comment.text }}
      </span>
    </div>
    <div class="comment-info">
      <v-popover placement="bottom-start" popoverClass="no-arrow" v-if="isShowActionMenu">
        <div class="action-arrow">
          <svg fill="#00b764" width="16px" height="16px" xmlns="http://www.w3.org/2000/svg"
               viewBox="0 0 426.667 426.667"
               class="custom-icon tooltip-target">
            <circle cx="42.667" cy="213.333" r="42.667"></circle>
            <circle
                cx="213.333" cy="213.333" r="42.667"></circle>
            <circle cx="384" cy="213.333"
                    r="42.667"></circle>
          </svg>
        </div>
        <MenuBase
            slot="popover" v-close-popover
            :options="menu"
        />
      </v-popover>
      <div class="comment-time">{{ time }}</div>
      <div v-if="!comment.isReplyComment"
           @click="isShowInput = !isShowInput"
           class="reply-btn">
        {{ $t('btnReply') }}
      </div>
      <div
          v-if="comment.repliesCount"
          @click="toggleReplies"
          class="reply-btn">
        {{ isRepliesOpened ? $t('btnHide') : $t('btnShow') }} {{ comment.repliesCount }}
        {{ changeWord(comment.repliesCount) }}
      </div>
    </div>
    <div v-if="isRepliesOpened" class="replies-wrap">
      <SocialCommentsListItem
          v-for="reply in comment.replies" :key="reply.id"
          :comment="reply"
          :postId="postId"
          :isFetchReplies="isFetchReplies"
          :commentId="comment.id"
      />
    </div>
    <SocialCommentInput
        v-if="isShowInput"
        :postId="postId"
        :replyTo="comment"
        @close="isShowInput = false"
        :isFetchReplies="isFetchReplies"
        :commentId="comment.id"
    />
    <SocialCommentInput
        v-if="isShowEditInput"
        :postId="postId"
        :editedComment="comment"
        @close="isShowEditInput = false"
        :isFetchReplies="isFetchReplies"
        :commentId="commentId"
    />
  </div>
</template>

<script>
import { formatDistanceToNow } from '@/utils/date'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import SocialCommentInput from './CommentInput.vue'
import MenuBase from '@/components/atoms/MenuBase.vue'

export default {
  name: 'social-comments-list-item',
  components: {
    SocialCommentInput,
    MenuBase
  },
  props: {
    comment: Object,
    postId: Number,
    commentId: Number
  },
  data () {
    return {
      isShowActionMenu: false,
      isRepliesOpened: false,
      isShowInput: false,
      isShowEditInput: false,
      isFetchReplies: true,
      menuMy: [
        {
          name: this.$t('buttonEdit'),
          icon: 'edit',
          handler: () => {
            this.isShowEditInput = true
          }
        },
        {
          name: this.$t('buttonDelete'),
          icon: 'delete',
          handler: () => {
            const text = this.comment.text.length < 20 ? this.comment.text : this.comment.text.slice(0, 20) + '...'
            this.$modal.show('confirm', {
              content: this.$t('socialComment.deleteConfirmation', { text }),
              onSubmit: this.onDelete
            })
          }
        }
      ],
      menuFollower: [
        {
          name: this.$t('socialPost.reportButton'),
          handler: () => {
            this.$modal.show('confirm', {
              content: this.$t('socialPost.commentReportConfirmation'),
              onSubmit: () => {
                this.$modal.show('info', { text: this.$t('socialPost.reportText') })
              }
            })
          }
        }
      ]
    }
  },
  created () {
    this.isShowActionMenu = this.comment.isOwn
  },
  computed: {
    ...mapGetters({
      fetchRepliesLoading: 'profile/fetchReplyLoading'
    }),
    name () {
      if (!this.user) return
      return this.user.displayName
    },
    time () {
      return formatDistanceToNow(new Date(this.comment.createdAt))
    },
    menu () {
      return this.comment.isOwn ? this.menuMy : null
    }
  },
  methods: {
    ...mapActions({
      fetchReplies: 'profile/fetchReplies',
      deleteComment: 'profile/deleteComment',
      fetchPostComments: 'profile/fetchPostComments'
    }),
    ...mapMutations({
      setReplyData: 'profile/setReplyData'
    }),
    onDelete () {
      this.deleteComment({ postId: this.postId, commentId: this.commentId ? this.commentId : this.comment.id, replyId: this.comment.id, isReplyComment: this.comment.isReplyComment })
    },
    toggleReplies () {
      this.isRepliesOpened = !this.isRepliesOpened
      if (this.isRepliesOpened) {
        this.onFetchReplies()
      }
    },
    onFetchReplies () {
      this.fetchReplies({ postId: this.postId, commentId: this.comment.id })
    },
    // showProfile(user) {
    //   this.$modal.show('profile', {
    //     userId: this.userId
    //   })
    // },
    changeWord (count) {
      const titles = [this.$t('socialPost.reply'), this.$t('socialPost.replies'), this.$t('socialPost.replies')]
      const cases = [2, 0, 1, 1, 1, 2]
      return titles[(count % 100 > 4 && count % 100 < 20) ? 2 : cases[(count % 10 < 5) ? count % 10 : 5]]
    }
  }
}
</script>

<style lang="scss">
.comment{
  margin: 5px 0;
  &-top{
    display: inline;
    overflow-wrap: break-word;
    span{
      font-size: 16px;
      line-height: 1.2;
      @include respondTo(600px){
        font-size: 14px;
      }
    }
  }
  &-user{
    font-weight: 500;
    color: #00b764;
    cursor: pointer;
  }
  &-text{
    margin-left: 4px;
    color: #3b3b3b;
    a{
      color: #7abbc7;
      text-decoration-color: transparent;
      transition: text-decoration $trans, color $trans;
      &:hover{
        color: #00b764;
        text-decoration-color: #00b764;
      }
    }
  }
  &-info {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    color: #3b3b3b;
    font-size: 14px;
    margin-top: 5px;
    @include respondTo(500px){
      font-size: 12px;
    }
  }
  &-time{
    user-select: none;
  }
  .v-popover{
    margin-right: 12px;
  }
  .comment {
    padding-left: 25px;
  }
}
.action-arrow{
  cursor: pointer;
}
.reply-btn {
  margin-left: 12px;
  cursor: pointer;
  @include respondTo(500px) {
    margin-left: 9px;
  }
}
.replies-wrap{
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: hidden;
}
</style>
