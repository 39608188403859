<template>
  <div v-if="user"
       :class="$style.container"
       :style="containerStyle">
    <div :class="$style.circle"
         :style="wrapperStyle">
      <img
        alt="user avatar"
        v-if="avatarLink"
        :src="avatarLink"
        :class="$style.image"
      >
      <template v-else>{{ placeholder }}</template>
    </div>
    <div v-if="user.isOnline && isShowOnline"
         :class="[$style.online, { [$style.small]: size < 50 }]">
    </div>
  </div>
</template>

<script>
import { getUserDisplayName, getColorById } from '@/utils/helpers'

export default {
  name: 'user-avatar',
  props: {
    user: {
      type: Object
    },
    size: {
      type: Number,
      default: 56
    },
    isShowOnline: {
      type: Boolean
    }
  },
  computed: {
    containerStyle () {
      return {
        width: `${this.size}px`,
        height: `${this.size}px`
      }
    },
    wrapperStyle () {
      const style = {
        width: `${this.size}px`,
        height: `${this.size}px`,
        lineHeight: `${this.size}px`,
        fontSize: `${Math.floor(this.size * 0.5)}px`
      }
      if (this.colors) {
        style.background = `linear-gradient(to right, ${this.colors[0]}, ${this.colors[1]})`
      }
      return style
    },
    colors () {
      if (!this.user) return
      return getColorById(this.user.userId || this.user.id)
    },
    placeholder () {
      const displayName = getUserDisplayName(this.user)
      if (displayName === this.user.phone) return
      return displayName.split(' ').map(n => n[0]).join('')
    },
    avatarLink () {
      return this.user.avatarLink
    }
  }
}
</script>

<style module lang="scss">
.container {
  position: relative;
}
.circle {
  border-radius: 100%;
  background-color: $main-bg-colored;
  color: $text-lightest;
  overflow: hidden;
  text-align: center;
}
.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.online {
  border-radius: 50%;
  border: 1px solid $text-lightest;
  background-color: $text-colored;
  width: 10px;
  height: 10px;
  position: absolute;
  left: -3px;
  top: calc(50% - 5px);
  &.small {
    width: 8px;
    height: 8px;
    left: -2px;
    top: calc(50% - 4px);
  }
}
</style>
