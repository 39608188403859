export default {
  termOfService: '利用規約',
  privacyPolicy: '個人情報保護方針',
  xCommunications: 'xコミュニケーション',
  xCommunicationsInvestorRelations: '投資家向け広報活動',
  xCommunicationsMediaRequest: 'メディアリクエスト',
  xCommunicationsOurPositionPrivacy: 'プライバシーに関する私たちの立場',
  xCommunicationsKnowledgeBase: '知識ベース',
  xCommunicationsOurPosition: 'ネットいじめに関する私たちの立場',
  premiumTools: 'プレミアムツール',
  premiumToolsSayHolaLiveChatPlugin: 'ライブチャットプラグイン',
  premiumToolsSayHolaBlogPlugin: 'sayHolaブログプラグイン',
  premiumToolsTogetherSayHola: 'sayHolaで一緒に',
  premiumToolsLiveLeagueApp: 'ライブリーグアプリ',
  programs: 'プログラム',
  programsAdvertiseHola: 'Holaで宣伝する',
  programsFreelancerProgram: 'フリーランサープログラム',
  programsAffiliateRewardsProgram: 'アフィリエイトリワードプログラム',
  terms: '条項',
  termsAdsPolicy: '広告ポリシー',
  termsPrivacyPolicy: '個人情報保護方針',
  termsCommunityStandards: 'コミュニティ基準',
  termsTermsUse: '利用規約',
  termsLoyaltyRewardsTerms: '報酬ポリシー',
  otp_unconfirmed: 'Otp未確認、再試行',
  pin_unconfirmed: '間違ったPINコード',
  otpDescription: 'SMSコードが送信されました。以下にコードを入力してください。',
  otpEnterYourOtp: 'OTPコードを入力してください',
  otpEnterYourPin: 'PINコードを入力してください',
  verify_otp: '確認',
  otpDontReceiveCode: 'コードを受け取りませんでしたか？',
  otpRequestSMSAgain: 'もう一度SMSをリクエストする',
  otpRequestNewCode: 'で新しいコードをリクエストする',
  copyright: 'xCommunications。全著作権所有',
  enterPhoneNumber: '国を選択し、完全な電話番号を入力してください。',
  login: 'ログイン',
  validationSelectFile: '写真を選択してアップロードする',
  validationSameAs: 'パスワードが一致していません',
  validationMaxLengthField: 'フィールド文字の最大数 :field - :count',
  validationMinLengthField: 'フィールド文字の最小数 :field - :count',
  validationRequired: 'このフィールドに入力します',
  validationEmail: '有効なメールアドレスを入力してください',
  validationUrl: '有効なURLを入力してください',
  needChangePhone: '番号を変更する必要がありますか？',
  recovery: '回復',
  needMoreInfo: '詳細情報が必要ですか？',
  learnMore: 'もっと詳しく知る',
  startUsingHola: 'sayHolaを使用して<br>ソーシャルスペースを表示します',
  startUsingHolaFollow: 'フォローするためにsayHolaの使用を開始します',
  socialSpaceWord: 'ソーシャルスペース',
  socialSpaceInvite: 'ソーシャルスペースへの招待',
  socialSpaceInviteMessage: 'コメントやフェイクニュースのない、ビジネス、有名人、ブロガー、インフルエンサー、コミュニティ、個人のための安全で安全なプライベートスペース',
  enterPhone: '電話を入力してください',
  footerPrivateAppJobsPage: '仕事の機会',
  socialPost: {
    image: '画像',
    isEnabledComments: 'コメント',
    isEnabledLikes: 'いいねボタン',
    newPostTitle: '新しい投稿',
    placeholder: 'ここからメッセージとハッシュタグを始めましょう',
    editPostTitle: '投稿を編集',
    deleteButton: '投稿を削除する',
    editButton: '投稿を編集',
    reportButton: '報告',
    reportConfirmation: 'この投稿を報告してもよろしいですか？',
    reportText: '私たちに知らせていただきありがとうございます。あなたのフィードバックは、コミュニティの使用慣行に役立ちます。あなたが取ることができる他のステップは、ユーザーをブロックすることです。これを行うと、お互いを確認したり連絡したりすることができなくなります。',
    likes: '好き',
    hideComments: 'コメントを隠す',
    viewLatestComments: '最新のコメントを見る',
    reply: '返事',
    replies: '返信',
    placeholderComment: 'コメントを書く',
    placeholderReply: 'に返信 {name}',
    commentReportConfirmation: 'このコメントを報告してもよろしいですか？'
  },
  btnHide: '隠れる',
  btnShow: '見せる',
  btnReply: '返事',
  buttonEdit: '編集',
  socialComment: {
    deleteConfirmation: 'コメントを削除してもよろしいですか <b>{text}</b>?'
  },
  buttonLoadMore: 'もっと読み込む',
  socialSpace: {
    searchTitle: 'ホラを検索',
    accounts: 'アカウント',
    places: '場所',
    tags: 'タグ'
  },
  searchNoResults: '結果がありません',
  startTypingForSearch: '検索のために入力を開始します',
  userStatus: {
    btnAdd: 'ステータスを追加',
    tabSetStatus: 'ステータスの設定',
    tabCreateStatus: 'ステータスの作成',
    titlePlaceholder: 'ステータスタイトル',
    textPlaceholder: 'ここからステータスを開始します（最大250文字）',
    editStatusTitle: 'ステータスの編集'
  },
  buttonCancel: 'キャンセル',
  buttonDelete: '消去',
  blogSubscribeBtn: '申し込む',
  blogUnsubscribeBtn: '登録を解除する',
  popupConfirmTitle: '確認',
  popupConfirmText: '本気ですか？',
  buttonAdd: '追加',
  buttonConfirm: '確認',
  socialProfile: {
    categoryCompany: '会社',
    categoryIndividual: '個人',
    extendedSettings: '拡張設定',
    firstName: 'ファーストネーム',
    lastName: '苗字',
    nickname: 'ニックネーム',
    isActive: 'ソーシャルプロファイル（ライブ）',
    profileViewOptions: '縦断ビューオプション',
    isPublic: '公開（検索可能）',
    isChatActive: '私にメッセージを送ってください（sayHola）',
    showFollowers: 'フォロワー数',
    showSocialSpace: 'ソーシャルスペースを表示する',
    textLocation: '場所（都市、国）',
    pictureError: 'プロフィール写真をアップロードしてください',
    errorNicknameExists: 'このニックネームのプロファイルはすでに存在します',
    editTitle: 'プレミアムプロファイルを編集する',
    btnCancelRequest: 'リクエストのキャンセル',
    btnFollow: '従う',
    btnUnfollow: 'フォロー解除',
    followers: 'フォロワー',
    pending: '保留中',
    createTitle: '新しいプロファイルを作成する',
    successCreateProfile: 'プロファイルが正常に作成されました。',
    successEditProfile: 'プロフィール情報更新！',
    successDeleteProfile: 'プロファイルが正常に削除されました！',
    showCountPosts: '投稿数',
    showCountFollowing: '次のカウント',
    socialProfile: 'ソーシャルプロファイル'
  },
  authLogout: 'ログアウト',
  logout: {
    confirmation: 'ログアウトしてもよろしいですか？',
    allSessions: 'すべてのセッションからログアウトします',
    title: 'ログアウト'
  },
  buttonCreate: '作成',
  buttonRegistration: '登録を完了する',
  buttonEnter: 'ホラに入る',
  buttonPay: '支払い',
  buttonUpgrade: 'アップグレード',
  buttonCopy: 'コピー',
  buttonSave: '保存する',
  buttonSend: '送信',
  successTitle: '正常に',
  errorTitle: 'エラー',
  error: 'すみません、問題が発生しました',
  errorEmailInvalid: '有効なメールアドレスを入力してください',
  errorPhoneInvalid: '無効な電話番号',
  errorEmptyField: 'この項目は必須です',
  errorAlphanumericDashUnderscore: 'このフィールドには、英数字、ダッシュ、アンダースコアのみを含めることができます[a-z0-9-_]',
  category: 'カテゴリー',
  chooseCategory: 'カテゴリを選択',
  chooseCategoryError: 'カテゴリを選んでください！',
  errorMinLength_begin: 'このフィールドに少なくとも',
  errorMaxLength_begin: 'このフィールドに以下が含まれていることを確認してください',
  errorLength_end: '文字',
  formAttach: '添付',
  successCreatePost: '投稿は正常に作成されました',
  successEditPost: '投稿が正常に編集されました',
  post: {
    author: '著者',
    createTitle: '新しい投稿を作成する',
    footerImages: 'フッター画像',
    headerImage: 'ヘッダー画像',
    isPublished: '公開済み',
    subtitle: '字幕',
    text: '文章',
    textPlaceholder: 'ここからブログを始めましょう。最初の文字は太字で大きい',
    title: '題名',
    delete: '投稿を削除する',
    edit: '投稿を編集',
    footerVideos: 'フッタービデオ',
    tabPhotos: '写真',
    tabVideos: 'ビデオ'
  },
  deleteConfirmation: '消去してもよろしいですか <b>{name}</b>?',
  postNotFound: '投稿が見つかりません！',
  createProfile: 'プロフィール作成',
  removeProfile: 'プロファイルを削除する',
  editProfile: 'プロファイル編集',
  createPost: '投稿を作成する',
  create: '作成',
  dontHaveProfile: 'あなたは社会的プロフィールを持っていません。',
  chooseProfile: 'ソーシャルプロファイルを選択する',
  needSubscribeMessage: 'プロファイルを表示するには、サブスクライブする必要があります。',
  profilePost: '役職',
  profileFollowing: '続く',
  profileFollowers: 'フォロワー',
  emptyPostList: '投稿リストは空です',
  navigationHome: '家',
  navigationLiked: '好き',
  navigationTrending: 'トレンド',
  navigationFavourites: 'お気に入り',
  navigationFollowers: 'フォロワー',
  postSettings: '投稿設定',
  noResultSearch: '結果が見つかりません',
  profiles: 'プロファイル',
  location: '位置',
  tags: 'タグ',
  showMore: 'もっと見せる',
  remove: '削除する',
  uploadPhoto: '写真をアップする',
  dragAndDrop: 'または単にドラッグアンドドロップ',
  emptyList: 'リストは空です！',
  open: '開ける',
  advertiseUsingCampaigns: 'キャンペーンを使用してここに広告を掲載する',
  postButton: '役職',
  settingsButton: '設定',
  viewButton: '意見',
  deleteButton: '消去',
  posts: '投稿',
  profileSettings: 'プロファイル設定',
  backgroundPicture: '背景画像',
  profilePicture: 'プロフィールの写真',
  fillUserInfo: '情報を入力してください：',
  requiredFields: '必須フィールドを示します。',
  formFirstName: 'ファーストネーム',
  formLastName: '苗字',
  formNickName: 'ユーザー名',
  profileEmailInfo: 'あなたの電子メールは回復のためだけに使われます。メールアドレスを入力すると、sayHolaのプライバシーポリシーと利用規約に同意したことになります。リンクは回復のためにあなたの電子メールに送られます。',
  formEmail: 'あなたのメール',
  error_email_exists: 'このメールのユーザーはすでに存在します',
  backToHomePage: 'ホームページに戻ります',
  pageNotFoundMessage: 'お探しのページが見つかりません',
  resendOtpMessage: 'もう1つのSMSが送信されました。',
  buttonDone: '終わり',
  viewProfile: 'プロフィールを見る',
  copyClipboardSuccess: 'クリップボードへのコピーの成功',
  copyClipboardError: 'クリップボードへのコピーエラー',
  loginByQRCodeMessage: 'スマートフォン経由でログインするためのQRコード',
  authOption: 'ログインするオプションを選択してください。',
  newRegistration: '新規登録',
  registrationOption: '登録には、サービスを使用するための電子メールとユーザー名が必要です。ログインすると、電話番号を使用して他のメッセンジャーアプリと同期することができます',
  loginWithApp: 'アプリでログイン',
  loginWithAppOption: '携帯電話でHolaを開き、アプリの右上隅にあるメニューに移動します。 QRコードアイコンをクリックします。この画面に携帯電話を向けて、ログインを確認します。',
  recoveryOption: '回復',
  recoveryOptionText: '回復のためにホットリンクを使用してログインするために登録された電子メールを入力してください。 FAQセクションにアクセスして詳細をご覧ください',
  enterEmailRecovery: '登録したメールアドレスを入力して復旧します。',
  notFoundUserLabel: 'このメールアドレスのユーザーが見つかりません',
  successSendRecoveryEmailMessage: 'あなたの電子メールをチェックして、指示に従ってください！',
  chatBots: 'チャットボット',
  BdtlAgencyProgram: 'BDTLエージェンシープログラム',
  agencyTerms: '代理店規約',
  holaExtensions: 'はいはい拡張',
  holaBlog: 'はいはいブログ',
  browserExtensions: 'ブラウザ拡張機能',
  agencyTermsNew: '代理店ポリシー',
  earnPrograms: '獲得プログラム',
  rewardsProgram: '報酬プログラム',
  despaxoTerms: 'デスパクソ規約',
  faqWord: 'よくある質問',
  paidServices: '基本サービスと有料サービス',
  multiapp: 'マルチアプリ同期',
  opportunities: '機会',
  despaxoDrivers: 'デスパクソドライバー',
  generalTerms: '一般条件',
  apps: 'アプリケーション',
  web: 'ウェブ',
  desktop: 'デスクトップ',
  blogSyncPlugin: 'ブログ同期プラグイン',
  together: '一緒',
  chatbotConstructor: 'チャットボットコンストラクター',
  enterpriseProgram: 'エンタープライズ プログラム',
  bdtlProgram: 'BDTL プログラム',
  despaxoBot: 'Despaxo ボット同期プログラム',
  extendetTerms: '延長条件',
  despaxoPolicy: 'デスパクソの方針',
  despaxoDriverPolicy: 'Despaxo ドライバー ポリシー',
  driverPolicy: 'ドライバー ポリシー',
  administrativeStatement: '管理声明',
  despaxoDefinitions: 'デスパクソの定義',
  operatingDespaxo: 'Despaxo の操作'
}
