<template>
  <div class="select-container">
    <template v-if="isLabelArray">
      <div v-for="(l, i) in label"
           :key="i"
           class="form--label">
        {{ l }}
      </div>
    </template>
    <div v-else-if="label" class="form--label">{{ label }}</div>
    <Multiselect
      v-bind="{
        value,
        placeholder,
        options,
        trackBy,
        label: optionLabel,
        showLabels,
        disabled,
        internalSearch
      }"
      v-on="$listeners">
      <slot v-for="(_, name) in $slots"
            :name="name"
            :slot="name" />
      <template v-for="(_, name) in $scopedSlots"
                :slot="name"
                slot-scope="slotData">
        <slot :name="name"
              v-bind="slotData" />
      </template>
    </Multiselect>
    <div
      v-show="!invalid && helpText"
      class="form--help-text">
      {{ helpText }}
    </div>
    <div class="form--error-msg"
         v-show="invalid">
      <span>
        {{ error }}
      </span>
    </div>
    <p class="form--error"
       v-if="showError">
      {{ $t('chooseCategoryError') }}
    </p>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
  name: 'base-select',
  components: {
    Multiselect
  },
  props: {
    value: {
      type: [String, Object]
    },
    placeholder: String,
    invalid: {
      type: Boolean,
      default: false
    },
    error: {
      type: String
    },
    disabled: Boolean,
    label: {
      type: [String, Array]
    },
    helpText: String,
    options: Array,
    trackBy: {
      type: String,
      default: 'value'
    },
    optionLabel: {
      type: String,
      default: 'label'
    },
    showLabels: {
      type: Boolean,
      default: false
    },
    internalSearch: {
      type: Boolean,
      default: true
    },
    showError: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isLabelArray () {
      return Array.isArray(this.label)
    }
  }
}
</script>

<style lang="scss">
.invalid .multiselect__tags {
  border-bottom-color: $text-error;
}
.select-container{
  padding: 25px 0;
}
p.form--error{
  margin-top: 5px;
  font-size: 12px;
  color: #B83F39;
}
</style>
