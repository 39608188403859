<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import SocialCreatePost from './CreatePost.vue'

export default {
  name: 'social-edit-post',
  extends: SocialCreatePost,
  props: {
    postId: Number
  },
  async created () {
    if (!this.post) {
      await this.fetchPost({ profileNickname: this.nickname, postId: this.postId })
    }
    if (!this.post) {
      this.$router.push({ name: 'start', params: { nickname: this.nickname } })
      return
    }
    this.text = this.post.text
    this.imageSrc = this.post.image
    this.isEnabledLikes = this.post.isEnabledLikes
    this.isEnabledComments = this.post.isEnabledComments
  },
  computed: {
    ...mapGetters({
      currentPost: 'user/currentPost'
    }),
    post () {
      return this.currentPost
    },
    title () {
      return this.$t('socialPost.editPostTitle')
    },
    buttonText () {
      return this.$t('buttonConfirm')
    }
  },
  methods: {
    ...mapActions({
      fetchPost: 'user/fetchPost',
      updatePost: 'user/updatePost'
    }),
    ...mapMutations({
      clearPost: 'user/clearPost'
    }),
    async onSubmit () {
      this.$v.$touch()
      if (this.loading || this.$v.$invalid) return
      const data = {
        text: this.text,
        isEnabledLikes: this.isEnabledLikes,
        isEnabledComments: this.isEnabledComments
      }
      if (this.imageSrc !== this.post.image && this.imageFile != null) {
        data.image = this.imageFile
      }
      this.loading = true
      try {
        await this.updatePost({
          profileNickname: this.nickname,
          postId: this.postId,
          data
        })
        this.$toasted.success(this.$t('successEditPost'))
        this.$router.push({ name: 'start', params: { nickname: this.nickname } })
      } catch (e) {
        this.$toasted.error(e.response.data)
      } finally {
        this.loading = false
      }
    }
  },
  destroyed () {
    this.clearPost()
  }
}
</script>

<style lang="scss" scoped>
.create-post{
  margin: 40px auto;
  padding: 0 25px;
  max-width: 800px;
  .button {
    margin: 14px auto;
  }
  &__title{
    font-size: 18px;
    font-weight: 500;
    color: $text-success;
    margin: 0;
    margin-bottom: 16px;
    text-align: center;
  }
  &__image{
    margin: 20px 0;
    padding: 30px 15px;
    background: #eceff0;
    & > label.form--label{
      margin-bottom: 20px;
      font-size: 18px;
      font-weight: 500;
      color: #00b764;
      text-align: center;

    }
    .form--label{
      display: block;
      color: #3b3b3b;
      font-size: 14px;
    }
    .file-input{
      display: block;
      margin-top: 10px;
      svg{
        margin-right: 5px;
      }
    }
    .gl-media-preview{
      margin: 0 auto;
    }
  }
  &__options{
    margin: 30px 0;
    padding: 30px 15px;
    background: #eceff0;
    &-title{
      font-size: 18px;
      font-weight: 500;
      color: #00b764;
      margin: 0;
      margin-bottom: 16px;
      text-align: center;
    }
    label {
      cursor: pointer;
    }
    label + label{
      margin-top: 15px;
    }
  }
  &__action{
    button{
      margin: 0 auto;
    }
  }
}
</style>
