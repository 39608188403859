import auth from './auth'
import user from './user'
import profile from './profile'
import location from './location'
import search from './search'
import likes from './likes'
import favourites from './favourites'
import trending from './trending'

export default {
  auth,
  user,
  profile,
  location,
  search,
  likes,
  favourites,
  trending
}
