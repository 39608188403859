<script>
import { required, maxLength, helpers } from 'vuelidate/lib/validators'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import api from '@/api'
import SocialCreateProfile from './CreateProfile.vue'
import { debounce } from 'lodash'

const alphaNumHyphenUnderscoreValidator = helpers.regex('alphaNumAndDot', /^[-a-zA-Z0-9_]+$/i)

const NAMES_MAX_LENGTH = 20
const NICKNAME_MAX_LENGTH = 20
const LOCATION_MAX_LENGTH = 50

export default {
  name: 'social-edit-profile',
  extends: SocialCreateProfile,
  async created () {
    await this.fetchMyProfile(this.profileNickname)

    if (!this.profile) {
      this.$router.replace({ name: 'home' })
      return
    }
    this.pictureSrc = this.profile.picture
    if (this.profile.background) {
      this.backgroundSrc = this.profile.background
    }
    this.firstName = this.profile.firstName
    this.lastName = this.profile.lastName
    this.nickname = this.profile.nickname
    this.textLocation = this.profile.textLocation
    this.category = this.categories.find(catObj => catObj.value === this.profile.category) || this.categories[0]
    this.isActive = this.profile.isActive
    this.isPublic = this.profile.isPublic
    this.showFollowers = this.profile.showFollowers
    this.isChatActive = this.profile.isChatActive
    this.showCountPosts = this.profile.showCountPosts
    this.showCountFollowing = this.profile.showCountFollowing
  },
  computed: {
    ...mapGetters({
      profile: 'user/editedProfile',
      editProfileLoading: 'profile/editProfileLoading'
    }),
    profileNickname () {
      return this.$route.params.nickname
    },
    title () {
      return this.$t('socialProfile.editTitle')
    },
    buttonText () {
      return this.$t('buttonConfirm')
    }
  },
  validations: {
    firstName: { required, maxLength: maxLength(NAMES_MAX_LENGTH) },
    lastName: { maxLength: maxLength(NAMES_MAX_LENGTH) },
    nickname: {
      required,
      maxLength: maxLength(NICKNAME_MAX_LENGTH),
      alphaNumHyphenUnderscoreValidator,
      uniq () {
        return !this.errorUniqNickname
      }
    },
    textLocation: { required, maxLength: maxLength(LOCATION_MAX_LENGTH) },
    pictureFile: {}
  },
  methods: {
    ...mapMutations({
      clearProfilesData: 'user/clearProfilesData'
    }),
    ...mapActions({
      fetchMyProfile: 'user/getEditedProfile',
      updateProfile: 'profile/updateProfile',
      fetchMyProfiles: 'user/fetchMyProfiles'
    }),
    setCategory () {
      this.isShowSelectError = !this.category
    },
    async onSubmit () {
      this.$v.$touch()
      if (this.loading || this.$v.$invalid || this.isShowSelectError) return
      const data = {
        firstName: this.firstName,
        lastName: this.lastName,
        nickname: this.nickname,
        textLocation: this.textLocation,
        category: this.category.value,
        isActive: this.isActive,
        isPublic: this.isPublic,
        showFollowers: this.showFollowers,
        isChatActive: this.isChatActive,
        showCountPosts: this.showCountPosts,
        showCountFollowing: this.showCountFollowing
      }
      if (this.textLocation !== this.profile.textLocation) {
        const location = await api.location.coordinatesByAddress(this.textLocation)
        data.location = JSON.stringify(location)
      }
      if (this.pictureFile) {
        data.picture = this.pictureFile
      }
      if (this.backgroundFile) {
        data.background = this.backgroundFile
      }
      this.loading = true
      try {
        await this.updateProfile({ nickname: this.profileNickname, data }).then(() => {
          this.$toasted.success(this.$t('socialProfile.successEditProfile'))
          this.$router.push({ name: 'start', params: { nickname: this.nickname } })
          this.fetchMyProfiles({ limit: 20, offset: 0 })
        })
      } catch (e) {
        const { nickname } = e.data
        if (nickname) {
          this.$toasted.error(this.$t('socialProfile.errorNicknameExists'))
        }
      } finally {
        this.loading = false
      }
    },
    changeInputUsername () {
      this.nickname = this.nickname.toLowerCase()
      if (this.nickname.length) {
        this.checkNicknameLoading = true
        this.changeValidationUserName()
      }
    },
    changeValidationUserName: debounce(function () {
      this.checkUsername(this.nickname).then((response) => {
        this.errorUniqNickname = response.info !== 'Nickname is unique'
      }).finally(() => {
        this.checkNicknameLoading = false
        this.$v.nickname.$touch()
      })
    }, 500)
  }
}
</script>
<style lang="scss" scoped>
.container {
  margin: 40px auto;
  padding: 0 25px;
  max-width: 800px;

  .button {
    margin: 0 auto;
  }
}
.images{
  width: 100%;
  margin-bottom: 30px;
  label{
    display: block;
    cursor: pointer;
  }
  div {
    text-align: center;
    margin: 0 auto;
  }
  p{
    font-size: 16px;
    color: $black;
    margin-bottom: 10px;
  }
}
.profile-setting,
.profile-extended{
  padding: 30px 15px;
  background: #eceff0;
  &__title{
    font-size: 18px;
    font-weight: 500;
    color: #00b764;
    margin: 0;
    margin-bottom: 16px;
    text-align: center;
  }
  &__row{
    display: flex;
    flex-direction: row;
    width: 100%;
    @include respondTo(600px){
      flex-direction: column;
    }
    div:first-child{
      padding-right: 15px;
      @include respondTo(600px){
        padding-right: 0
      }
    }
    div:last-child{
      padding-left: 15px;
      @include respondTo(600px){
        padding-left: 0
      }
    }
    div.select-container{
      width: 100%;
      padding-left: 0;
      padding-right: 0;
    }
  }
  &__col{
    width: 50%;
    @include respondTo(600px){
      width: 100%;
    }
  }
}
.profile-extended{
  margin: 30px 0 0;
}
.form--action{
  margin: 30px auto 0;
}
</style>
