<template>
  <SecondaryModal
    :name="$options.name"
    class="share-popup"
    @before-open="beforeOpen">
    <div slot="default"
         class="share">
      <template>
        <div class="share-header">
          <CopyButton class="share-copy"
                      :link="value">
            {{ $t('buttonCopy') }}
          </CopyButton>
          <p class="share-link">
            {{ value }}
          </p>
        </div>
      </template>
    </div>
  </SecondaryModal>
</template>

<script>
import CopyButton from '@/components/atoms/CopyButton'
import SecondaryModal from './SecondaryModal'

export default {
  name: 'share',
  components: {
    CopyButton,
    SecondaryModal
  },
  data () {
    return {
      value: ''
    }
  },
  methods: {
    async beforeOpen ({ params: { value = '' } }) {
      this.value = value
    }
  }
}
</script>
<style lang="scss">
.share-popup {
  .vm--modal{
    @include respondTo(500px) {
      max-width: 95%;
      margin: 0 auto;
      padding: 15px 0;
      left: auto !important;
    }
  }
}
</style>
<style lang="scss" scoped>
.share {
  padding-bottom: 15px;
  &-header{
    padding: 10px 15px;
  }
  &-link {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    @include respondTo(600px){
      font-size: 14px;
    }
  }
}
</style>
