<template>
  <div id="app" class="app" v-if="show">
    <app-banner />
    <div class="app-container">
      <div class="wrapper">
        <div class="wrapper-top">
          <main-header />
          <div class="wrapper-content">
            <transition name="component-fade" mode="out-in">
              <router-view />
            </transition>
          </div>
        </div>
        <div class="wrapper-bottom">
          <main-footer />
        </div>
      </div>
    </div>
    <MobileNavigation v-if="isMobileVersion && isAuthenticated" />
<!--    // modals-->
    <Confirm />
    <Logout />
    <Cropper />
    <UserInfo />
    <DeleteSocialPost />
    <Share />
    <LoginByCode />
    <Registration />
    <Recovery />
  </div>
</template>

<!--// global styles-->
<style src="@/assets/scss/main.scss" lang="scss"></style>

<script>
import MainHeader from '@/components/features/MainHeader.vue'
import MainFooter from '@/components/features/MainFooter.vue'
import AppBanner from '@/components/features/AppBanner.vue'
import Confirm from '@/components/modals/Confirm.vue'
import Logout from '@/components/modals/Logout.vue'
import UserInfo from '@/components/modals/UserInfo.vue'
import Share from '@/components/modals/Share.vue'
import LoginByCode from '@/components/modals/LoginByCode.vue'
import Registration from './components/modals/Registration.vue'
import Recovery from './components/modals/Recovery.vue'
import Cropper from '@/components/modals/Cropper.vue'
import DeleteSocialPost from '@/components/modals/DeleteSocialPost.vue'
import MobileNavigation from '@/components/features/MobileNavigation.vue'
import { mapGetters, mapMutations, mapActions } from 'vuex'

export default {
  name: 'App',
  data () {
    return {
      show: false,
      isMobileVersion: false
    }
  },
  components: {
    MainHeader,
    MainFooter,
    AppBanner,
    Confirm,
    Logout,
    Cropper,
    DeleteSocialPost,
    UserInfo,
    MobileNavigation,
    Share,
    LoginByCode,
    Registration,
    Recovery
  },
  watch: {
    error: {
      handler (item) {
        if (item.show) {
          this.$toasted.error(item.message)
        }
      },
      deep: true
    },
    isAuthenticated: {
      immediate: true,
      handler (newVal) {
        if (newVal) {
          this.getSocialInfo()
        } else {
          this.show = true
          this.$router.push({ name: 'start', params: { nickname: this.$route.params.nickname } })
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      error: 'system/error',
      isAuthenticated: 'auth/isAuthenticated',
      myProfile: 'user/myProfile'
    })
  },
  mounted () {
    this.$nextTick(() => {
      this.onResize()
      window.addEventListener('resize', this.onResize, { passive: true })
    })
  },
  methods: {
    ...mapActions({
      fetchMyProfile: 'user/fetchMyProfile',
      fetchMyProfiles: 'user/fetchMyProfiles',
      logout: 'auth/logoutLocal'
    }),
    ...mapMutations({
      changeAuthStatus: 'auth/changeAuthStatus'
    }),
    onResize () {
      this.isMobileVersion = window.innerWidth < 600
    },
    getSocialInfo () {
      this.fetchMyProfile().then(() => {
        this.show = true
        if ((this.myProfile && !this.myProfile.email) || (this.myProfile && !this.myProfile.nickname)) {
          setTimeout(() => {
            this.$modal.show('user-info')
          }, 0)
        }
        if (this.$route.params.nickname) {
          this.$router.push({ name: 'start', params: { nickname: this.$route.params.nickname } })
        } else {
          if (this.$route.name === 'page-not-found') {
            this.$router.push({ name: 'page-not-found' }).catch(() => {})
            return false
          } else {
            this.$router.push({ name: 'home' })
          }
        }
      }).catch((error) => {
        console.log(error)
        this.$router.push({ name: 'start', params: { nickname: this.$route.params.nickname } })
        this.logout()
        this.changeAuthStatus(false)
      })
    }
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.onResize)
  }
}
</script>
