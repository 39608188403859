<template>
  <div class="get-otp">
    <form action=""
          @submit.prevent="getOtp">
      <div class="get-otp__title">
        {{ $t('enterPhoneNumber') }}
      </div>
      <phone-input :errors="errors"
                   @on-update="updatePhone($event)"
                   @valid="isValidPhone = $event"/>
      <div class="auth-button">
        <base-button
          loader-color="white"
          :progress="isLoading"
          :disabled="!isValidPhone">
          {{ $t('login') }}
        </base-button>
      </div>
    </form>
  </div>
</template>

<script>
import PhoneInput from '@/components/atoms/PhoneInput'
import BaseButton from '@/components/atoms/BaseButton.vue'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'GetOTP',
  data () {
    return {
      errors: null,
      phone: null,
      isValidPhone: false
    }
  },
  components: {
    PhoneInput,
    BaseButton
  },
  computed: {
    ...mapGetters({
      isLoading: 'auth/isLoading'
    })
  },
  methods: {
    ...mapActions({
      getOtpCode: 'auth/getOtp'
    }),
    updatePhone (phone) {
      this.phone = phone
      this.errors = null
    },
    getOtp () {
      this.getOtpCode({ phone: this.phone }).catch(error => {
        console.log(error)
        if (error.data.errorType === 'scheme') {
          this.$toasted.error(this.$t('errorPhoneInvalid'))
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.get-otp{
  background: $white;
  padding: 5px 15px 20px;
  @include respondTo(600px){
    padding: 10px 20px 40px;
  }
  &__title{
    font-size: 18px;
    line-height: 1.3;
    font-weight: 500;
    color: #3b3b3b;
    text-align: center;
    margin-bottom: 40px;
    @include respondTo(600px){
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 20px;
    }
  }
  .auth-button {
    margin-top: 80px;
  }
}
</style>
