<template>
  <div class="tooltip-layout"
       v-on-clickaway="close"
       :class="{ position,open}">
    <div class="tooltip-layout__header">
      <a href="#" @click.prevent="open = !open">
        <slot name="icon"></slot>
      </a>
    </div>
    <transition name="slide-top" mode="out-in">
      <div class="tooltip-layout__wrap"
           :class="{ 'no-arrow': !arrow }"
           :style="{ 'width' : `${maxWidth}px` }"
           v-show="open">
        <slot></slot>
      </div>
    </transition>
  </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway'
import { mapGetters } from 'vuex'

export default {
  name: 'tooltip-layout',
  mixins: [clickaway],
  props: {
    position: {
      type: String,
      default: 'left'
    },
    maxWidth: {
      type: Number,
      default: 200
    },
    status: {
      type: Boolean,
      default: false
    },
    arrow: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      open: false
    }
  },
  computed: {
    ...mapGetters({
      showLangList: 'system/showLangList'
    })
  },
  watch: {
    '$route.path' () {
      if (this.open) {
        this.close()
      }
    },
    showLangList: {
      immediate: true,
      handler () {
        this.close()
      }
    }
  },
  methods: {
    close () {
      this.open = false
    }
  }
}
</script>

<style lang="scss">
.tooltip-layout {
  position: relative;
  a{
    text-decoration: none;
  }
  &.right {
    .tooltip-layout__wrap {
      right: auto;
      left: -12px;
      &::after {
        right: auto;
        left: 15px;
      }
    }
  }
  li {
    cursor: pointer;
    position: relative;
    &:after{
      content: '';
      background: #cecece;
      opacity: 0;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      transition: opacity $trans;
      z-index: -1;
    }
    &:hover:after{
      opacity: 0.4;
    }
    a {
      display: block;
      padding: 10px;
      font-size: 12px;
      color: $black;
      text-decoration: none;
    }
  }
  &__wrap {
    position: absolute;
    top: calc(100% + 15px);
    width: 200px;
    right: -12px;
    background: #fff;
    border-radius: 3px;
    box-shadow: 0 5px 40px rgba(0, 0, 0, 0.15);
    z-index: 5;
    padding: 10px 0;
    min-height: 40px;
    @include respondTo(1200px){
      right: 0;
    }
    @include respondTo(500px) {
      .tooltip-layout__wrap {
        padding-top: 5px;
        padding-bottom: 5px;
      }
    }
    &::after {
      content: '';
      position: absolute;
      top: -20px;
      right: 15px;
      border: 10px solid;
      border-color: transparent transparent #fff transparent;
      filter: drop-shadow(0 -5px 4px rgba(0, 0, 0, .05));
    }
    &.no-arrow:after{
      display: none;
    }
  }
  &__header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    a {
      width: 40px;
      height: 40px;
      border-radius: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 2;
      position: relative;
      svg {
        width: 13px;
        margin: 0;
      }
    }
  }
}
</style>
