import api from '@/api'
import store from '@/store'

const state = {
  likesList: null,
  likesListLoading: false,
  likesListCount: 0,
  likesListNextPage: null
}

const getters = {
  likesList: (state) => state.likesList,
  likesListLoading: (state) => state.likesListLoading,
  likesListCount: (state) => state.likesListCount,
  likesListNextPage: (state) => state.likesListNextPage
}

const actions = {
  async getLikesList ({ commit }, { limit, offset }) {
    try {
      commit('changeLikesListLoading', true)
      const response = await api.likes.getLikesList({ limit, offset })
      store.commit('profile/saveProfilePosts', response)
      commit('setLikesListCount', response.count)
      commit('setLikesListNextPage', response.next)
    } catch (e) {
      throw e.response
    } finally {
      commit('changeLikesListLoading', false)
    }
  }
}

const mutations = {
  setLikesList (state, data) {
    // eslint-disable-next-line no-unused-vars
    let result
    if (data.count) {
      result = data.results.map((item) => {
        item.isOwn = false
        return item
      })
    }
    store.getters['profile/profilePosts'] = data
  },
  changeLikesListLoading (state, status) {
    state.likesListLoading = status
  },
  setLikesListCount (state, count) {
    state.likesListCount = count
  },
  setLikesListNextPage (state, page) {
    state.likesListNextPage = page
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}
