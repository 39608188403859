<template>
  <div class="phone-input">
    <div class="phone-input__label" v-show="!!label">
      {{ label }}
    </div>
    <vue-tel-input
        :inputOptions="{
          placeholder: $t('enterPhone'),
          autocomplete: true,
          type: 'tel'
        }"
        :dropdownOptions="{
          showDialCodeInSelection: true,
          showFlags: true,
          showDialCodeInList: true
        }"
        :ignoredCountries="['sn']"
        @input="changePhoneInput">
    </vue-tel-input>
    <div class="form-errors" v-if="errors && errors.length">
      <span class="form-errors__item" v-for="error in errors" :key="error">
        {{ error }}
      </span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'PhoneInput',
  props: {
    errors: {},
    label: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      phone: ''
    }
  },
  emits: ['on-update', 'valid'],
  methods: {
    changePhoneInput (number, info) {
      if (info) {
        this.$emit('on-update', info.number)
        this.$emit('valid', !!info.valid)
        this.phone = info.number
      }
    }
  }
}
</script>
<style lang="scss">
.phone-input {
  margin-bottom: 20px;
  position: relative;
  &.error {
    .vue-tel-input {
      border-color: $red;
    }
    input {
      border-color: $red;
    }
  }
  &-error {
    margin-top: 5px;
    color: $red;
    display: block;
    font-size: 12px;
    animation: shake 1 1s ease-in-out;
  }
  &__label {
    color: $silver;
    font-weight: 500;
    margin-bottom: 10px;
    font-size: 14px;
    @include respondTo(1950px) {
      font-size: 12px;
    }
  }
  input {
    width: 100%;
    border: 1px solid rgba(17, 17, 17, 0.15);
    height: 42px;
    font-size: 16px;
    line-height: 30px;
    font-weight: 400;
    padding: 0 19px;
    transition: border-color $trans;
    border-radius: 3px;
    &.vti__input {
      font-family: 'Montserrat', sans-serif;
      border: none;
      border-left: 1px solid rgba(17, 17, 17, 0.15);
      border-radius: 0;
      width: calc(100% - 110px);
      @include respondTo(550px) {
        width: calc(100% - 90px);
      }
    }
    &:focus {
      border-color: rgba(17, 17, 17, 0.5);
    }
  }
  .vti__dropdown-list {
    width: calc(100% + 2px);
    box-shadow: 0 5px 40px rgba(0, 0, 0, 0.15);
    border: none;
    max-height: 180px;
    padding-top: 10px;
    padding-bottom: 10px;
    z-index: 11;
    @include respondTo(500px){
      max-height: 150px;
    }
    &::-webkit-scrollbar {
      width: 8px;
      position: absolute;
      background: rgba(17, 17, 17, 0.1);
    }
    &::-webkit-scrollbar-track {
      background: transparent;
      position: absolute;
      z-index: -2
    }
    &::-webkit-scrollbar-thumb {
      background: rgba(17, 17, 17, 0.4);
      border-radius: 5px;
    }
  }
  .vti__dropdown-list.below {
    top: 50px;
  }
  .vue-tel-input {
    position: relative;
    border-radius: 3px;
    border: 1px solid rgba(17, 17, 17, 0.15);
    transition: border-color $trans;
    background: $white;
  }
  .vue-tel-input:focus-within {
    box-shadow: none;
    border-color: rgba(17, 17, 17, 0.5);
  }
  .vti__dropdown {
    width: 120px;
    padding-left: 15px;
    position: initial;
    @include respondTo(550px) {
      width: 90px;
      padding: 5px;
    }
    &.open {
      .vti__dropdown-arrow {
        transform: scale(1) rotate(-180deg);
      }
    }
  }
  .vti__selection .vti__country-code {
    font-size: 16px;
    color: $black;
    font-weight: 400;
  }
  .vti__dropdown-arrow {
    font-size: 0;
    width: 13px;
    height: 8px;
    background-image: url('../../assets/img/svg/arrow-down-ico.svg');
    background-position: center;
    background-repeat: no-repeat;
    transform: scale(1);
    margin-left: 5px;
    transition: transform $trans;
  }
  .vti__dropdown-item {
    height: 50px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    position: relative;
    padding-left: 46px;
    .vti__flag {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 10px;
      &.ua ~ span {
        font-size: 0;
        &::before {
          content: '+38';
          position: relative;
          display: inline-block;
          font-size: 14px;
        }
      }
    }
    &::after {
      content: '';
      position: absolute;
      left: 46px;
      bottom: 0;
      height: 1px;
      right: 0;
      background: rgba(0, 0, 0, 0.07);
      display: block;
    }
    strong {
      font-weight: 400;
      margin-left: 7px;
    }
    .vti__flag {
      margin-right: 13px;
    }
  }
  .vti__flag.ua + .vti__country-code {
    font-size: 0;

    &::before {
      content: '+38';
      position: relative;
      display: inline-block;
      font-size: 16px;
      @include respondTo(550px) {
        font-size: 14px;
      }
    }
  }
}
</style>
