<template>
  <SecondaryModal
    :name="$options.name"
    :title="$t('popupConfirmTitle')"
    @before-open="reset"
  >
    <div v-html="content"></div>

    <template v-slot:footer>
      <FormButton
        oval
        secondary
        @click="$modal.hide($options.name)">
        {{ $t('buttonCancel') }}
      </FormButton>
      <FormButton
        oval
        @click="submit">
        {{ $t('buttonConfirm') }}
      </FormButton>
    </template>
  </SecondaryModal>
</template>

<script>
import SecondaryModal from './SecondaryModal'
import FormButton from '@/components/atoms/_FormButton.vue'

export default {
  name: 'confirm',
  components: {
    SecondaryModal,
    FormButton
  },
  data () {
    return {
      content: this.$t('popupConfirmText'),
      onSubmit: () => {}
    }
  },
  methods: {
    reset ({ params: { content, onSubmit = () => {} } }) {
      this.onSubmit = onSubmit
      this.content = content || this.$t('popupConfirmText')
    },
    submit () {
      this.onSubmit()
      this.$modal.hide(this.$options.name)
    }
  }
}
</script>
