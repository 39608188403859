<template>
  <div class="app-banner"
       v-if="isMobileDevice === 'ios' || isMobileDevice === 'android'">
    <div class="smartbanner">
      <div class="smartbanner-container">
        <a href=""
           @click.prevent="closeAppBanner"
           class="smartbanner-close">
          ×
        </a>
        <span
          class="smartbanner-icon"></span>
        <div class="smartbanner-info">
          <div class="smartbanner-title">Hola</div>
          <div>xGroup Companies</div>
        </div>
        <a :href="appLink"
           class="smartbanner-button"
           target="_blank">
          <span class="smartbanner-button-text">
            {{ $t('open') }}
          </span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { isMobile } from '@/helpers/variables/index.js'

export default {
  name: 'app-banner',
  data () {
    return {
      isMobileDevice: null,
      appLink: ''
    }
  },
  created () {
    if (isMobile.Android()) {
      this.isMobileDevice = 'android'
      this.appLink = 'https://play.google.com/store/apps/details?id=com.xgroup.hola.hola'
    } else if (isMobile.iOS()) {
      this.isMobileDevice = 'ios'
      this.appLink = 'https://apps.apple.com/us/app/sayhola/id1474217306?ls=1'
    }
  },
  methods: {
    closeAppBanner () {
      this.isMobileDevice = null
    }
  }
}
</script>

<style lang="scss">
.smartbanner {
  width: 100%;
  height: 80px;
  line-height: 80px;
  background: #f4f4f4;
  z-index: 9998;
  -webkit-font-smoothing: antialiased;
  overflow: hidden;
  -webkit-text-size-adjust: none;
}
.smartbanner-container {
  margin: 0 auto;
  white-space: nowrap;
}
.smartbanner-close {
  display: inline-block;
  vertical-align: middle;
  margin: 0 5px 0 5px;
  font-size: 20px;
  text-align: center;
  color: #888;
  text-decoration: none;
  border: 0;
  border-radius: 14px;
  -webkit-font-smoothing: subpixel-antialiased;
}
.smartbanner-icon {
  display: inline-block;
  vertical-align: middle;
  width: 57px;
  height: 57px;
  margin-right: 12px;
  background-size: cover;
  border-radius: 10px;
}
.smartbanner-info {
  display: inline-block;
  vertical-align: middle;
  width: 44%;
  font-size: 11px;
  line-height: 1.2em;
  font-weight: bold;
}
.smartbanner-title {
  font-size: 13px;
  line-height: 18px;
}
.smartbanner-button {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  height: 24px;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  font-weight: bold;
  color: #6a6a6a;
  text-transform: uppercase;
  text-decoration: none;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.8);
}
.app-banner {
  position: relative;
  height: 80px;
  .smartbanner {
    background: $white;
    box-shadow: none;
  }
  .smartbanner-title{
    color: $black;
    font-weight: normal;
    text-shadow: none
  }
  .smartbanner-info{
    color: #878787;
    font-weight: normal;
    text-shadow: none
  }
  .smartbanner-icon{
    overflow: hidden;
    border: none;
    background: url('../../assets/img/home/app-img.png') center center no-repeat;
    background-size: contain;
  }
  .smartbanner-close{
    background: $white;
    text-shadow: none;
    box-shadow: none;
    color: $black;
    font-size: 25px;
  }
  .smartbanner-button{
    right: 10px;
    font-size: 18px;
    line-height: 24px;
    box-shadow: none;
    color: #00b764;
    span {
      background: $white;
    }
  }
}
</style>
