const state = {
  globalLoader: false,
  fixBody: false,
  currentLang: null,
  showLangList: '',
  error: {
    id: Date.now(),
    show: false,
    message: ''
  }
}

const getters = {
  fixBody: state => state.fixBody,
  globalLoader: state => state.globalLoader,

  currentLang: state => state.currentLang,
  showLangList: state => state.showLangList,

  error: state => state.error
}

export default {
  state,
  getters
}
