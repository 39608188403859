<template>
  <div class="header-search">
    <div class="header-search__bg"
         v-show="inputValue.length && show"
         @click="setFocusField(false)"></div>
    <div class="header-search__wrap">
      <form class="header-search__form"
            @submit.prevent="changeSearch()">
        <div class="search-type"
             v-on-clickaway="closeSearchTypesList">
          <div class="search-type__label"
               @click="openSearchTypesList">
            <p :title="searchType.name">{{ searchType.name }}</p>
            <svg :class="{ 'rotate': isShowSearchTypesList }" width="12px" height="12px" aria-hidden="true"
                 focusable="false" data-prefix="far" data-icon="chevron-down"
                 role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"
                 class="svg-inline--fa fa-chevron-down fa-w-14 fa-2x">
              <path data-v-18a043e7="" fill="currentColor"
                    d="M441.9 167.3l-19.8-19.8c-4.7-4.7-12.3-4.7-17 0L224 328.2 42.9 147.5c-4.7-4.7-12.3-4.7-17 0L6.1 167.3c-4.7 4.7-4.7 12.3 0 17l209.4 209.4c4.7 4.7 12.3 4.7 17 0l209.4-209.4c4.7-4.7 4.7-12.3 0-17z"></path>
            </svg>
          </div>
          <div class="search-type__list"
               v-if="isShowSearchTypesList">
            <div class="search-type__item-wrap"
                 v-for="item in searchTypeList"
                 :key="item.id" @click="changeSearchType(item)">
              <div class="search-type__item"
                   :title="item.name"
                   v-if="item.name !== searchType.name">
                {{ item.name }}
              </div>
            </div>
          </div>
        </div>
        <input type="search"
               :placeholder="$t('socialSpace.searchTitle')"
               @focusin="setFocusField(true)"
               @input="changeSearch()"
               @keyup.delete="resetPages"
               v-model="inputValue"
               @keypress="isLetter($event)">
        <span class="header-search__icon" :class="{ 'rotate': inputValue.length && show }">
                <svg width="12px" height="12px" aria-hidden="true" focusable="false" data-prefix="far"
                     data-icon="chevron-down" role="img"
                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"
                     class="svg-inline--fa fa-chevron-down fa-w-14 fa-2x"><path fill="currentColor"
                                                                                d="M441.9 167.3l-19.8-19.8c-4.7-4.7-12.3-4.7-17 0L224 328.2 42.9 147.5c-4.7-4.7-12.3-4.7-17 0L6.1 167.3c-4.7 4.7-4.7 12.3 0 17l209.4 209.4c4.7 4.7 12.3 4.7 17 0l209.4-209.4c4.7-4.7 4.7-12.3 0-17z"
                                                                                class=""></path></svg>
            </span>
      </form>
    </div>
    <div class="header-search__result"
         v-show="inputValue.length && show ">
      <div class="header-search__result-list">
        <div class="header-search__result-item search-item"
             v-for="(item, index) in searchList" :key="index">
          <router-link class="search-item__wrap" :to="$localize({
                            name: 'start',
                            params: { nickname: item.nickname }
                         })">
            <div class="search-item__left">
              <Avatar :user="{
                firstName: item.firstName,
                 lastName: item.lastName,
                  avatarLink: item.picture}"
                      :size="30"/>
            </div>
            <div class="search-item__right">
              <div class="search-item__name"
                   v-if="item.firstName">
                {{ item.firstName }} {{ item.lastName }}
              </div>
              <div class="search-item__nickname"
                   v-if="item.nickname">
                /{{ item.nickname }}
              </div>
            </div>
          </router-link>
        </div>
        <div class="search-list__infinity" v-if="isNextPageSearchResult && searchList">
          <infinite-loading
            :identifier="infiniteId"
            @infinite="infiniteHandler"
            class="search-results">
            <Loader slot="spinner"/>
            <div slot="no-more"></div>
            <div slot="no-results"></div>
          </infinite-loading>
        </div>
        <p class="header-search__empty"
           v-show="noResultSearch">
          {{ $t('noResultSearch') }}
        </p>
        <div class="header-search__loader"
             v-show="searchLoader">
          <Loader/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import Loader from '@/components/atoms/Spinner.vue'
import debounce from 'lodash/debounce'
import Avatar from '@/components/atoms/Avatar.vue'
import { mixin as clickaway } from 'vue-clickaway'
import InfiniteLoading from 'vue-infinite-loading'

const OFFSET = 30

export default {
  name: 'HeaderSearch',
  mixins: [clickaway],
  data () {
    return {
      show: false,
      inputValue: '',
      searchType: {
        name: this.$t('profiles'),
        value: 'accounts'
      },
      searchTypeList: [
        {
          id: 0,
          name: this.$t('profiles'),
          value: 'accounts'
        },
        {
          id: 1,
          name: this.$t('location'),
          value: 'places'
        },
        {
          id: 2,
          name: this.$t('tags'),
          value: 'tags'
        }
      ],
      isShowSearchTypesList: false,
      infiniteId: +new Date(),
      page: 0,
      infinitePage: 1
    }
  },
  components: {
    Loader,
    Avatar,
    InfiniteLoading
  },
  watch: {
    '$route.path' () {
      if (this.show) {
        this.show = false
      }
    }
  },
  computed: {
    ...mapGetters({
      searchLoader: 'search/searchLoader',
      searchList: 'search/searchList',
      profilesData: 'search/profilesData',
      noResultSearch: 'search/noResultSearch',
      isNextPageSearchResult: 'search/isNextPageSearchResult'
    })
  },
  methods: {
    ...mapActions({
      getSearchList: 'search/getSearchList'
    }),
    ...mapMutations({
      reset: 'search/resetSearchList',
      showNoResultsSearch: 'search/showNoResultsSearch',
      setNextSearchPage: 'search/setNextSearchPage'
    }),
    sendRequest: debounce(function () {
      if (this.inputValue.length) {
        this.getSearchList({
          limit: OFFSET,
          offset: OFFSET * this.page,
          query: this.inputValue,
          type: this.searchType.value
        })
      }
    },
    500),
    async infiniteHandler ($state) {
      await this.getSearchList({
        limit: OFFSET,
        offset: OFFSET * this.infinitePage,
        query: this.inputValue,
        type: this.searchType.value
      })
      if (!this.isNextPageSearchResult) {
        $state.complete()
      } else {
        $state.loaded()
        this.infinitePage += 1
      }
    },
    resetPages () {
      this.infinitePage = 1
      this.setNextSearchPage(null)
    },
    changeSearch () {
      this.setFocusField(true)
      this.showNoResultsSearch(false)
      this.reset()
      this.resetPages()
      this.sendRequest()
    },
    isLetter (e) {
      const char = String.fromCharCode(e.keyCode)
      if (/^[a-zA-Z0-9-а-яА-я ]*$/.test(char)) return true
      else e.preventDefault()
    },
    setFocusField (status) {
      if (this.inputValue.length) {
        this.show = status
      }
    },
    changeSearchType (item) {
      this.searchType = item
      this.isShowSearchTypesList = false
      this.resetPages()
    },
    closeSearchTypesList () {
      this.isShowSearchTypesList = false
    },
    openSearchTypesList () {
      this.inputValue = ''
      this.setFocusField(false)
      this.isShowSearchTypesList = !this.isShowSearchTypesList
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  &-search {
    width: 100%;
    height: 40px;
    position: relative;
    &__bg {
      position: fixed;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100vw;
      height: 100vh;
      background: transparent;
      z-index: 2;
      transition: all 0.3s ease;
    }
    &__wrap {
      height: 100%;
      position: relative;
      z-index: 3;
    }
    &__form {
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
    input {
      background: #eceff0;
      width: 100%;
      height: 100%;
      border-radius: 0 15px 15px 0;
      border: none;
      padding: 0 15px 0 20px;
      font-family: $mont;
      color: #7e7e7e;
      font-size: 16px;
      line-height: 40px;
      border-left: 1px solid $white;
      &::placeholder {
        font-family: $mont;
        color: #7e7e7e;
        font-size: 16px;
        line-height: 40px;
      }
    }
    &__icon {
      position: absolute;
      right: 12px;
      top: 50%;
      transform: translateY(-50%);
      &.rotate svg {
        transform: rotate(180deg);
      }
      svg {
        width: 15px;
        height: 15px;
        transition: transform $trans;
      }
    }
    &__result {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 100%;
      z-index: 5;
      max-width: 1440px;
      width: 100%;
      &-bg {
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        width: 100vw;
        height: 100vh;
        background: rgba(104, 104, 104, 0.2);
        backdrop-filter: blur(3px);
        z-index: 2;
        transition: all 0.3s ease;
      }
      &-list {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        position: relative;
        width: 100%;
        background: #eceff0;
        box-sizing: border-box;
        box-shadow: 0 4px 5px rgba(0, 0, 0, 0.25);
        border-radius: 0 0 5px 5px;
        min-height: 75px;
        max-height: 360px;
        overflow-y: auto;
        &:after {
          content: '';
          display: block;
          position: absolute;
          left: 5px;
          top: -15px;
          right: 5px;
          width: 100%;
          height: 15px;
          background: $white;
        }
        &::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
          background: transparent;
          border-radius: 10px;
        }
        &::-webkit-scrollbar {
          width: 5px;
          background: transparent;
          border-radius: 0;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background: #00b764;
        }
      }
      .search-item {
        width: 100%;
        align-items: center;
        padding: 15px;
        border-bottom: 2px solid #E1E1E1;
        &:first-child {
          border-radius: 5px 5px 0 0;
        }
        &:last-child {
          border-radius: 0 0 5px 5px;
        }
        &:hover {
          background: #cecece;
        }
        &__wrap {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          text-decoration: none;
        }
        &__name {
          font-size: 14px;
          line-height: 16px;
          color: $black;
          text-decoration: none;
        }
        &__nickname {
          font-size: 14px;
          line-height: 16px;
          color: #686868;
          margin-top: 5px;
          text-transform: lowercase;
        }
        &__left {
          width: 30px;
          height: 30px;
          margin-right: 10px;
        }
        &__right {
          width: calc(100% - 40px);
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
        }
      }
    }
    &__loader {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      height: 100%;
      z-index: 2;
      background: #eceff0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &__empty {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px;
      font-size: 14px;
      line-height: 18px;
      color: #686868;
    }
  }
  .search-type {
    background: #eceff0;
    height: 100%;
    border-radius: 15px 0 0 15px;
    padding: 0 10px;
    line-height: 40px;
    color: #7e7e7e;
    font-size: 14px;
    width: 150px;
    text-align: center;
    cursor: pointer;
    position: relative;
    z-index: 3;
    &__label {
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      max-width: 130px;
      p {
        width: calc(100% - 20px);
        font-size: 16px;
        line-height: 40px;
        @include truncate();
      }
      svg {
        margin-left: 5px;
        transition: transform $trans;
        &.rotate {
          transform: rotate(180deg);
        }
      }
    }
    &__list {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      background: #eceff0;
      border-radius: 15px;
      overflow: hidden;
    }
    &__item {
      border-bottom: 1px solid $white;
      transition: background $trans;
      padding: 0 10px;
      @include truncate();
      &:hover {
        background: #e1e1e1;
      }
    }
  }
  .infinite-loading {
    height: 15px;
    width: 100%;
    padding: 30px 0;
    position: relative;
  }
  .search-list__infinity {
    width: 100%;
    height: 30px;
    padding: 30px 0;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    .search-results {
      display: flex;
      width: 45px;
      height: 45px;
    }
  }
  .infinite-status-prompt {
    display: block;
    width: 45px;
    height: 45px;
  }
}
</style>
