import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'

// modules
import Home from '@/modules/home/index.vue'
import Start from '@/modules/start/index.vue'
import CreateProfile from '@/modules/profile/components/CreateProfile.vue'
import EditProfile from '@/modules/profile/components/EditProfile.vue'
import NewPost from '@/modules/profile/components/CreatePost.vue'
import EditPost from '@/modules/profile/components/EditPost.vue'
import Likes from '@/modules/likes/index.vue'
import Favourites from '@/modules/favourites/index.vue'
import Trending from '@/modules/trending/index.vue'
import PageNotFound from '@/modules/system/PageNotFound.vue'

Vue.use(Router)

const originalReplace = Router.prototype.replace
Router.prototype.replace = function replace (location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch((err) => err)
}

const isAuthenticated = (to, from, next) => {
  if (store.state.auth.isAuthenticated) {
    next()
  } else {
    next({ name: 'start' })
  }
}

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/home',
      name: 'home',
      component: Home
    },
    {
      path: '/:nickname?',
      name: 'start',
      component: Start
    },
    {
      path: '/:nickname?/edit',
      name: 'social-edit-profile',
      component: EditProfile
    },
    {
      path: '/:nickname?/new-post',
      name: 'social-new-post',
      props: true,
      component: NewPost
    },
    {
      path: '/:nickname/:postId/edit',
      name: 'social-post-edit',
      props: true,
      component: EditPost
    },
    {
      path: '/create-profile',
      name: 'social-create-profile',
      component: CreateProfile
    },
    {
      path: '/likes',
      name: 'likes',
      component: Likes,
      beforeEnter: isAuthenticated
    },
    {
      path: '/favourites',
      name: 'favourites',
      component: Favourites,
      beforeEnter: isAuthenticated
    },
    {
      path: '/trending',
      name: 'trending',
      component: Trending,
      beforeEnter: isAuthenticated
    },
    {
      path: '/page-not-found',
      name: 'page-not-found',
      component: PageNotFound
    },
    {
      path: '/*',
      redirect: {
        name: 'page-not-found'
      }
    }
  ],
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export default router
