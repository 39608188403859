import { mapActions, mapGetters, mapMutations } from 'vuex'
import Loader from '@/components/atoms/Spinner.vue'
import Icon from '@/components/atoms/Icon.vue'
import BaseButton from '@/components/atoms/_FormButton.vue'
import { Stack, StackItem } from 'vue-stack-grid'

const OFFSET = 20

export default {
  name: 'home',
  data () {
    return {
      page: 1,
      showMoreLoading: false
    }
  },
  components: {
    Loader,
    BaseButton,
    Stack,
    StackItem,
    Icon
  },
  computed: {
    ...mapGetters({
      profile: 'user/profilesData',
      myProfile: 'user/myProfile',
      profilesLoading: 'user/profilesLoading',
      isNextPageProfilePosts: 'user/isNextPageProfilePosts',
      count: 'user/count'
    }),
    ownerId () {
      return this.myProfile?.userId
    },
    profilesList () {
      if (this.profile && this.ownerId && this.count) {
        return this.profile.map((item) => {
          item.isOwn = item.owner === this.ownerId
          return item
        })
      } else {
        return []
      }
    }
  },
  created () {
    this.clearProfilesData()
    this.fetchMyProfiles({
      limit: 30,
      offset: 0
    })
  },
  methods: {
    ...mapMutations({
      clearProfilesData: 'user/clearProfilesData'
    }),
    ...mapActions({
      deleteProfile: 'user/deleteProfile',
      fetchMyProfiles: 'user/fetchMyProfiles'
    }),
    editProfile (item) {
      if (item) {
        this.$router.push({
          name: 'social-edit-profile',
          params: { nickname: item.nickname }
        })
      }
    },
    goToCreatePost (item) {
      if (item) {
        this.$router.push({
          name: 'social-new-post',
          params: { nickname: item.nickname }
        })
      }
    },
    removeProfile (item) {
      const text = item.firstName ? item.firstName + ' ' + item.lastName : item.nickname
      this.$modal.show('confirm', {
        content: this.$t('remove') + ' ' + text + '?',
        onSubmit: () => {
          this.deleteProfile(item.nickname).then(() => {
            this.$toasted.success(this.$t('socialProfile.successDeleteProfile'))
          }).catch((e) => {
            this.$toasted.success(e.data.error)
          })
        }
      })
    },
    moreItem () {
      this.showMoreLoading = true
      this.fetchMyProfiles({
        limit: OFFSET,
        offset: OFFSET * this.page
      }).then(() => {
        if (this.isNextPageProfilePosts) {
          this.page += 1
        }
      }).finally(() => {
        this.showMoreLoading = false
      })
    },
    nFormatter (num, digits) {
      const lookup = [
        { value: 1, symbol: '' },
        { value: 1e3, symbol: 'k' },
        { value: 1e6, symbol: 'M' },
        { value: 1e9, symbol: 'G' },
        { value: 1e12, symbol: 'T' },
        { value: 1e15, symbol: 'P' },
        { value: 1e18, symbol: 'E' }
      ]
      const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
      const item = lookup.slice().reverse().find(function (item) {
        return num >= item.value
      })
      return item ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol : '0'
    }
  }
}
