<template>
  <div class="create-post">
    <h1 class="create-post__title">{{ title }}</h1>

    <BaseInput
      v-model="text"
      multiline
      :placeholder="$t('socialPost.placeholder')"
      :invalid="$v.text.$error"
      :error="$t('errorEmptyField')"
      @blur="$v.text.$touch()"
    />

    <div class="create-post__image">
      <label class="form--label">{{ $t('socialPost.image') }}</label>
      <FileInput
        v-if="!imageSrc"
        accept="image/*"
        @input="onFileChange"
        class="file-input"
        :withDragAndDrop="true"
      >
      </FileInput>
      <MediaPreview
          :src="imageSrc"
          @close="imageSrc = null"
          v-viewer
      />
    </div>

    <div class="create-post__options">
      <div class="create-post__options-title">
        {{ $t('postSettings') }}
      </div>
      <BaseSwitch
          v-model="isEnabledLikes">
        {{ $t('socialPost.isEnabledLikes') }}
      </BaseSwitch>
      <BaseSwitch
          v-model="isEnabledComments">
        {{ $t('socialPost.isEnabledComments') }}
      </BaseSwitch>
    </div>
    <div class="create-post__action">
      <BaseButton
          oval
          :loading="loading"
          @click="onSubmit">
        {{ buttonText }}
      </BaseButton>
    </div>

  </div>
</template>

<script>
import { mapActions } from 'vuex'
import FileInput from '@/components/atoms/FileInput'
import BaseInput from '@/components/atoms/BaseInput.vue'
import BaseSwitch from '@/components/atoms/_BaseSwitch.vue'
import BaseButton from '@/components/atoms/_FormButton.vue'
import imageCompression from 'browser-image-compression'
import MediaPreview from '@/components/atoms/MediaPreview.vue'

export default {
  name: 'social-create-post',
  components: {
    FileInput,
    MediaPreview,
    BaseInput,
    BaseSwitch,
    BaseButton
  },
  props: {
    nickname: String
  },
  data () {
    return {
      text: '',
      imageFile: null,
      imageSrc: null,
      isEnabledLikes: true,
      isEnabledComments: false,
      loading: false
    }
  },
  created () {},
  computed: {
    title () {
      return this.$t('socialPost.newPostTitle')
    },
    buttonText () {
      return this.$t('buttonCreate')
    }
  },
  validations: {
    text: {
    }
  },
  methods: {
    ...mapActions({
      createPost: 'user/createPost'
    }),
    async onFileChange (file) {
      this.$modal.show('cropper', {
        aspectRatio: 1,
        file,
        onDone: async canvas => {
          this.imageSrc = canvas.toDataURL()
          const blob = await imageCompression.getFilefromDataUrl(this.imageSrc, 'social-post-picture')
          const compressedBlob = await imageCompression(blob, {
            maxSizeMB: 1
          })
          this.imageFile = new File([compressedBlob], `${compressedBlob.name}.${compressedBlob.type.split('/')[1]}`)
        }
      })
    },
    async onSubmit () {
      this.$v.$touch()
      if (this.loading || this.$v.$invalid) return
      const data = {
        isEnabledLikes: this.isEnabledLikes,
        isEnabledComments: this.isEnabledComments
      }
      if (this.text) {
        data.text = this.text
      }
      if (this.imageFile) {
        data.image = this.imageFile
      }
      this.loading = true
      try {
        await this.createPost({
          profileNickname: this.nickname,
          data
        })
        this.$toasted.success(this.$t('successCreatePost'))
        this.$router.push({ name: 'start', params: { nickname: this.nickname } })
      } catch (e) {
        this.$toasted.error(JSON.stringify(e.response.data))
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.create-post{
  margin: 40px auto;
  padding: 0 25px;
  max-width: 800px;
  .button {
    margin: 14px auto;
  }
  &__title{
    font-size: 18px;
    font-weight: 500;
    color: $text-success;
    margin: 0;
    margin-bottom: 16px;
    text-align: center;
  }
  &__image{
    margin: 20px 0;
    padding: 30px 15px;
    background: #eceff0;
    & > label.form--label{
      margin-bottom: 20px;
      font-size: 18px;
      font-weight: 500;
      color: #00b764;
      text-align: center;

    }
    .form--label{
      display: block;
      color: #3b3b3b;
      font-size: 14px;
    }
    .file-input{
      display: block;
      margin-top: 10px;
      svg{
        margin-right: 5px;
      }
    }
    .gl-media-preview{
      margin: 0 auto;
    }
  }
  &__options{
    margin: 30px 0;
    padding: 30px 15px;
    background: #eceff0;
    &-title{
      font-size: 18px;
      font-weight: 500;
      color: #00b764;
      margin: 0;
      margin-bottom: 16px;
      text-align: center;
    }
    label {
      cursor: pointer;
    }
    label + label{
      margin-top: 15px;
    }
  }
  &__action{
    button{
      margin: 0 auto;
    }
  }
}
.file-input{
  width: 100%;
}
</style>
