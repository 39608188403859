import api from '@/api'
import Vue from 'vue'

const state = {
  searchList: [],
  searchLoader: false,
  noResultSearch: false,
  count: 0,
  isNextPageSearchResult: null
}

const getters = {
  searchList: state => state.searchList,
  count: state => state.count,
  searchLoader: state => state.searchLoader,
  noResultSearch: state => state.noResultSearch,
  isNextPageSearchResult: state => state.isNextPageSearchResult
}

const actions = {
  async getSearchList ({ commit }, payload) {
    try {
      commit('changeSearchLoading', true)
      commit('showNoResultsSearch', false)
      const response = await api.search.fetchSearchList(payload)
      response.count === 0 ? commit('showNoResultsSearch', true) : commit('showNoResultsSearch', false)
      commit('setSearchCount', response.count)
      commit('setSearchList', response.results)
      commit('setNextSearchPage', response.next)
    } catch (e) {
      throw e.response
    } finally {
      commit('changeSearchLoading', false)
    }
  }
}

const mutations = {
  setSearchList (state, data) {
    if (state.searchList?.length) {
      const newData = [...state.searchList, ...data]
      Vue.set(state, 'searchList', newData)
    } else {
      Vue.set(state, 'searchList', data)
    }
  },
  setSearchCount (state, count) {
    state.count = count
  },
  resetSearchList (state) {
    state.searchList = null
  },
  changeSearchLoading (state, status) {
    state.searchLoader = status
  },
  setNextSearchPage (state, status) {
    state.isNextPageSearchResult = status
  },
  showNoResultsSearch (state, status) {
    state.noResultSearch = status
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}
