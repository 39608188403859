<template>
  <div class="header" id="header">
    <div class="container">
      <div class="header-wrap">
        <div class="header-left">
          <div class="header-logo">
            <a href="#" @click.prevent="changeHeaderLogo">
              <img src="../../assets/img/home/social-logo.png" alt="Hola">
            </a>
          </div>
        </div>
        <div class="header-center" v-if="isAuthenticated">
          <HeaderSearch />
          <HeaderNavigation />
        </div>
        <div class="header-right">
          <div class="header-language">
            <tooltip-layout :maxWidth="160" position="left">
              <template slot="icon">
                <img :src="`/img/locales/${currentLanguage.urlPrefix}.png`" class="flag">
              </template>
              <ul>
                <li v-for="item in languages" :key="item.id" class="column">
                  <a @click.prevent="changeLanguage(item)" :title="item.title" href="#"
                     v-if="item.urlPrefix !== currentLanguage.urlPrefix">
                    <span class="language-item">
                        <img :alt="item.alt"
                             :src="`/img/locales/${item.urlPrefix}.png`"
                             class="flag"/>
                      <span class="language__text">{{ item.name }}</span>
                    </span>
                  </a>
                </li>
              </ul>
            </tooltip-layout>
          </div>
          <div class="header-menu" v-if="isAuthenticated">
            <tooltip-layout :maxWidth="300" position="left" :arrow="false">
              <template slot="icon">
                <Avatar v-if="profile"
                        :user="{firstName: profile.firstName, lastName: profile.lastName, avatarLink: profile.avatarLink}"
                        :size="40"/>
              </template>
              <nav class="header-nav">
                <ul class="header-nav__list">
                  <li>
                    <router-link :to="$localize({ name: 'home' })" class="header-nav__item">
                      <span class="header-nav__item-ico">
                        <icon name="headerNavigationHome"></icon>
                      </span>
                      <div class="header-nav__item-label">{{ $t('navigationHome') }}</div>
                    </router-link>
                  </li>
                  <li>
                    <router-link :to="$localize({ name: 'likes' })" class="header-nav__item">
                       <span class="header-nav__item-ico">
                          <icon name="headerNavigationLikes"></icon>
                       </span>
                      <div class="header-nav__item-label">{{ $t('navigationLiked') }}</div>
                    </router-link>
                  </li>
                  <li>
                    <router-link :to="$localize({ name: 'trending' })" class="header-nav__item">
                      <span class="header-nav__item-ico">
                        <icon name="headerNavigationTrending"></icon>
                      </span>
                      <div class="header-nav__item-label">{{ $t('navigationTrending') }}</div>
                    </router-link>
                  </li>
                  <li>
                    <router-link :to="$localize({ name: 'favourites' })" class="header-nav__item">
                      <span class="header-nav__item-ico">
                        <icon name="headerNavigationFavourites"></icon>
                      </span>
                      <div class="header-nav__item-label">{{ $t('navigationFavourites') }}</div>
                    </router-link>
                  </li>
                  <li>
                    <a href="" @click.prevent="logout" class="header-nav__item">
                      <span class="header-nav__item-ico">
                        <icon name="headerNavigationLogout"></icon>
                      </span>
                      <div class="header-nav__item-label">{{ $t('authLogout') }}</div>
                    </a>
                  </li>
                </ul>
              </nav>
              <app-links/>
            </tooltip-layout>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TooltipLayout from '@/components/atoms/TooltipLayout.vue'
import Icon from '@/components/atoms/Icon.vue'
import AppLinks from '@/components/features/AppLinks.vue'
import HeaderNavigation from '@/components/features/HeaderNavigation.vue'
import HeaderSearch from '@/components/features/HeaderSearch.vue'
import Avatar from '@/components/atoms/Avatar.vue'

export default {
  name: 'main-header',
  data () {
    return {
      isOpenMenu: false,
      isActive: false
    }
  },
  components: {
    TooltipLayout,
    Avatar,
    AppLinks,
    HeaderNavigation,
    HeaderSearch,
    Icon
  },
  computed: {
    ...mapGetters({
      showLangList: 'system/showLangList',
      isShowLanguageList: 'system/isShowLanguageList',
      isAuthenticated: 'auth/isAuthenticated',
      profile: 'user/myProfile'
    }),
    ...mapGetters([
      'languages',
      'currentLanguage'
    ])
  },
  methods: {
    changeHeaderLogo () {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    },
    changeLanguage (language) {
      this.$setLanguage(language.code)
      setTimeout(() => {
        window.location.reload()
      }, 800)
    },
    openMenu () {
      this.isOpenMenu = !this.isOpenMenu
    },
    logout () {
      this.$modal.show('logout')
    }
  }
}
</script>

<style lang="scss">
.header {
  z-index: 10;
  position: relative;
  padding: 20px 0 30px;
  border-bottom: 1px solid #ebefef;
  @include respondTo(700px){
    padding: 15px 0;
  }
  &-right {
    display: flex;
    flex-direction: row;
  }
  &-center{
    width: 550px;
    @include respondTo(900px){
      padding: 0 15px;
    }
    @include respondTo(700px){
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 0;
      width: 100%;
    }
  }
  &-wrap {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    @include respondTo(700px){
      padding-bottom: 55px;
    }
  }
  &-logo {
    a {
      display: block;
      width: 310px;
      @include respondTo(1200px) {
        width: 150px;
      }
      img {
        width: 310px;
        transition: transform $trans;
        @include respondTo(1200px) {
          width: 150px;
        }
      }
      &:hover img {
        transform: scale(0.9);
      }
    }
  }
  &-language {
    z-index: 3;
    margin-right: 25px;
  }
  &-lang {
    &__item {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }
    &__name {
      margin-left: 10px;
    }
  }
  &-menu {
    z-index: 3;
  }
  &-nav {
    padding: 8px 0;
    overflow: hidden;
    .tooltip-layout__wrap::after {
      display: none !important;
    }
    li:last-child .header-nav__item-label {
      border-bottom: none;
    }
    li {
      ul {
        position: relative;
        z-index: 2;
        background: #ffffff;
        li {
          padding-left: 20px;
          padding-right: 10px;
          width: calc(100% + 10px);
        }
        a.router-link-exact-active {
          position: relative;
          &:after {
            content: '';
            position: absolute;
            left: -20px;
            top: 0;
            width: 20px;
            height: 100%;
            background: #dbebe4;
          }
        }
      }
    }
    &__item {
      display: flex !important;
      justify-content: flex-start;
      align-items: center;
      text-decoration: none;
      padding: 0;
      margin: 0;
      height: 48px;
      position: relative;
      transition: background .2s ease-in-out;
      color: #111;
      &-ico {
        width: 52px;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: .65;
      }
      &-label {
        width: calc(100% - 52px);
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        position: relative;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        text-transform: capitalize;
      }
      &:before{
        content: '';
        position: absolute;
        bottom: 0;
        left: 52px;
        width: calc(100% - 52px);
        height: 1px;
        background: rgba(0, 0, 0, .1);
      }
    }
  }
}
</style>
