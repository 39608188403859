<template>
  <div class="copy">
    <button class="copy-button"
            @click="onCopy">
      <span class="copy-button__label">
        {{ $t('buttonCopy') }}
      </span>
      <span class="copy-button__icon">
              <svg width="14px" height="14px" aria-hidden="true" focusable="false" data-prefix="far" data-icon="clone" role="img"
                   xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
                   class="svg-inline--fa fa-clone fa-w-16 CopyButton_icon_FC4XS">
        <path fill="currentColor"
              d="M464 0H144c-26.51 0-48 21.49-48 48v48H48c-26.51 0-48 21.49-48 48v320c0 26.51 21.49 48 48 48h320c26.51 0 48-21.49 48-48v-48h48c26.51 0 48-21.49 48-48V48c0-26.51-21.49-48-48-48zM362 464H54a6 6 0 0 1-6-6V150a6 6 0 0 1 6-6h42v224c0 26.51 21.49 48 48 48h224v42a6 6 0 0 1-6 6zm96-96H150a6 6 0 0 1-6-6V54a6 6 0 0 1 6-6h308a6 6 0 0 1 6 6v308a6 6 0 0 1-6 6z"
              class=""></path>
      </svg>
      </span>
    </button>
  </div>
</template>

<script>
export default {
  name: 'CopyButton',
  props: {
    link: {
      type: String,
      default: ''
    }
  },
  methods: {
    onCopy () {
      this.$copyText(this.link).then(() => {
        this.$toasted.success(this.$t('copyClipboardSuccess'))
      }, () => {
        this.$toasted.error(this.$t('copyClipboardError'))
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.copy {
  &-button {
    padding: 15px 30px;
    background: $green;
    margin-bottom: 30px;
    border-radius: 15px;
    border: 1px solid transparent;
    color: $white;
    transition: color $trans, background $trans, border-color $trans;
    &:hover{
      background: white;
      color: $green;
      border: 1px solid $green;
    }
    &__label {
      font-size: 16px;
    }
    &__icon {
      margin-left: 10px;
    }
  }
}
</style>
