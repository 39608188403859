export default {
  termOfService: 'Laufzeit der Dienstleistungen',
  privacyPolicy: 'Datenschutz-Bestimmungen',
  xCommunications: 'xKommunikation',
  xCommunicationsInvestorRelations: 'Anlegerbeziehungen',
  xCommunicationsMediaRequest: 'Medienanfrage',
  xCommunicationsOurPositionPrivacy: 'Unsere Position zum Datenschutz',
  xCommunicationsKnowledgeBase: 'Wissensbasis',
  xCommunicationsOurPosition: 'Unsere Position zu Cybermobbing',
  premiumTools: 'Premium Tools',
  premiumToolsSayHolaLiveChatPlugin: 'Live Chat Plugin',
  premiumToolsSayHolaBlogPlugin: 'sayHola Blog Plugin',
  premiumToolsTogetherSayHola: 'zusammen von sayHola',
  premiumToolsLiveLeagueApp: 'Live League App',
  programs: 'Programme',
  programsAdvertiseHola: 'Werben Sie mit Hola',
  programsFreelancerProgram: 'Freiberuflerprogramm',
  programsAffiliateRewardsProgram: 'Affiliate-Prämienprogramm',
  terms: 'Bedingungen',
  termsAdsPolicy: 'Anzeigenrichtlinie',
  termsPrivacyPolicy: 'Datenschutz-Bestimmungen',
  termsCommunityStandards: 'Gemeinschaftsstandards',
  termsTermsUse: 'Nutzungsbedingungen',
  termsLoyaltyRewardsTerms: 'Belohnungsrichtlinie',
  otp_unconfirmed: 'Nicht bestätigt, versuche es noch einmal',
  pin_unconfirmed: 'Falscher PIN-Code',
  otpDescription: 'Ihnen wurde ein SMS-Code zugesandt. Bitte Code unten eingeben.',
  otpEnterYourOtp: 'Geben Sie Ihren otp-Code ein',
  otpEnterYourPin: 'Geben Sie Ihren PIN-Code ein',
  verify_otp: 'Bestätigen',
  otpDontReceiveCode: 'Code nicht erhalten?',
  otpRequestSMSAgain: 'SMS erneut anfordern',
  otpRequestNewCode: 'Fordern Sie einen neuen Code an in',
  copyright: 'xKommunikation. Alle Rechte vorbehalten',
  enterPhoneNumber: 'Bitte wählen Sie Ihr Land und geben Sie Ihre vollständige Telefonnummer ein.',
  login: 'Anmeldung',
  validationSelectFile: 'Foto auswählen und hochladen',
  validationSameAs: 'Passwörter stimmen nicht überein',
  validationMaxLengthField: 'Maximale Anzahl Feldzeichen :field - :count',
  validationMinLengthField: 'Mindestanzahl von Feldzeichen :field - :count',
  validationRequired: 'Füllen Sie dieses Feld aus',
  validationEmail: 'Geben Sie eine gültige E-Mail ein',
  validationUrl: 'Geben Sie eine gültige URL ein',
  needChangePhone: 'Müssen Sie Ihre Nummer ändern?',
  recovery: 'Wiederherstellung',
  needMoreInfo: 'Benötigen Sie weitere Informationen?',
  learnMore: 'Mehr erfahren',
  startUsingHola: 'Beginnen Sie mit sayHola, um den <br> Social Space anzuzeigen',
  startUsingHolaFollow: 'Beginnen Sie, sayHola zu verwenden, um zu folgen',
  socialSpaceWord: 'sozialer Raum',
  socialSpaceInvite: 'Social Space-Einladung',
  socialSpaceInviteMessage: 'ein sicherer, privater Raum für Unternehmen, Prominente, Blogger, Influencer, Community und Einzelpersonen ohne Kommentare und Fake News',
  enterPhone: 'Telefon eingeben',
  footerPrivateAppJobsPage: 'Arbeitsmöglichkeiten',
  socialPost: {
    image: 'Bild',
    isEnabledComments: 'Kommentare',
    isEnabledLikes: 'Gefällt mir Knopf',
    newPostTitle: 'Neuer Beitrag',
    placeholder: 'Starten Sie hier Ihre Nachricht und #Hashtags',
    editPostTitle: 'Beitrag bearbeiten',
    deleteButton: 'Beitrag löschen',
    editButton: 'Beitrag bearbeiten',
    reportButton: 'Prüfbericht',
    reportConfirmation: 'Möchten Sie diesen Beitrag wirklich melden?',
    reportText: 'Danke, dass sie es uns wissen lassen. Ihr Feedback hilft bei der Nutzung durch die Community. Andere Schritte, die Sie unternehmen können, ist das Blockieren des Benutzers. Sie können sich nicht mehr sehen oder kontaktieren, wenn Sie dies tun.',
    likes: 'Likes',
    hideComments: 'Kommentare ausblenden',
    viewLatestComments: 'Neueste Kommentare anzeigen',
    reply: 'Antwort',
    replies: 'antwortet',
    placeholderComment: 'Schreibe einen Kommentar',
    placeholderReply: 'Antwort an {name}',
    commentReportConfirmation: 'Möchten Sie diesen Kommentar wirklich melden?'
  },
  btnHide: 'Verstecken',
  btnShow: 'Zeigen',
  btnReply: 'Antwort',
  buttonEdit: 'Bearbeiten',
  socialComment: {
    deleteConfirmation: 'Möchten Sie den Kommentar wirklich löschen? <b>{text}</b>?'
  },
  buttonLoadMore: 'Mehr laden',
  socialSpace: {
    searchTitle: 'Suchen',
    accounts: 'Konten',
    places: 'Setzt',
    tags: 'Stichworte'
  },
  searchNoResults: 'Keine Ergebnisse',
  startTypingForSearch: 'Beginnen Sie mit der Eingabe für die Suche',
  userStatus: {
    btnAdd: 'Status hinzufügen',
    tabSetStatus: 'Status setzen',
    tabCreateStatus: 'Status erstellen',
    titlePlaceholder: 'Statustitel',
    textPlaceholder: 'Starten Sie hier Ihren Status (max. 250 Zeichen)',
    editStatusTitle: 'Status bearbeiten'
  },
  buttonCancel: 'Abbrechen',
  buttonDelete: 'Löschen',
  blogSubscribeBtn: 'Abonnieren',
  blogUnsubscribeBtn: 'Abbestellen',
  popupConfirmTitle: 'Bestätigung',
  popupConfirmText: 'Bist du sicher?',
  buttonAdd: 'Hinzufügen',
  buttonConfirm: 'Bestätigen Sie',
  socialProfile: {
    categoryCompany: 'Gesellschaft',
    categoryIndividual: 'Individuell',
    extendedSettings: 'Erweiterte Einstellungen',
    firstName: 'Vorname',
    lastName: 'Nachname',
    nickname: 'Spitzname',
    isActive: 'Soziales Profil (Live)',
    profileViewOptions: 'Profilansichtsoptionen',
    isPublic: 'Öffentlich (durchsuchbar)',
    isChatActive: 'Nachricht an mich (sayHola)',
    showFollowers: 'Followerzahl',
    showSocialSpace: 'Sozialraum anzeigen',
    textLocation: 'Standort (Stadt, Land)',
    pictureError: 'Bitte lade dein Profilbild hoch',
    errorNicknameExists: 'Profil mit diesem Spitznamen existiert bereits',
    editTitle: 'Mein Premium-Profil bearbeiten',
    btnCancelRequest: 'Anfrage abbrechen',
    btnFollow: 'Folgen',
    btnUnfollow: 'Entfolgen',
    followers: 'Anhänger',
    pending: 'Ausstehend',
    createTitle: 'Neues Profil erstellen',
    successCreateProfile: 'Profil erfolgreich erstellt!',
    successEditProfile: 'Profilinformationen aktualisiert!',
    successDeleteProfile: 'Profil erfolgreich gelöscht!',
    showCountPosts: 'Beiträge zählen',
    showCountFollowing: 'Folgende Zählung',
    socialProfile: 'Soziales Profil'
  },
  authLogout: 'Ausloggen',
  logout: {
    confirmation: 'Möchten Sie sich wirklich abmelden?',
    allSessions: 'Von allen Sitzungen abmelden',
    title: 'Ausloggen'
  },
  buttonCreate: 'Schaffen',
  buttonRegistration: 'Registrierung abschließen',
  buttonEnter: 'Betreten Sie Hola',
  buttonPay: 'Zahlen',
  buttonUpgrade: 'Aktualisierung',
  buttonCopy: 'Kopieren',
  buttonSave: 'Speichern',
  buttonSend: 'Senden',
  successTitle: 'Erfolgreich',
  errorTitle: 'Fehler',
  error: 'Entschuldigung, etwas ist schief gelaufen',
  errorEmailInvalid: 'Geben sie eine gültige E-Mail-Adresse an',
  errorPhoneInvalid: 'Ungültige Telefonnummer',
  errorEmptyField: 'Dieses Feld wird benötigt',
  errorAlphanumericDashUnderscore: 'Dieses Feld darf nur alphanumerische Zeichen, Bindestriche und Unterstriche enthalten [a-z0-9-_]',
  category: 'Kategorie',
  chooseCategory: 'Kategorie wählen',
  chooseCategoryError: 'Bitte wähle eine Kategorie!',
  errorMinLength_begin: 'Stellen Sie sicher, dass dieses Feld mindestens',
  errorMaxLength_begin: 'Stellen Sie sicher, dass dieses Feld nicht mehr als . enthält',
  errorLength_end: 'Zeichen',
  formAttach: 'Anfügen',
  successCreatePost: 'Der Beitrag wurde erfolgreich erstellt',
  successEditPost: 'Beitrag erfolgreich bearbeitet',
  post: {
    author: 'Autor',
    createTitle: 'Neuen Beitrag erstellen',
    footerImages: 'Fußzeilenbilder',
    headerImage: 'Kopfzeilenbild',
    isPublished: 'Veröffentlicht',
    subtitle: 'Untertitel',
    text: 'Text',
    textPlaceholder: 'Starten Sie hier Ihren Blog. Der erste Buchstabe ist fett und groß',
    title: 'Titel',
    delete: 'Beitrag löschen',
    edit: 'Beitrag bearbeiten',
    footerVideos: 'Fußzeilenvideos',
    tabPhotos: 'Fotos',
    tabVideos: 'Videos'
  },
  deleteConfirmation: 'Sind Sie sicher, dass Sie löschen möchten <b>{name}</b>?',
  postNotFound: 'Beitrag nicht gefunden!',
  createProfile: 'Profil erstellen',
  removeProfile: 'Profil entfernen',
  editProfile: 'Profil bearbeiten',
  createPost: 'Beitrag erstellen',
  create: 'Schaffen',
  dontHaveProfile: 'Sie haben kein soziales Profil.',
  chooseProfile: 'Wählen Sie ein soziales Profil',
  needSubscribeMessage: 'Sie müssen sich anmelden, um das Profil anzuzeigen!',
  profilePost: 'Post',
  profileFollowing: 'Folge',
  profileFollowers: 'Anhänger',
  emptyPostList: 'Die Beitragsliste ist leer',
  navigationHome: 'Heimat',
  navigationLiked: 'Likes',
  navigationTrending: 'angesagt',
  navigationFavourites: 'Favoriten',
  navigationFollowers: 'Anhänger',
  postSettings: 'Beitragseinstellungen',
  noResultSearch: 'keine Ergebnisse gefunden',
  profiles: 'Profile',
  location: 'Standort',
  tags: 'Stichworte',
  showMore: 'Zeig mehr',
  remove: 'Entfernen',
  uploadPhoto: 'Foto hochladen',
  dragAndDrop: 'oder einfach Drag & Drop',
  emptyList: 'Die Liste ist leer!',
  open: 'Offen',
  advertiseUsingCampaigns: 'Werben Sie hier mit Kampagnen',
  postButton: 'Post',
  settingsButton: 'Einstellungen',
  viewButton: 'Sicht',
  deleteButton: 'Löschen',
  posts: 'Beiträge',
  profileSettings: 'Profileinstellungen',
  backgroundPicture: 'Hintergrundbild',
  profilePicture: 'Profilbild',
  fillUserInfo: 'Bitte geben Sie die Informationen ein:',
  requiredFields: 'Zeigt das erforderliche Feld an.',
  formFirstName: 'Vorname',
  formLastName: 'Nachname',
  formNickName: 'Nutzername',
  profileEmailInfo: 'Ihre E-Mail wird nur zur Wiederherstellung verwendet. Durch die Eingabe Ihrer E-Mail akzeptieren Sie die Datenschutzerklärung und Geschäftsbedingungen von sayHola. Links werden zur Wiederherstellung an Ihre E-Mail gesendet.',
  formEmail: 'Deine E-Mail',
  error_email_exists: 'Ein Benutzer existiert bereits mit dieser E-Mail-Adresse',
  backToHomePage: 'Zurück zur Startseite',
  pageNotFoundMessage: 'Wir können die von Ihnen gesuchte Seite nicht finden',
  resendOtpMessage: 'Eine weitere SMS wurde gesendet.',
  buttonDone: 'Fertig',
  viewProfile: 'Profil anzeigen',
  copyClipboardSuccess: 'Erfolgreich in die Zwischenablage kopieren',
  copyClipboardError: 'Fehler beim Kopieren in die Zwischenablage',
  loginByQRCodeMessage: 'QR-Code zur Anmeldung per Smartphone',
  authOption: 'Bitte wählen Sie eine Option zum Anmelden.',
  newRegistration: 'Neue Registrierung',
  registrationOption: 'Die Registrierung erfordert eine E-Mail-Adresse und einen Benutzernamen, um die Dienste nutzen zu können. Sobald Sie angemeldet sind, haben Sie die Möglichkeit, mit Ihrer Telefonnummer mit anderen Messenger-Apps zu synchronisieren',
  loginWithApp: 'anmelden mit app',
  loginWithAppOption: 'Öffnen Sie Hola auf Ihrem Telefon und gehen Sie zum Menü in der oberen rechten Ecke der App. Klicken Sie auf das QR-Code-Symbol. Richten Sie Ihr Telefon auf diesen Bildschirm, um die Anmeldung zu bestätigen.',
  recoveryOption: 'Erholung',
  recoveryOptionText: 'Zur Wiederherstellung Geben Sie Ihre registrierte E-Mail-Adresse ein, um sich über Hot-Link anzumelden. Erfahren Sie mehr, indem Sie den FAQ-Bereich besuchen',
  enterEmailRecovery: 'Geben Sie Ihre registrierte E-Mail-Adresse zur Wiederherstellung ein:',
  notFoundUserLabel: 'Benutzer mit dieser E-Mail nicht gefunden',
  successSendRecoveryEmailMessage: 'Überprüfen Sie Ihre E-Mails und folgen Sie den Anweisungen!',
  chatBots: 'ChatBots',
  BdtlAgencyProgram: 'BDTL-Agenturprogramm',
  agencyTerms: 'Bedingungen der Agentur',
  holaExtensions: 'Hola-Erweiterung',
  holaBlog: 'Hola Blog',
  browserExtensions: 'Browser-Erweiterungen',
  agencyTermsNew: 'Agenturrichtlinie ',
  earnPrograms: 'Verdienen Sie Programme',
  rewardsProgram: 'Belohnungsprogramm',
  despaxoTerms: 'Despaxo-Bedingungen',
  faqWord: 'Häufig gestellte Fragen',
  paidServices: 'Grundlegende und kostenpflichtige Dienste',
  multiapp: 'Multi-App-Synchronisierung',
  opportunities: 'Opportunities',
  despaxoDrivers: 'Despaxo-Treiber',
  generalTerms: 'Allgemeine Bedingungen',
  apps: 'Anwendungen',
  web: 'Netz',
  desktop: 'Schreibtisch',
  blogSyncPlugin: 'Blog-Sync-Plugin',
  together: 'Zusammen',
  chatbotConstructor: 'Chatbots-Konstrukteur',
  enterpriseProgram: 'Enterprise-Programm',
  bdtlProgram: 'BDTL-Programm',
  despaxoBot: 'Despaxo Bots Sync-Programm',
  extendetTerms: 'Erweiterte Bedingungen',
  despaxoPolicy: 'Despaxo-Richtlinie',
  despaxoDriverPolicy: 'Despaxo Treiberrichtlinie',
  driverPolicy: 'Fahrerrichtlinie',
  administrativeStatement: 'Verwaltungserklärung',
  despaxoDefinitions: 'Despaxo Definitionen',
  operatingDespaxo: 'Betrieb von Despaxo'
}
