// utils
import moment from 'moment'
import './utils/plugins'
import './utils/translations'
import './utils/global-components'

import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import VueRouter from 'vue-router'
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'

// moment
Vue.prototype.moment = moment

Vue.config.productionTip = false

if (localStorage.getItem('accessToken')) {
  store.state.auth.isAuthenticated = true
  store.state.auth.accessToken = localStorage.getItem('accessToken')
}
if (localStorage.getItem('refreshToken')) {
  store.state.auth.refreshToken = localStorage.getItem('refreshToken')
}

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
Vue.config.productionTip = false
Vue.use(VueRouter)
Vue.use(Viewer, {
  defaultOptions: {
    title: false,
    rotatable: false,
    navbar: false
  }
})

const app = new Vue({
  router,
  store,
  render: (h) => h(App),
  mounted () {
    document.dispatchEvent(new Event('render-event'))
  }
})

Vue.initI18nManager().then(() => {
  app.$mount('#app')
})
