<template>
  <div class="not-found">
    <div class="container">
      <div class="not-found__wrap">
        <div class="not-found__title">
          {{ $t('pageNotFoundMessage') }} :(
        </div>
        <div class="not-found__image">
          <img src="../../assets/img/home/404.png" alt="">
        </div>
        <div class="not-found__link">
          <router-link :to="$localize({ name: 'home' })">
            {{ $t('backToHomePage') }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageNotFound'
}
</script>

<style lang="scss" scoped>
.not-found {
  position: relative;
  padding: 80px 0;
  background: rgb(0,0,0);
  background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(24,173,100,1) 100%);
  @include respondTo(800px){
    padding: 60px 0;
  }
  &__title {
    text-align: center;
    max-width: 490px;
    font-size: 34px;
    color: $white;
    line-height: 1.6;
    margin: 0 auto 50px;
    @include respondTo(800px){
      max-width: none;
      padding: 0 15px;
      font-size: 20px;
      margin: 0 auto 30px;
    }
  }
  &__image {
    text-align: center;
    img{
      margin: 0 auto;
      width: 550px;
      height: auto;
      object-fit: cover;
      @include respondTo(800px){
        width: 260px;
      }
      @include respondTo(450px){
        width: 100%;
        padding: 0 15px;
      }
    }
  }
  &__link{
    margin-top: 50px;
    text-align: center;
    a{
      display: inline-block;
      margin: 0 auto;
      text-align: center;
      padding: 15px 30px;
      color: $white;
      font-size: 14px;
      line-height: 1;
      border-radius: 15px;
      border: 1px solid $white;
      background: transparent;
      transition: background $trans, color $trans;
      text-decoration: none;
      text-transform: uppercase;
      @include respondTo(800px){
        padding: 10px 20px;
        font-size: 12px;
      }
      &:hover {
        background: $white;
        color: $green;
      }
    }
  }
}
</style>
