<template>
  <div v-if="src" :class="[$style.container, 'gl-media-preview']">
    <div v-if="isCloseBtn"
         :class="$style.closeWrap"
         @click="$emit('close')">
      <svg fill="#fff" width="12px" height="12px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 241.171 241.171"
           class="custom-icon MediaPreview_close_AJiDQ">
        <path
            d="M138.138 120.754l99.118-98.576a11.931 11.931 0 000-17.011c-4.74-4.704-12.439-4.704-17.179 0l-99.033 98.492-99.949-99.96c-4.74-4.752-12.439-4.752-17.179 0-4.74 4.764-4.74 12.475 0 17.227l99.876 99.888L3.555 220.497c-4.74 4.704-4.74 12.319 0 17.011 4.74 4.704 12.439 4.704 17.179 0l100.152-99.599 99.551 99.563c4.74 4.752 12.439 4.752 17.179 0 4.74-4.764 4.74-12.475 0-17.227l-99.478-99.491z"></path>
      </svg>
    </div>
    <img
      v-if="!isVideo"
      :src="src"
      alt="preview"
      :class="$style.image"
    >
    <template v-else>
      <video
        :src="src"
        preload="metadata"
        :class="$style.image"
        @click="openVideo"
      ></video>
    </template>
  </div>
</template>

<script>
export default {
  name: 'media-preview',
  props: {
    src: String,
    isVideo: Boolean,
    isCloseBtn: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    openVideo () {
      this.$modal.show('video', { src: this.src })
    }
  }
}
</script>

<style module lang="scss">
.container {
  position: relative;
  max-width: 200px;
  height: 150px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    .closeWrap {
      opacity: 1;
    }
  }
}
.closeWrap {
  opacity: 0;
  position: absolute;
  top: 2px;
  right: 2px;
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: rgba($main-bg-darkest, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 1;
  &:hover {
    background-color: rgba($main-bg-darkest, 1);
  }
}
.close {
  font-size: 12px;
  color: $text-lightest;
}
.image {
  max-width: 100%;
  max-height: 100%;
}
.play {
  font-size: 34px;
  color: $text-lightest;
  position: absolute;
  top: calc(50% - 17px);
  left: calc(50% - 17px);
}
</style>
