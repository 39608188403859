var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"phone-input"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!!_vm.label),expression:"!!label"}],staticClass:"phone-input__label"},[_vm._v(" "+_vm._s(_vm.label)+" ")]),_c('vue-tel-input',{attrs:{"inputOptions":{
        placeholder: _vm.$t('enterPhone'),
        autocomplete: true,
        type: 'tel'
      },"dropdownOptions":{
        showDialCodeInSelection: true,
        showFlags: true,
        showDialCodeInList: true
      },"ignoredCountries":['sn']},on:{"input":_vm.changePhoneInput}}),(_vm.errors && _vm.errors.length)?_c('div',{staticClass:"form-errors"},_vm._l((_vm.errors),function(error){return _c('span',{key:error,staticClass:"form-errors__item"},[_vm._v(" "+_vm._s(error)+" ")])}),0):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }