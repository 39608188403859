<template>
  <div :class="$style.container">
    <h1 :class="$style.title">{{ title }}</h1>

    <div>
      <div :class="$style.images">
        <p>{{ $t('profilePicture') }}:</p>
        <FileInput
            v-if="!pictureSrc"
            :withDragAndDrop="true"
            accept="image/*"
            @input="onPictureFileChange"
            :class="$style.file">
        </FileInput>
        <MediaPreview
            :src="pictureSrc"
            @close="pictureSrc = null"
            v-viewer
        />
      </div>
      <div :class="$style.images">
        <p>{{ $t('backgroundPicture') }}:</p>
        <FileInput
            v-if="!backgroundSrc"
            :withDragAndDrop="true"
            accept="image/*"
            @input="onBackgroundFileChange"
            :class="$style.file">
        </FileInput>
        <MediaPreview
            :src="backgroundSrc"
            @close="backgroundSrc = null"
            v-viewer
        />
      </div>
    </div>
    <div class="profile-setting">
      <div class="profile-setting__title">
        {{ $t('profileSettings') }}
      </div>
      <div class="profile-setting__form">
        <div class="profile-setting__row">
          <div class="profile-setting__col">
            <BaseInput
                v-model="firstName"
                :label="$t('socialProfile.firstName')"
                :invalid="$v.firstName.$error"
                :error="firstNameError"
                @blur="$v.firstName.$touch"
            />
          </div>
          <div class="profile-setting__col">
            <BaseInput
                v-model="lastName"
                :label="$t('socialProfile.lastName')"
                :invalid="$v.lastName.$error"
                :error="lastNameError"
                @blur="$v.lastName.$touch"
            />
          </div>
        </div>
        <div class="profile-setting__row">
          <div class="profile-setting__col">
            <BaseInput
                v-model="nickname"
                :label="$t('socialProfile.nickname')"
                :invalid="$v.nickname.$error"
                :error="nicknameError"
                @blur="$v.nickname.$touch"
                @input="changeInputUsername"
            />
          </div>
          <div class="profile-setting__col">
            <BaseInput
                v-model="textLocation"
                :label="$t('socialProfile.textLocation')"
                :invalid="$v.textLocation.$error"
                :error="textLocationError"
                @blur="$v.textLocation.$touch"
            />
          </div>
        </div>
        <div class="profile-setting__row">
          <BaseSelect
              v-model="category"
              @input="setCategory"
              :label="$t('category')"
              :placeholder="$t('chooseCategory')"
              :options="categories"
              trackBy="value"
              optionLabel="label"
              :show-error="isShowSelectError"
          />
        </div>
      </div>
    </div>
    <div class="profile-extended">
      <div class="profile-extended__title">
        <div :class="$style.extended">{{ $t('socialProfile.extendedSettings') }}</div>
      </div>
      <div class="profile-extended__form">
        <div class="profile-extended__row">
          <div class="profile-extended__col">
            <div :class="$style.extendedBlock">
              <div :class="$style.label">{{ $t('socialProfile.showSocialSpace') }}</div>
              <BaseSwitch v-model="isActive" :class="$style.switchMargin">{{$t('socialProfile.isActive')}}</BaseSwitch>
              <BaseSwitch v-model="isPublic">{{ $t('socialProfile.isPublic') }}</BaseSwitch>
              <BaseSwitch v-model="isChatActive">{{ $t('socialProfile.isChatActive') }}</BaseSwitch>
            </div>
          </div>
          <div class="profile-extended__col">
            <div :class="$style.extendedBlock">
              <div :class="$style.label">{{ $t('socialProfile.profileViewOptions') }}</div>
              <BaseSwitch v-model="showFollowers" :class="$style.switchMargin">{{$t('socialProfile.showFollowers')}}</BaseSwitch>
              <BaseSwitch v-model="showCountPosts">{{ $t('socialProfile.showCountPosts') }}</BaseSwitch>
              <BaseSwitch v-model="showCountFollowing">{{ $t('socialProfile.showCountFollowing') }}</BaseSwitch>
            </div>
          </div>
        </div>
      </div>
    </div>

    <p v-if="$v.pictureFile.$error" class="form--error">{{ $t('socialProfile.pictureError') }}</p>

    <BaseButton
        oval
        :loading="loading || createProfileLoading || checkNicknameLoading"
        @click="onSubmit"
        class="form--action">
      {{ buttonText }}
    </BaseButton>
  </div>
</template>

<script>
import { required, maxLength, helpers } from 'vuelidate/lib/validators'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import FileInput from '@/components/atoms/FileInput.vue'
import BaseInput from '@/components/atoms/BaseInput.vue'
import BaseButton from '@/components/atoms/_FormButton.vue'
import BaseSwitch from '@/components/atoms/_BaseSwitch.vue'
import BaseSelect from '@/components/atoms/_BaseSelect.vue'
import imageCompression from 'browser-image-compression'
import MediaPreview from '@/components/atoms/MediaPreview.vue'
import api from '@/api'
import { debounce } from 'lodash'

const alphaNumHyphenUnderscoreValidator = helpers.regex('alphaNumAndDot', /^[-a-zA-Z0-9_]+$/i)

const NAMES_MAX_LENGTH = 20
const NICKNAME_MAX_LENGTH = 20
const LOCATION_MAX_LENGTH = 50

export default {
  name: 'social-create-profile',
  components: {
    FileInput,
    BaseInput,
    BaseSwitch,
    BaseButton,
    BaseSelect,
    MediaPreview
  },
  data () {
    return {
      pictureSrc: null,
      backgroundSrc: null,
      firstName: '',
      lastName: '',
      nickname: '',
      textLocation: '',
      category: {
        label: this.$t('socialProfile.categoryIndividual'),
        value: 'Individual'
      },
      isActive: false,
      isPublic: false,
      showFollowers: true,
      isChatActive: true,
      loading: false,
      pictureFile: null,
      backgroundFile: null,
      isShowSelectError: false,
      errorUniqNickname: false,
      showCountPosts: true,
      showCountFollowing: true,
      checkNicknameLoading: false
    }
  },
  computed: {
    ...mapGetters({
      createProfileLoading: 'profile/createProfileLoading'
    }),
    title () {
      return this.$t('socialProfile.createTitle')
    },
    buttonText () {
      return this.$t('buttonCreate')
    },
    categories () {
      return [
        {
          label: this.$t('socialProfile.categoryIndividual'),
          value: 'Individual'
        },
        {
          label: this.$t('socialProfile.categoryCompany'),
          value: 'Company'
        }
      ]
    },
    firstNameError () {
      if (!this.$v.firstName.maxLength) {
        return `${this.$t('errorMaxLength_begin')} ${NAMES_MAX_LENGTH} ${this.$t('errorLength_end')}`
      }
      return this.$t('errorEmptyField')
    },
    lastNameError () {
      if (!this.$v.lastName.maxLength) {
        return `${this.$t('errorMaxLength_begin')} ${NAMES_MAX_LENGTH} ${this.$t('errorLength_end')}`
      }
      return ''
    },
    nicknameError () {
      if (!this.$v.nickname.required) {
        return this.$t('errorEmptyField')
      } else if (!this.$v.nickname.maxLength) {
        return `${this.$t('errorMaxLength_begin')} ${NICKNAME_MAX_LENGTH} ${this.$t('errorLength_end')}`
      } else if (!this.$v.nickname.alphaNumHyphenUnderscoreValidator) {
        return this.$t('errorAlphanumericDashUnderscore')
      } else if (!this.$v.nickname.uniq) {
        return this.$t('socialProfile.errorNicknameExists')
      }
      return this.$t('errorEmptyField')
    },
    textLocationError () {
      if (!this.$v.textLocation.maxLength) {
        return `${this.$t('errorMaxLength_begin')} ${LOCATION_MAX_LENGTH} ${this.$t('errorLength_end')}`
      }
      return this.$t('errorEmptyField')
    }
  },
  validations: {
    firstName: { required, maxLength: maxLength(NAMES_MAX_LENGTH) },
    lastName: { maxLength: maxLength(NAMES_MAX_LENGTH) },
    nickname: {
      required,
      maxLength: maxLength(NICKNAME_MAX_LENGTH),
      alphaNumHyphenUnderscoreValidator,
      uniq () {
        return !this.errorUniqNickname
      }
    },
    textLocation: { required, maxLength: maxLength(LOCATION_MAX_LENGTH) },
    pictureFile: { required }
  },
  methods: {
    ...mapMutations({
      clearProfilesData: 'user/clearProfilesData'
    }),
    ...mapActions({
      createProfile: 'profile/createProfile',
      fetchMyProfiles: 'user/fetchMyProfiles',
      checkUsername: 'user/checkUsername'
    }),
    setCategory () {
      this.isShowSelectError = !this.category
    },
    changeInputUsername () {
      this.nickname = this.nickname.toLowerCase()
      if (this.nickname.length) {
        this.checkNicknameLoading = true
        this.changeValidationUserName()
      }
    },
    changeValidationUserName: debounce(function () {
      this.checkUsername(this.nickname).then((response) => {
        this.errorUniqNickname = response.info !== 'Nickname is unique'
      }).finally(() => {
        this.checkNicknameLoading = false
        this.$v.nickname.$touch()
      })
    }, 500),
    async onSubmit () {
      this.$v.$touch()
      if (this.loading || this.$v.$invalid || this.isShowSelectError) return
      const data = {
        firstName: this.firstName,
        lastName: this.lastName,
        nickname: this.nickname,
        textLocation: this.textLocation,
        category: this.category.value,
        isActive: this.isActive,
        isPublic: this.isPublic,
        showFollowers: this.showFollowers,
        isChatActive: this.isChatActive,
        picture: this.pictureFile,
        showCountPosts: this.showCountPosts,
        showCountFollowing: this.showCountFollowing
      }
      const location = await api.location.coordinatesByAddress(this.textLocation)
      data.location = JSON.stringify(location)
      if (this.backgroundFile) {
        data.background = this.backgroundFile
      }
      this.loading = true
      try {
        await this.createProfile(data).then(() => {
          this.$toasted.success(this.$t('socialProfile.successCreateProfile'))
          this.fetchMyProfiles({ limit: 20, offset: 0 }).then(() => {
            this.$router.push({ name: 'home', params: { profileNickname: this.nickname } }).catch(() => {
            })
          })
        })
      } catch (e) {
        const { nickname } = e.response.data
        if (nickname) {
          this.errorUniqNickname = true
        }
      } finally {
        this.loading = false
      }
    },
    async onPictureFileChange (file) {
      this.$modal.show('cropper', {
        aspectRatio: 1,
        file,
        onDone: async canvas => {
          this.pictureSrc = canvas.toDataURL()
          const blob = await imageCompression.getFilefromDataUrl(this.pictureSrc, 'social-profile-picture')
          const compressedBlob = await imageCompression(blob, {
            maxSizeMB: 1
          })
          this.pictureFile = new File([compressedBlob], `${compressedBlob.name}.${compressedBlob.type.split('/')[1]}`)
        }
      })
    },
    async onBackgroundFileChange (file) {
      this.$modal.show('cropper', {
        aspectRatio: 16 / 9,
        file,
        onDone: async canvas => {
          this.backgroundSrc = canvas.toDataURL()
          const blob = await imageCompression.getFilefromDataUrl(this.backgroundSrc, 'social-profile-background')
          const compressedBlob = await imageCompression(blob, {
            maxSizeMB: 1
          })
          this.backgroundFile = new File([compressedBlob], `${compressedBlob.name}.${compressedBlob.type.split('/')[1]}`)
        }
      })
    }
  }
}
</script>

<style module lang="scss">
.container {
  margin: 40px auto;
  padding: 0 25px;
  max-width: 800px;
  .button {
    margin: 0 auto;
  }
}
.title {
  font-size: 18px;
  font-weight: 500;
  color: $text-success;
  margin: 0;
  margin-bottom: 16px;
  text-align: center;
}
.images{
  width: 100%;
  margin-bottom: 30px;
  label{
    display: block;
  }
  div {
    text-align: center;
    margin: 0 auto;
  }
  p{
    font-size: 16px;
    color: $black;
    margin-bottom: 10px;
  }
}
.picture {
  width: 110px;
  height: 110px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  &Error {
    border-color: $text-error;
  }
}
.background {
  width: 240px;
  height: 110px;
  border-radius: 5px;
  top: -65px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  position: relative;
  svg {
    position: absolute;
    right: 10px;
    bottom: 10px;
  }
}
.image {
  width: 100%;
}
.pencilIcon {
  font-size: 16px;
  color: $text-light;
  position: absolute;
  transition: all 0.7s;
}
.pencilIconPicture {
  position: absolute;
  top: 8px;
}
.pencilIconBackground {
  position: absolute;
  bottom: 8px;
  right: 8px;
}
.extendedBlock {
  padding: 15px 0;
  & > div {
    font-size: 16px;
  }
  input {
    display: none;
  }
  label {
    padding: 10px 0;
    cursor: pointer;
  }
}
.label {
  color: $text-colored;
  font-weight: 500;
  font-size: 12px;
  margin-bottom: 4px;
}
.switchMargin {
  margin-bottom: 2px;
}
</style>
<style lang="scss" scoped>
.form-button {
  margin-top: 20px;
}
.profile-setting,
.profile-extended {
  padding: 30px 15px;
  background: #eceff0;
  &__title {
    font-size: 18px;
    font-weight: 500;
    color: #00b764;
    margin: 0;
    margin-bottom: 16px;
    text-align: center;
  }
  &__row {
    display: flex;
    flex-direction: row;
    width: 100%;
    @include respondTo(600px) {
      flex-direction: column;
    }
    div:first-child {
      padding-right: 15px;
      @include respondTo(600px) {
        padding-right: 0
      }
    }
    div:last-child {
      padding-left: 15px;
      @include respondTo(600px) {
        padding-left: 0
      }
    }
    div.select-container {
      width: 100%;
      padding-left: 0;
      padding-right: 0;
    }
  }
  &__col {
    width: 50%;
    @include respondTo(600px) {
      width: 100%;
    }
  }
}
.profile-extended {
  margin: 30px 0 0;
}
.form--action {
  margin: 30px auto 0;
}
</style>
