<template>
  <div class="comment-wrap">
    <div class="comment-wrap__form">
      <BaseInput
          v-model="text"
          :placeholder="placeholder"
          multiline rows="2"
          @keydown.enter.exact.prevent
          @keyup.enter.exact="onSubmit" />
    </div>
    <div class="comment-wrap__buttons">
      <button
          class="comment-wrap__buttons-close"
          @click="$emit('close')">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 241.171 241.171"
             class="custom-icon SocialCommentInput_closeBtn_hDwg6">
          <path
              d="M138.138 120.754l99.118-98.576a11.931 11.931 0 000-17.011c-4.74-4.704-12.439-4.704-17.179 0l-99.033 98.492-99.949-99.96c-4.74-4.752-12.439-4.752-17.179 0-4.74 4.764-4.74 12.475 0 17.227l99.876 99.888L3.555 220.497c-4.74 4.704-4.74 12.319 0 17.011 4.74 4.704 12.439 4.704 17.179 0l100.152-99.599 99.551 99.563c4.74 4.752 12.439 4.752 17.179 0 4.74-4.764 4.74-12.475 0-17.227l-99.478-99.491z"></path>
        </svg>
      </button>
      <button
          class="comment-wrap__buttons-send"
          @click="onSubmit">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45.923 45.923"
             class="custom-icon SocialCommentInput_sendBtn_3WESU">
          <path
              d="M44.041 17.019L3.785.191a2.47 2.47 0 00-3.27 3.123l14.962 40.987a2.467 2.467 0 004.574.154l6.841-15.436a2.472 2.472 0 011.359-1.301l15.733-6.125a2.466 2.466 0 00.057-4.574z"></path>
        </svg>
      </button>
    </div>
  </div>
</template>

<!-- scripts-->
<script>
import { mapActions } from 'vuex'
import BaseInput from '@/components/atoms/BaseInput.vue'

export default {
  name: 'comment-input',
  props: {
    postId: {
      type: Number,
      required: true
    },
    commentId: Number,
    replyTo: Object,
    editedComment: Object,
    isFetchReplies: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      text: ''
    }
  },
  components: {
    BaseInput
  },
  computed: {
    placeholder () {
      if (this.replyTo) {
        return this.$t('socialPost.placeholderReply', { name: this.replyTo.nickname })
      }
      return this.$t('socialPost.placeholderComment')
    }
  },
  created () {
    if (this.editedComment) {
      this.text = this.editedComment.text
    }
  },
  methods: {
    ...mapActions({
      send: 'profile/sendComment',
      edit: 'profile/editComment',
      fetchPostComments: 'profile/fetchPostComments',
      fetchReplies: 'profile/fetchReplies'
    }),
    async onSubmit () {
      if (!this.text) return
      const data = {
        text: this.text
      }
      if (this.replyTo) {
        data.replyTo = this.replyTo.id
      }
      if (this.commentId) {
        data.commentId = this.commentId
      }
      if (!this.editedComment) {
        await this.send({
          postId: this.postId,
          data
        })
      } else {
        await this.edit({
          postId: this.postId,
          id: this.editedComment.id,
          text: this.text,
          isReplyComment: this.editedComment.isReplyComment,
          commentId: this.commentId
        })
      }
      this.$emit('close')
    }
  }
}
</script>

<!-- style-->
<style lang="scss" scoped>
.comment-wrap{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 15px;
  padding: 10px 20px;
  @include respondTo(600px){
    padding: 10px;
  }
  &__form{
    width: calc(100% - 55px);
    margin-right: 5px;
  }
  &__buttons{
    width: 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    button {
      margin: 0;
      padding: 0;
      svg{
        width: 100%;
        height: 100%;
      }
    }
    &-close{
      width: 12px;
      height: 12px;
    }
    &-send{
      width: 22px;
      height: 22px;
      svg {
        transform: rotate(135deg);
      }
      svg path{
        fill: #00b764;
      }
    }
  }
}
</style>
