<template>
  <stack
    :column-min-width="200"
    :gutter-width="20"
    :gutter-height="20"
    monitor-images-loaded>
    <stack-item v-for="(item,index) in list"
                :key="item.id"
                :class="{ 'odd': index % 2 === 0 }"
                class="trending-item">
      <TrendingListItem :item="item" />
    </stack-item>
  </stack>
</template>

<script>
import { Stack, StackItem } from 'vue-stack-grid'
import TrendingListItem from '../components/TrendingListItem.vue'
export default {
  name: 'TrendingStackItem',
  props: {
    list: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: []
    }
  },
  components: {
    Stack,
    StackItem,
    TrendingListItem
  }
}
</script>

<style lang="scss" scoped>
.trending{
  &-item{
    flex: 0 0 20%;
    max-width: 20%;
    height: 290px;
    &.odd{
      height: 320px;
    }
    @include respondTo(890px){
      padding: 5px;
      flex: 0 0 33.33%;
      max-width: 33.33%;
    }
    @include respondTo(700px){
      padding: 5px;
      flex: 0 0 50%;
      max-width: 50%;
    }
    @include respondTo(440px){
      padding: 5px;
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}
</style>
