<template>
          <div class="profile-mood">
            <div class="profile-mood__logo">
              <img src="../../../assets/img/home/mood-yellow-logo.png" alt="">
            </div>
            <div class="profile-mood__list-wrap" ref="scroll">
              <button v-if="isShowScrollButton" class="btn-scroll btn-scroll__left" ref="scroll-left" @click.prevent="scrollHorizontally(1)">
                <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="chevron-left" role="img"
                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"
                     class="svg-inline--fa fa-chevron-left fa-w-8 fa-2x">
                  <path fill="currentColor"
                        d="M238.475 475.535l7.071-7.07c4.686-4.686 4.686-12.284 0-16.971L50.053 256 245.546 60.506c4.686-4.686 4.686-12.284 0-16.971l-7.071-7.07c-4.686-4.686-12.284-4.686-16.97 0L10.454 247.515c-4.686 4.686-4.686 12.284 0 16.971l211.051 211.05c4.686 4.686 12.284 4.686 16.97-.001z"
                        class=""></path>
                </svg>
              </button>
              <button v-if="isShowScrollButton" class="btn-scroll btn-scroll__right" ref="scroll-right" @click.prevent="scrollHorizontally(-1)">
                <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="chevron-right" role="img"
                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"
                     class="svg-inline--fa fa-chevron-right fa-w-8 fa-2x">
                  <path fill="currentColor"
                        d="M17.525 36.465l-7.071 7.07c-4.686 4.686-4.686 12.284 0 16.971L205.947 256 10.454 451.494c-4.686 4.686-4.686 12.284 0 16.971l7.071 7.07c4.686 4.686 12.284 4.686 16.97 0l211.051-211.05c4.686-4.686 4.686-12.284 0-16.971L34.495 36.465c-4.686-4.687-12.284-4.687-16.97 0z"
                        class=""></path>
                </svg>
              </button>
              <div class="profile-mood__list" ref="container">
                <div class="profile-mood__item user-mood">
                  <div class="profile-mood__item-logo">
                    <img src="../../../assets/img/home/default-mood.png" alt="">
                  </div>
                  <div class="profile-mood__item-nickname">
                    Your Mood
                  </div>
                </div>
                <div class="profile-mood__item">
                  <div class="profile-mood__item-logo">
                    <img src="../../../assets/img/home/default-mood.png" alt="">
                  </div>
                  <div class="profile-mood__item-nickname">
                    Romio95
                  </div>
                </div>
                <div class="profile-mood__item">
                  <div class="profile-mood__item-logo">
                    <img src="../../../assets/img/home/default-mood.png" alt="">
                  </div>
                  <div class="profile-mood__item-nickname">
                    Roma
                  </div>
                </div>
                <div class="profile-mood__item">
                  <div class="profile-mood__item-logo">
                    <img src="../../../assets/img/home/default-mood.png" alt="">
                  </div>
                  <div class="profile-mood__item-nickname">
                    Roma
                  </div>
                </div>
                <div class="profile-mood__item">
                  <div class="profile-mood__item-logo">
                    <img src="../../../assets/img/home/default-mood.png" alt="">
                  </div>
                  <div class="profile-mood__item-nickname">
                    Roma
                  </div>
                </div>
                <div class="profile-mood__item">
                  <div class="profile-mood__item-logo">
                    <img src="../../../assets/img/home/default-mood.png" alt="">
                  </div>
                  <div class="profile-mood__item-nickname">
                    Roma
                  </div>
                </div>
                <div class="profile-mood__item">
                  <div class="profile-mood__item-logo">
                    <img src="../../../assets/img/home/default-mood.png" alt="">
                  </div>
                  <div class="profile-mood__item-nickname">
                    Roma
                  </div>
                </div>
                <div class="profile-mood__item">
                  <div class="profile-mood__item-logo">
                    <img src="../../../assets/img/home/default-mood.png" alt="">
                  </div>
                  <div class="profile-mood__item-nickname">
                    Roma
                  </div>
                </div>
                <div class="profile-mood__item">
                  <div class="profile-mood__item-logo">
                    <img src="../../../assets/img/home/default-mood.png" alt="">
                  </div>
                  <div class="profile-mood__item-nickname">
                    Roma
                  </div>
                </div>
                <div class="profile-mood__item">
                  <div class="profile-mood__item-logo">
                    <img src="../../../assets/img/home/default-mood.png" alt="">
                  </div>
                  <div class="profile-mood__item-nickname">
                    Roma
                  </div>
                </div>
                <div class="profile-mood__item">
                  <div class="profile-mood__item-logo">
                    <img src="../../../assets/img/home/default-mood.png" alt="">
                  </div>
                  <div class="profile-mood__item-nickname">
                    Roma
                  </div>
                </div>
                <div class="profile-mood__item">
                  <div class="profile-mood__item-logo">
                    <img src="../../../assets/img/home/default-mood.png" alt="">
                  </div>
                  <div class="profile-mood__item-nickname">
                    Roma
                  </div>
                </div>
                <div class="profile-mood__item">
                  <div class="profile-mood__item-logo">
                    <img src="../../../assets/img/home/default-mood.png" alt="">
                  </div>
                  <div class="profile-mood__item-nickname">
                    Roma
                  </div>
                </div>
                <div class="profile-mood__item">
                  <div class="profile-mood__item-logo">
                    <img src="../../../assets/img/home/default-mood.png" alt="">
                  </div>
                  <div class="profile-mood__item-nickname">
                    Roma
                  </div>
                </div>
                <div class="profile-mood__item">
                  <div class="profile-mood__item-logo">
                    <img src="../../../assets/img/home/default-mood.png" alt="">
                  </div>
                  <div class="profile-mood__item-nickname">
                    Roma
                  </div>
                </div>
                <div class="profile-mood__item">
                  <div class="profile-mood__item-logo">
                    <img src="../../../assets/img/home/default-mood.png" alt="">
                  </div>
                  <div class="profile-mood__item-nickname">
                    Roma
                  </div>
                </div>
                <div class="profile-mood__item">
                  <div class="profile-mood__item-logo">
                    <img src="../../../assets/img/home/default-mood.png" alt="">
                  </div>
                  <div class="profile-mood__item-nickname">
                    Roma
                  </div>
                </div>
              </div>
            </div>
          </div>
</template>

<!-- scripts-->
<script>
export default {
  name: 'profile-mood',
  data () {
    return {
      isShowScrollButton: false,
      currentScrollPosition: 0,
      scrollAmount: 250
    }
  },
  computed: {
  },
  mounted () {
    const sCont = this.$refs.container
    const hScroll = this.$refs.scroll
    if (sCont.offsetWidth > hScroll.offsetWidth) {
      this.isShowScrollButton = true
    }
  },
  methods: {
    scrollHorizontally (val) {
      const sCont = this.$refs.container
      const hScroll = this.$refs.scroll

      const maxScroll = -sCont.offsetWidth + hScroll.offsetWidth
      const leftButton = this.$refs['scroll-left']
      const rightButton = this.$refs['scroll-right']

      leftButton.style.opacity = '0'

      this.currentScrollPosition += (val * this.scrollAmount)

      if (this.currentScrollPosition >= 0) {
        this.currentScrollPosition = 0
        leftButton.style.opacity = '0'
      } else {
        leftButton.style.opacity = '1'
      }

      if (this.currentScrollPosition <= maxScroll) {
        this.currentScrollPosition = maxScroll
        rightButton.style.opacity = '0'
      } else {
        rightButton.style.opacity = '1'
      }

      sCont.style.left = this.currentScrollPosition + 'px'
    }
  }
}
</script>

<!-- style-->
<style lang="scss" scoped>
.profile {
  &-mood{
    margin: 30px 0 50px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    &__logo{
      margin-right: 30px;
      width: 250px;
      img{
        width: 100%;
        height: auto;
      }
    }
    &__list{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-start;
      position: absolute;
      left: 0;
      transition: left $trans;

      &-wrap {
        width: calc(100% - 280px);
        height: 90px;
        position: relative;
        overflow: hidden;
        border-radius: 5px;
        background: #ffffff;
        .btn-scroll{
          background: $white;
          width: 30px;
          height: 30px;
          color: #999;
          box-shadow: 0 0 10px #999999;
          border: 0;
          border-radius: 50%;
          z-index: 1;
          cursor: pointer;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          transition: opacity $trans;
          padding: 0;
          margin: 0;
          svg{
            vertical-align: middle;
            width: 9px;
          }
          &__left{
            left: 5px;
            opacity: 0;
          }
          &__right{
            right: 5px;
          }
        }
      }
    }
    &__item{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      overflow: hidden;
      cursor: pointer;
      &:not(:last-child){
        margin-right: 35px;
      }
      &-logo{
        width: 65px;
        height: 65px;
        border-radius: 50%;
        position: relative;
        margin-bottom: 10px;
        background: linear-gradient(rgb(231,0,231),rgb(255,115, 1));
        img{
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 50%;
          border: 2px solid $white;
        }
      }
      &-nickname{
        font-size: 14px;
        color: #7e7e7e;
        line-height: 1;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
    .user-mood{
      .profile-mood__item-logo:after{
        content: '';
        position: absolute;
        display: block;
        right: 0;
        bottom: 0;
        width: 20px;
        height: 20px;
        background: url('../../../assets/img/home/add-mood-icon.png') center center no-repeat;
        background-size: cover;
      }
    }
  }
}
</style>
