import { mapGetters, mapActions, mapMutations } from 'vuex'
import PostList from './components/PostList.vue'
import ProfileMood from './components/ProfileMood.vue'
import Loader from '@/components/atoms/Spinner.vue'
import BaseButton from '@/components/atoms/BaseButton.vue'

export default {
  name: 'profile',
  data () {
    return {}
  },
  components: {
    PostList,
    ProfileMood,
    Loader,
    BaseButton
  },
  computed: {
    ...mapGetters({
      profile: 'profile/profileData',
      profilePosts: 'profile/profilePosts',
      profileDataLoading: 'profile/profileDataLoading',
      profilePostsLoading: 'profile/profilePostsLoading',
      subscribeLoading: 'profile/subscribeLoading',
      unSubscribeLoading: 'profile/unSubscribeLoading'
    }),
    isShowPostList () {
      const { type, subscribeStatus, isOwn } = this.profile
      if (isOwn) {
        return true
      } else {
        return type !== 'LOOKING_FOR' || (subscribeStatus !== null && subscribeStatus !== 3) || this.profile.isPublic
      }
    },
    nickname () {
      return this.$route.params.nickname
    }
  },
  watch: {
    '$route.path' () {
      this.clearCommentsList(true)
      this.clearPostsList(true)
      // for change profile without reload page (search etc.)
      this.getProfileData(this.nickname).catch((e) => {
        this.$router.push({ name: 'home' })
      })
    }
  },
  created () {
    this.clearCommentsList(true)
    this.clearPostsList(true)
    if (this.nickname === 'trending') {
      this.$router.push({ name: 'trending' })
      return
    }
    if (this.nickname === 'favourites') {
      this.$router.push({ name: 'favourites' })
      return
    }
    if (this.nickname === 'likes') {
      this.$router.push({ name: 'likes' })
      return
    }
    if (this.nickname === 'page-not-found') {
      this.$router.push({ name: 'page-not-found' })
      return
    }
    if (this.nickname === 'create-profile') {
      this.$router.push({ name: 'social-create-profile' })
      return
    }
    if (this.nickname && this.nickname !== 'home') {
      console.log('FETCH PROFILE AND PUSH TO PROFILE')
      this.getProfileData(this.nickname).catch(() => {
        this.$router.push({ name: 'profile' })
      })
    } else {
      console.log('FETCH PROFILE AND PUSH TO HOME')
      this.$router.push({ name: 'home' })
    }
  },
  methods: {
    ...mapActions({
      getProfileData: 'profile/getProfileData',
      subscribe: 'profile/subscribe',
      unsubscribe: 'profile/unsubscribe'
    }),
    ...mapMutations({
      clearCommentsList: 'profile/clearCommentsList',
      clearPostsList: 'profile/clearPostsList'
    }),
    onFollow (nickname) {
      this.subscribe(nickname)
    },
    onUnfollow (nickname) {
      this.unsubscribe(nickname)
    },
    nFormatter (num, digits) {
      const lookup = [
        { value: 1, symbol: '' },
        { value: 1e3, symbol: 'k' },
        { value: 1e6, symbol: 'M' },
        { value: 1e9, symbol: 'G' },
        { value: 1e12, symbol: 'T' },
        { value: 1e15, symbol: 'P' },
        { value: 1e18, symbol: 'E' }
      ]
      const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
      const item = lookup.slice().reverse().find(function (item) {
        return num >= item.value
      })
      return item ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol : '0'
    }
  },
  destroyed () {
    this.clearCommentsList(true)
    this.clearPostsList(true)
  }
}
