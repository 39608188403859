<template>
  <SecondaryModal
    :name="$options.name"
    :clickToClose="false"
    @before-open="openPopup"
    @before-close="beforeClose"
    @closed="resetIntervals"
    class="qr-popup">
    <div slot="default"
         class="qr-popup__wrap">
      <template>
        <div class="qr-popup__container">
          <div class="qr-popup__code">
            <qr-code :size="150" :text="qrCode"/>
          </div>
          <div class="qr-popup__label">
            {{ $t('loginByQRCodeMessage') }}
          </div>
          <div class="qr-popup__footer">
           <MobileAppLinks />
          </div>
        </div>
      </template>
    </div>
  </SecondaryModal>
</template>

<script>
import SecondaryModal from './SecondaryModal'
import { mapActions } from 'vuex'
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import MobileAppLinks from '@/components/atoms/MobileAppLinks.vue'

const COUNTDOWN_TIME = 90
let timerTicker = null
let IntervalLongFunction = null
let IntervalShortFunction = null

export default {
  name: 'login-by-code',
  components: {
    SecondaryModal,
    MobileAppLinks
  },
  data () {
    return {
      fingerPrint: '',
      qrCode: '',
      iteration: 1,
      isGenerateNewQRCode: false
    }
  },
  methods: {
    ...mapActions({
      getSocialInfo: 'auth/getSocialInfo',
      authByCode: 'auth/authByCode',
      sendQRCode: 'auth/sendQRCode'
    }),
    openPopup () {
      this.iteration = 1
      this.isGenerateNewQRCode = false
      this.setNewQRCode()
      this.generateFingerPrint()
      this.startIterations()
      this.startProcessRequests()
      this.startTimer()
    },
    startTimer () {
      let minutes = Math.floor(COUNTDOWN_TIME / 60)
      let seconds = COUNTDOWN_TIME % 60
      let currentVal = COUNTDOWN_TIME
      timerTicker = setInterval(() => {
        if (!currentVal) {
          clearInterval(timerTicker)
          return
        }
        currentVal--
        seconds = currentVal % 60
        minutes = Math.floor(currentVal / 60)
        if (seconds === 0 && minutes === 0) {
          this.$modal.hide(this.$options.name)
        }
      }, 1000)
    },
    generateFingerPrint () {
      const fpPromise = FingerprintJS.load()
      fpPromise.then(fp => fp.get().then(result => {
        this.fingerPrint = 'holanew_' + result.visitorId
      }))
    },
    startProcessRequests () {
      IntervalLongFunction = setInterval(() => {
        this.startIterations()
      }, 10000)
    },
    startIterations () {
      IntervalShortFunction = setInterval(() => {
        this.startSendingRequests()
      }, 1000)
    },
    startSendingRequests () {
      if (this.iteration === 1 && !this.isGenerateNewQRCode) {
        this.sendRequestAction(this.qrCode)
        this.iteration = this.iteration + 1
      } else if (this.iteration === 1 && this.isGenerateNewQRCode) {
        this.sendRequestAction('')
        this.iteration = this.iteration + 1
      } else if (this.iteration > 1 && this.iteration < 10) {
        this.sendRequestAction('')
        this.iteration = this.iteration + 1
      } else if (this.iteration === 10) {
        clearInterval(IntervalShortFunction)
        this.setNewQRCode()
        this.sendRequestAction(this.qrCode)
        this.iteration = 1
        this.isGenerateNewQRCode = true
      }
    },
    sendRequestAction (qrCode) {
      if (this.fingerPrint) {
        this.authByCode({
          data: {
            device: [{
              id: '73617948-6f6c-6120-426c-6f6720777000',
              type: 'web'
            }],
            qr_code: qrCode
          },
          fingerprint: this.fingerPrint
        }).then(response => {
          if (response.data && Object.keys(response.data).length === 0 && Object.getPrototypeOf(response.data) === Object.prototype) {} else {
            this.$modal.hide('login-by-code')
            setTimeout(() => {
              this.redirectAfterLoginByQRCode()
            }, 400)
          }
        })
      }
    },
    generateRandomQRCode (length) {
      let result = ''
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      const charactersLength = characters.length
      for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength))
      }
      return result
    },
    setNewQRCode () {
      this.qrCode = 'holanew_' + this.generateRandomQRCode(37)
    },
    redirectAfterLoginByQRCode () {
      if (this.$route.params.nickname) {
        this.$router.push({ name: 'start', params: { nickname: this.$route.params.nickname } }).catch(() => {
          console.log()
        })
      } else {
        this.$router.push({ name: 'home', params: { nickname: this.$route.params.nickname } }).catch(() => {
          console.log()
        })
      }
    },
    beforeClose () {
      clearInterval(IntervalLongFunction)
      clearInterval(IntervalShortFunction)
      this.fingerPrint = ''
      this.qrCode = ''
      this.iteration = 1
      this.isGenerateNewQRCode = false
      clearInterval(timerTicker)
    },
    resetIntervals () {
      clearInterval(IntervalLongFunction)
      clearInterval(IntervalShortFunction)
      clearInterval(timerTicker)
    }
  }
}
</script>
<style lang="scss">
.qr-popup {
  .vm--overlay {
    background: rgba(0,0,0,0.5);
    backdrop-filter: blur(2px);
  }
  .vm--modal{
    @include respondTo(500px) {
      max-width: 95%;
      margin: 0 auto;
      padding: 15px 0;
      left: auto !important;
    }
  }
  &__code{
    img {
      user-select: none;
      margin: 0 auto;
      padding: 15px 0;
    }
  }
}
</style>
<style lang="scss" scoped>
.qr-popup{
  &__label{
    padding: 0 15px;
    text-align: center;
    font-size: 18px;
    line-height: 1.3;
    font-weight: 500;
    color: #3b3b3b;
    margin: 10px auto;
    max-width: 360px;
    user-select: none;
  }
}
</style>
