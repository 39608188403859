import api from '@/api'
import store from '@/store'

const state = {
  profilesData: null,
  profilesLoading: false,
  myProfile: null,
  myProfileLoading: false,
  editedProfile: [],
  editedProfileLoading: false,
  deleteProfileLoading: false,
  currentPost: null,
  fetchPostLoading: false,
  deletePostLoading: false,
  count: 0,
  isNextPageProfilePosts: false
}

const getters = {
  profilesData: state => state.profilesData,
  profilesLoading: state => state.profilesLoading,
  myProfile: state => state.myProfile,
  myProfileLoading: state => state.myProfileLoading,
  editedProfile: state => state.editedProfile,
  editedProfileLoading: state => state.editedProfileLoading,
  deleteProfileLoading: (state) => state.deleteProfileLoading,
  currentPost: (state) => state.currentPost,
  fetchPostLoading: (state) => state.fetchPostLoading,
  deletePostLoading: (state) => state.deletePostLoading,
  count: (state) => state.count,
  isNextPageProfilePosts: (state) => state.isNextPageProfilePosts
}

const actions = {
  async fetchMyProfile ({ commit }, params) {
    try {
      commit('changeProfileLoading', true)
      const response = await api.user.getMyProfile(params)
      commit('setProfileData', response.data)
      return response
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      throw e
    } finally {
      commit('changeProfileLoading', false)
    }
  },
  async fetchMyProfiles ({ commit }, params) {
    try {
      commit('changeProfilesLoading', true)
      const response = await api.user.getMyProfiles(params)
      commit('setProfilesData', response.results)
      commit('setProfilesDataCount', response.count)
      commit('setProfilesDataNextPage', response.next)
      return response
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      throw e
    } finally {
      commit('changeProfilesLoading', false)
    }
  },
  async getEditedProfile ({ commit }, nickname) {
    try {
      commit('changeEditedProfileLoading', true)
      const response = await api.user.getEditedProfile(nickname)
      commit('setEditedProfile', response)
      return response
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      throw e
    } finally {
      commit('changeEditedProfileLoading', false)
    }
  },
  async deleteProfile ({ commit }, nickname) {
    try {
      commit('changeDeleteProfileLoading', true)
      await api.user.deleteProfile(nickname)
      commit('removeProfile', nickname)
    } catch (e) {
      throw e.response
    } finally {
      commit('changeDeleteProfileLoading', false)
    }
  },
  async createPost (_, { profileNickname, data }) {
    let postData = data
    if (data.image) {
      const formData = new FormData()
      for (const key in data) {
        if (data[key] instanceof File) {
          formData.append(key, data[key])
        } else {
          formData.set(key, data[key])
        }
      }
      postData = formData
    }
    await api.user.createPost(profileNickname, postData)
  },
  async fetchPost ({ commit }, { profileNickname, postId }) {
    try {
      commit('changeFetchPostLoading', true)
      const post = await api.user.getPost(profileNickname, postId)
      commit('setPost', post)
    } catch (e) {
      throw e.response
    } finally {
      commit('changeFetchPostLoading', false)
    }
  },
  async updatePost (_, { profileNickname, postId, data }) {
    const formData = new FormData()
    for (const key in data) {
      if (data[key] instanceof File) {
        formData.append(key, data[key])
      } else {
        formData.set(key, data[key])
      }
    }
    await api.user.editPost(profileNickname, postId, formData)
  },
  async deletePost ({ commit }, { postId, nickname }) {
    try {
      commit('changeDeletePostLoading', true)
      await api.user.deletePost(nickname, postId)
      commit('deletePost', postId)
    } catch (e) {
      throw e.response
    } finally {
      commit('changeDeletePostLoading', false)
    }
  },
  async checkUsername (_, nickname) {
    // eslint-disable-next-line no-useless-catch
    try {
      return await api.user.checkUsername(nickname)
    } catch (e) {
      throw e
    }
  },
  async checkHolaUsername (_, nickname) {
    // eslint-disable-next-line no-useless-catch
    try {
      return await api.user.checkHolaUsername(nickname)
    } catch (e) {
      throw e
    }
  },
  async saveProfile ({ commit, dispatch }, params) {
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await api.user.saveProfile(params)
      commit('setProfileData', response.data)
    } catch (e) {
      throw e
    }
  }
}

const mutations = {
  changeProfilesLoading (state, status) {
    state.profilesLoading = status
  },
  setProfilesData (state, data) {
    if (state.profilesData) {
      data.forEach((item) => {
        item.isOwn = item.owner === state.myProfile.userId
        state.profilesData.push(item)
      })
    } else {
      state.profilesData = data
    }
  },
  clearProfilesData (state) {
    state.profilesData = null
  },
  changeProfileLoading (state, status) {
    state.myProfileLoading = status
  },
  changeFetchPostLoading (state, status) {
    state.fetchPostLoading = status
  },
  changeDeletePostLoading (state, status) {
    state.deletePostLoading = status
  },
  setProfileData (state, data) {
    state.myProfile = data
  },
  setProfilesDataCount (state, count) {
    state.count = count
  },
  setProfilesDataNextPage (state, status) {
    state.isNextPageProfilePosts = status
  },
  setEditedProfile (state, data) {
    state.editedProfile = data
  },
  changeEditedProfileLoading (state, status) {
    state.editedProfileLoading = status
  },
  changeDeleteProfileLoading (state, status) {
    state.deleteProfileLoading = status
  },
  removeProfile (state, nickname) {
    const index = state.profilesData.findIndex(item => item.nickname === nickname)
    state.profilesData.splice(index, 1)
  },
  setPost (state, data) {
    state.currentPost = data
  },
  clearPost (state) {
    state.currentPost = null
  },
  deletePost (state, postId) {
    const postIndex = store.getters['profile/profilePosts'].results.findIndex(item => item.id === postId)
    store.getters['profile/profilePosts'].results.splice(postIndex, 1)
    store.getters['profile/profileData'].countPosts -= 1
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}
