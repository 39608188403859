<template>
  <div class="followers">
    <div class="followers-bg" @click.prevent="hideFollowersList"></div>
    <div class="followers-wrap">
      <ul class="followers-tabs">
        <li class="followers-tabs__item"
            :class="{ 'activeTab': followersType === 'followers' }"
            @click.prevent="changeActiveTab('followers')">
          {{ $t('profileFollowers') }}
        </li>
        <li class="followers-tabs__item"
            :class="{ 'activeTab' :followersType === 'pending' }"
            @click.prevent="changeActiveTab('pending')">
          {{ $t('socialProfile.pending') }}
          <div class="followers-count"
               v-if="followersCount > 0">
            {{followersCount}}
          </div>
        </li>
        <li class="followers-tabs__line"
            :class="{'left' : followersType === 'followers' , 'right' : followersType === 'pending' }"></li>
      </ul>
      <div class="followers-content">
        <ul class="followers-list" v-if="followersType === 'followers'">
          <li class="followers-list__item"
              v-for="(item) in followers"
              :key="item.id">
            <div class="followers-list__item-info">
              <div class="followers-list__item-img">
                <Avatar :user="{firstName: item.firstName, lastName: item.lastName}"
                        :size="44"/>
              </div>
              <div class="followers-list__item-name">
                {{ item.firstName }} {{ item.lastName }}
              </div>
            </div>
            <div class="followers-list__item-action">
              <a href="" @click.prevent="removeSubscription(item)">
                <svg fill="#3b3b3b" width="16px" height="16px" aria-hidden="true" focusable="false"
                     data-prefix="fas"
                     data-icon="trash-alt"
                     role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"
                     class="svg-inline--fa fa-trash-alt fa-w-14 MenuBase_icon_vbnBs">
                  <path
                      d="M32 464a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128H32zm272-256a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zM432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16z"
                      class=""></path>
                </svg>
              </a>
            </div>
          </li>
          <li class="followers-list__empty" v-if="!followers.length && !subscribersLoading">
            {{ $t('emptyList') }}
          </li>
          <li class="followers-loader" v-show="subscribersLoading">
            <Loader />
          </li>
        </ul>
        <ul class="pending-list" v-if="followersType === 'pending'">
          <li class="pending-list__item"
              v-for="(item) in pending"
              :key="item.id">
            <div class="pending-list__item-info">
              <div class="pending-list__item-img">
                <Avatar :user="{firstName: item.firstName, lastName: item.lastName}"
                        :size="44"/>
              </div>
              <div class="pending-list__item-name">
                {{ item.firstName }} {{ item.lastName }}
              </div>
            </div>
            <div class="pending-list__item-action">
              <a href=""
                 @click.prevent="manageSubscriptions(item, 1)">
                <svg width="16px" height="16px" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="plus-circle" role="img"
                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
                     class="svg-inline--fa fa-plus-circle fa-w-16 fa-2x">
                  <path fill="#3b3b3b"
                        d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm144 276c0 6.6-5.4 12-12 12h-92v92c0 6.6-5.4 12-12 12h-56c-6.6 0-12-5.4-12-12v-92h-92c-6.6 0-12-5.4-12-12v-56c0-6.6 5.4-12 12-12h92v-92c0-6.6 5.4-12 12-12h56c6.6 0 12 5.4 12 12v92h92c6.6 0 12 5.4 12 12v56z"
                        class=""></path>
                </svg>
              </a>
              <a href=""
                 @click.prevent="manageSubscriptions(item, 2)">
                <svg fill="#3b3b3b" width="16px" height="16px" aria-hidden="true" focusable="false"
                     data-prefix="fas"
                     data-icon="trash-alt"
                     role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"
                     class="svg-inline--fa fa-trash-alt fa-w-14 MenuBase_icon_vbnBs">
                  <path
                      d="M32 464a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128H32zm272-256a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zM432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16z"
                      class=""></path>
                </svg>
              </a>
            </div>
          </li>
          <li class="followers-list__empty" v-if="!pending.length && !subscribersLoading">
            {{ $t('emptyList') }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway'
import Avatar from '@/components/atoms/Avatar.vue'
import Loader from '@/components/atoms/Spinner.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'Followers',
  mixins: [clickaway],
  props: {
    followersCount: {
      type: Number
    }
  },
  data () {
    return {
      isShowFollowerMenu: false,
      followersType: 'followers'
    }
  },
  components: {
    Avatar,
    Loader
  },
  computed: {
    ...mapGetters({
      profile: 'profile/profileData',
      subscribersList: 'profile/subscribersList',
      subscribersLoading: 'profile/subscribersLoading'
    }),
    followers () {
      return this.subscribersList.length ? this.subscribersList.filter(subscription => subscription.status === 1) : []
    },
    pending () {
      return this.subscribersList.length ? this.subscribersList.filter(subscription => subscription.status === 3) : []
    }
  },
  created () {
    this.init()
  },
  methods: {
    ...mapActions({
      fetchSubscribers: 'profile/fetchSubscribers',
      manageSubscription: 'profile/manageSubscription',
      deleteSubscriptions: 'profile/deleteSubscription'
    }),
    ...mapMutations({
      setSubscribers: 'profile/setSubscribersList'
    }),
    init () {
      const profileNickname = this.$route.params.nickname
      this.fetchSubscribers(profileNickname).catch((e) => {
        console.log(e.response)
      })
    },
    changeActiveTab (tab) {
      this.followersType = tab
    },
    hideFollowersList () {
      this.$emit('close', 'close')
    },
    manageSubscriptions (profile, status) {
      const obj = {}
      obj.data = {}
      obj.data.status = status
      obj.subscriptionId = profile.id
      obj.nickname = this.profile.nickname
      this.manageSubscription(obj)
    },
    removeSubscription (item) {
      const text = item.firstName ? item.firstName + ' ' + item.lastName : item.nickname
      const obj = {}
      obj.nickname = this.profile.nickname
      obj.subscriptionId = item.id
      this.$modal.show('confirm', {
        content: this.$t('remove') + ' ' + text + '?',
        onSubmit: () => {
          this.deleteSubscriptions(obj)
        }
      })
    }
  },
  destroyed () {
    this.setSubscribers()
  }
}
</script>

<style lang="scss" scoped>
.followers {
  position: absolute;
  left: 0;
  top: 100%;
  &-bg{
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: transparent;
    z-index: 4;
  }
  &-wrap {
    width: 280px;
    height: 405px;
    background: $white;
    border-radius: 8px;
    box-shadow: 5px 0 40px 0 rgba(34, 60, 80, 0.15);
    padding: 10px;
    position: relative;
    z-index: 5;
  }
  &-tabs {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    &__item {
      width: 50%;
      text-align: center;
      padding: 10px 5px;
      color: #111111;
      font-size: 14px;
      line-height: 1.1;
      transition: color $trans;
      position: relative;
      cursor: pointer;
      @include truncate();

      &:hover {
        color: #00b764;
      }
    }
    &__line {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 50%;
      height: 2px;
      background: #00b764;
      transition: left $trans;
      &.left {
        left: 0;
      }
      &.right {
        left: 50%;
      }
    }
  }
  &-content {
    max-height: calc(100% - 35px);
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 10px;
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
      background: transparent;
      border-radius: 10px;
    }
    &::-webkit-scrollbar {
      width: 5px;
      background: transparent;
      border-radius: 0;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: #00b764;
    }
    .followers-list,
    .pending-list {
      display: flex;
      flex-direction: column;
      padding-top: 10px;
      min-height: 30px;
      &__empty{
        padding: 5px 0;
        text-align: center;
        font-size: 14px;
        line-height: 1.1;
      }
      &__item {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        height: 100%;
        padding: 8px 0;
        border-top: 1px solid #e7e7e7;
        border-bottom: 1px solid #e7e7e7;
        &:first-child {
          border-top: none;
        }
        &-info {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          width: calc(100% - 40px);
        }
        &-action {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          align-items: center;
          align-self: stretch;
          width: 30px;
          margin-left: 10px;
          svg {
            transition: fill $trans;
            path {
              transition: fill $trans;
            }
          }
          a:hover svg {
            fill: #00b764;
            path {
              fill: #00b764;
            }
          }
          a + a {
            margin-left: 10px;
          }
        }
        &-img {
          margin-right: 10px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        &-name {
          color: #111111;
          font-size: 14px;
          line-height: 1;
          width: calc(100% - 40px);
          @include truncate();
        }
      }
    }
  }
  &-loader{
    min-height: 120px;
    background: $white;
  }
}
.followers-count{
  position: absolute;
  border-radius: 8px;
  background-color: #00b764;
  color: #fff;
  font-size: 10px;
  padding: 2px 4px;
  top: 0;
  right: 10px;
  text-align: center;
  min-width: 16px;
  font-weight: 600;
}
</style>
