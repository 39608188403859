import { mapActions, mapGetters, mapMutations } from 'vuex'
import Loader from '@/components/atoms/Spinner.vue'
import TrendingList from './components/TrendingList.vue'

const OFFSET = 50

export default {
  name: 'Trending',
  data () {
    return {
      page: 1
    }
  },
  components: {
    TrendingList,
    Loader
  },
  computed: {
    ...mapGetters({
      trendingList: 'trending/trendingList',
      trendingListCount: 'trending/trendingListCount',
      trendingListLoading: 'trending/trendingListLoading',
      trendingListNextPage: 'trending/trendingListNextPage'
    })
  },
  created () {
    this.getTrendingList({
      limit: OFFSET,
      offset: 0
    })
  },
  methods: {
    ...mapMutations({
      clearTrendingList: 'trending/clearTrendingList'
    }),
    ...mapActions({
      getTrendingList: 'trending/getTrendingList'
    }),
    async infiniteLoading () {
      if (this.trendingListNextPage) {
        this.getTrendingList({
          limit: OFFSET,
          offset: OFFSET * this.page
        })
        this.page += 1
      }
    }
  },
  destroyed () {
    this.clearTrendingList()
  }
}
