<template>
  <div class="mobile-nav">
    <div class="mobile-nav__wrap">
      <div class="mobile-nav__list">
        <router-link :to="$localize({ name: 'home' })" class="mobile-nav__item">
          <icon name="mobileNavigationHome"></icon>
        </router-link>
        <router-link :to="$localize({ name: 'likes' })" class="mobile-nav__item">
          <icon name="mobileNavigationLikes"></icon>
        </router-link>
        <router-link :to="$localize({ name: 'trending' })" class="mobile-nav__item">
          <icon name="mobileNavigationTrending"></icon>
        </router-link>
        <router-link :to="$localize({ name: 'favourites' })" class="mobile-nav__item">
          <icon name="mobileNavigationFavourites"></icon>
        </router-link>
        <a href="" class="mobile-nav__item" @click.prevent="logout">
          <icon name="mobileNavigationSignIn"></icon>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '@/components/atoms/Icon.vue'
export default {
  name: 'MobileNavigation',
  data () {
    return {}
  },
  components: {
    Icon
  },
  methods: {
    logout () {
      this.$modal.show('logout')
    }
  }
}
</script>

<style lang="scss">
.mobile-nav {
  z-index: 12;
  height: 50px;
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100vw;
  background: #000;
  border-top: 1px solid $white;
  &__wrap {
    height: 100%;
  }
  &__list {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  &__item {
    width: 25%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      width: 25px;
      height: 25px;
      path {
        fill: $white;
      }
    }
    &.router-link-exact-active svg path {
      fill: $green;
    }
  }
}
</style>
