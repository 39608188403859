<template>
  <div class="auth-sidebar">
    <div class="auth-sidebar__top">
      <div class="auth-sidebar__title">
        {{ otpResponse.phone }}
      </div>
      <div class="auth-sidebar__description">
        {{ $t('otpDescription') }}
      </div>
      <form @submit.prevent="confirmOtp"
            class="auth-form form">
        <div class="auth-form__short">
          <div class="auth-form__pin-label">
            {{ $t('otpEnterYourOtp') }}
          </div>
          <label :class="otpErrors.length !== 0? 'error': 'valid'">
            <input
              type="number"
              name="otp"
              :autocomplete="false"
              v-model="otp"
              @blur="$v.otp.$touch()"
            >
            <span v-for="(item, index) in otpErrors" :key="index">{{ item }}</span>
          </label>
        </div>
        <div class="auth-form__short"
             v-if="otpResponse && otpResponse.isPinProtected">
          <div class="auth-form__pin-label">
            {{ $t('otpEnterYourPin') }}
          </div>
          <label :class="pinErrors.length !== 0? 'error': 'valid'">
            <input
              type="number"
              name="pin"
              :autocomplete="false"
              v-model="pin"
              @blur="$v.pin.$touch()"
            >
            <span v-for="(item, index) in pinErrors" :key="index">{{ item }}</span>
          </label>
        </div>
        <div class="auth-form__errors">
          <span class="auth-form__errors-item"
                v-for="error in serverErrors"
                :key='error'>
            {{ error }}
          </span>
        </div>
        <div class="auth-btn">
          <base-button
            :disabled="this.$v.$invalid"
            :progress="isLoadingConfirm || isLoadingLogin ">
            {{ $t('verify_otp') }}
          </base-button>
        </div>
        <div class="auth-form__resend">
          <a href="#" @click.prevent="resendOtp"
             :class="{ 'disabled' : showTimeLabel }"
             class="text-decoration-link primary">
            <p v-show="!showTimeLabel">
              {{ $t('otpDontReceiveCode') }}
            </p>
            <span v-show="!showTimeLabel"
                  class="action-link">
              {{ $t('otpRequestSMSAgain') }}
            </span>
            <span v-show="showTimeLabel && isResendOtpText" class="resend-message">
              <svg width="15px" height="15px" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check" role="img"
                   xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-check fa-w-16"><path
                fill="currentColor"
                d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
                class=""></path></svg>
              {{ $t('resendOtpMessage') }}
            </span>
          </a>
          <p class="auth-sidebar__timer" v-show="showTimeLabel">
            {{ $t('otpRequestNewCode') }}: <span>{{ currentTime }}</span>
          </p>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { maxLength, minLength, required } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'

import BaseButton from '@/components/atoms/BaseButton.vue'

const COUNTDOWN_TIME = 30
let timerTicker = null

export default {
  name: 'ConfirmOTP',
  mixin: [validationMixin],
  data () {
    return {
      showTimeLabel: true,
      serverErrors: [],
      otp: '',
      pin: '',
      validationErrors: {},
      isAvailableResendOtp: {},
      showPopup: false,
      currentTime: null,
      timerTicker: null,
      isResendOtpText: false
    }
  },
  components: {
    BaseButton
  },
  validations () {
    if (!this.otpResponse.isPinProtected) {
      return {
        otp: {
          required,
          numeric: true,
          minLength: minLength(6),
          maxLength: maxLength(6)
        }
      }
    } else {
      return {
        otp: {
          required,
          numeric: true,
          minLength: minLength(6),
          maxLength: maxLength(6)
        },
        pin: {
          required,
          numeric: true,
          minLength: minLength(6),
          maxLength: maxLength(6)
        }
      }
    }
  },
  created () {
    this.countdown()
  },
  computed: {
    ...mapGetters({
      otpResponse: 'auth/otpResponse',
      isLoadingConfirm: 'auth/isLoadingConfirm',
      isLoadingLogin: 'auth/isLoadingLogin'
    }),
    otpErrors () {
      const error = []
      if (!this.$v.otp.$dirty) {
        return error
      }
      if (!this.$v.otp.maxLength) {
        error.push(this.$t('validationMaxLengthField').replace(':count', 6))
      }
      if (!this.$v.otp.minLength) {
        error.push(this.$t('validationMinLengthField').replace(':count', 6))
      }
      if (!this.$v.otp.required) {
        error.push(this.$t('validationRequired'))
      }
      if (this.validationErrors.otp) {
        this.validationErrors.otp.forEach((row) => {
          error.push(row)
        })
      }
      return error
    },
    pinErrors () {
      const error = []
      if (!this.$v.pin.$dirty) {
        return error
      }
      if (!this.$v.pin.maxLength) {
        error.push(this.$t('validationMaxLengthField').replace(':count', 6))
      }
      if (!this.$v.pin.minLength) {
        error.push(this.$t('validationMinLengthField').replace(':count', 6))
      }
      if (!this.$v.pin.required) {
        error.push(this.$t('validationRequired'))
      }
      if (this.validationErrors.pin) {
        this.validationErrors.pin.forEach((row) => {
          error.push(row)
        })
      }
      return error
    }
  },
  methods: {
    ...mapMutations({
      saveOtp: 'auth/saveOtpResponse'
    }),
    ...mapActions({
      confirmOtpAction: 'auth/confirmOtp',
      getOtpCode: 'auth/getOtp'
    }),
    countdown () {
      let minutes = Math.floor(COUNTDOWN_TIME / 60)
      let seconds = COUNTDOWN_TIME % 60
      let currentVal = COUNTDOWN_TIME
      timerTicker = setInterval(() => {
        if (!currentVal) {
          this.isAvailableResendOtp.value = true
          clearInterval(timerTicker)
          return
        }
        currentVal--
        seconds = currentVal % 60
        minutes = Math.floor(currentVal / 60)
        this.currentTime = `${minutes < 10 ? `0${minutes}` : minutes}` + ':' + `${seconds < 10 ? `0${seconds}` : seconds}`
        if (this.currentTime === '00:01') {
          this.showTimeLabel = false
        }
      }, 1000)
      this.currentTime = `${minutes < 10 ? `0${minutes}` : minutes}` + ':' + `${seconds < 10 ? `0${seconds}` : seconds}`
    },
    confirmOtp () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.serverErrors = []
        const requestPayload = {
          device: {
            id: '73617948-6f6c-6120-426c-6f6720777000',
            type: 'web'
          },
          phone: this.otpResponse.phone,
          otp: this.otp
        }
        if (this.otpResponse && this.otpResponse.isPinProtected) {
          requestPayload.pinCode = this.pin
        }
        this.confirmOtpAction(requestPayload).then(() => {
          this.$modal.hide('registration')
          if (this.$route.params.nickname) {
            this.$nextTick(() => {
              this.$router.push({ name: 'start', params: { nickname: this.$route.params.nickname } }).catch(() => { console.log() })
            })
          } else {
            this.$router.push({ name: 'home', params: { nickname: this.$route.params.nickname } }).catch(() => { console.log() })
          }
        }).catch(error => {
          console.log(error.response)
          if (error.response.data.data.type === 'otp_unconfirmed') {
            this.$toasted.error(this.$t('otp_unconfirmed'))
            this.resetOtpResponse()
          }
          if (error.response.data.data.type === 'pin_unconfirmed') {
            this.$toasted.error(this.$t('pin_unconfirmed'))
            this.resetOtpResponse()
          }
        })
      }
    },
    resendOtp () {
      if (!this.isAvailableResendOtp.value) return
      this.isAvailableResendOtp.value = false

      this.getOtpCode({ phone: this.otpResponse.phone })
        .then(() => {
          this.countdown()
          this.showTimeLabel = true
          this.isResendOtpText = true
        })
        .catch(error => {
          if (error.status === 400) {
            this.serverErrors = error.data.data.phone
          }
        })
    },
    resetOtpResponse () {
      this.saveOtp()
    }
  },
  destroyed () {
    clearInterval(timerTicker)
  }
}
</script>

<style lang="scss">
.auth-form{
  &__resend{
    margin-top: 30px;
    text-align: center;
    @include respondTo(550px){
      margin-top: 20px;
    }
    .auth-sidebar__timer {
      line-height: 1.3;
    }
    p{
      margin-bottom: 5px;
      text-decoration: none;
      @include respondTo(550px){
        font-size: 14px;
      }
    }
    .action-link {
      text-decoration: underline;
      text-decoration-color: transparent;
      transition: text-decoration $trans;
    }
    a{
      color: $green;
      user-select: none;
      text-decoration: none;
      border-bottom: none;
      @include respondTo(550px){
        font-size: 14px;
      }
      &.disabled{
        pointer-events: none;
        opacity: 0.7;
      }
      &:hover .action-link {
        text-decoration-color: $green;
      }
    }
  }
  input {
    font-family: 'Montserrat', sans-serif;
    width: 100%;
    font-size: 16px;
    line-height: 18px;
    padding: 5px 10px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    transition: border-color $trans;
    height: 42px;
    text-align: center;
    &:focus {
      border-color: rgba(0, 0, 0, 0.4);
    }
    &[type='password'] {
      font-size: 20px;
      padding-bottom: 10px;
      font-weight: bold;

      &::-webkit-input-placeholder {
        color: #b3b3b3;
        opacity: 1;
        font-size: 16px;
        line-height: 1;
      }
      &:-moz-placeholder {
        color: #b3b3b3;
        opacity: 1;
      }
      &::-moz-placeholder {
        color: #b3b3b3;
        opacity: 1;
      }
      &:-ms-input-placeholder {
        color: #b3b3b3;
        opacity: 1;
      }
    }
  }
  &__short{
    margin-bottom: 30px;
  }
  &__pin{
    &-label{
      text-align: center;
      color: $silver-1;
      margin-bottom: 10px;
      @include respondTo(550px){
        font-size: 14px;
        margin-bottom: 5px;
      }
    }
  }
  label{
    &.error{
      span{
        display: block;
        margin-top: 5px;
        animation: shake 1 1s ease-in-out;
        font-size: 12px;
        color: red;
      }
    }
  }
  .auth-btn{
    display: flex;
    justify-content: center;
  }
}
</style>
