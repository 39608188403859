import { mapActions, mapGetters, mapMutations } from 'vuex'
import Loader from '@/components/atoms/Spinner.vue'
import PostListItem from '../profile/components/PostListItem.vue'

const OFFSET = 20

export default {
  name: 'Likes',
  data () {
    return {
      page: 0
    }
  },
  components: {
    Loader,
    PostListItem
  },
  computed: {
    ...mapGetters({
      likesList: 'likes/likesList',
      likesListCount: 'likes/likesListCount',
      likesListLoading: 'likes/likesListLoading',
      profilePosts: 'profile/profilePosts',
      likesListNextPage: 'likes/likesListNextPage'
    })
  },
  created () {
    this.getInitLikeList()
  },
  methods: {
    ...mapMutations({
      clearPostsList: 'profile/clearPostsList'
    }),
    ...mapActions({
      getLikesList: 'likes/getLikesList'
    }),
    getInitLikeList () {
      this.getLikesList({
        limit: OFFSET,
        offset: OFFSET * this.page
      })
      this.page += 1
    },
    async infiniteLoading () {
      if (this.likesListNextPage) {
        await this.getLikesList({
          limit: OFFSET,
          offset: OFFSET * this.page
        })
        this.page += 1
      }
    }
  },
  destroyed () {
    this.clearPostsList(true)
  }
}
