import apiReq from './request'

const SOCIAL_URL = `${process.env.VUE_APP_ROOT_API}/social`

export default {
  getProfileData (nickname) {
    return apiReq.get(`${SOCIAL_URL}/profile/${nickname}/`)
  },
  getProfilePosts (nickname, limit, offset) {
    return apiReq.get(`${SOCIAL_URL}/profile/${nickname}/post/?limit=${limit}&offset=${offset}`)
  },
  likePost (nickname, postId, data) {
    return apiReq.post(`${SOCIAL_URL}/profile/${nickname}/post/${postId}/like/`, data)
  },
  getPostComments (postId, params = {}) {
    return apiReq.get(`${SOCIAL_URL}/profile/${postId}/comments/`, { params })
  },
  createComment (postId, data) {
    return apiReq.post(`${SOCIAL_URL}/profile/${postId}/comments/`, data)
  },
  editComment (postId, commentId, data) {
    return apiReq.patch(`${SOCIAL_URL}/profile/${postId}/comments/${commentId}/`, data)
  },
  deleteComment (postId, commentId) {
    return apiReq.delete(`${SOCIAL_URL}/profile/${postId}/comments/${commentId}/`)
  },
  getCommentReplies (postId, commentId) {
    return apiReq.get(`${SOCIAL_URL}/profile/${postId}/comments/${commentId}/replies/`)
  },
  subscribe (nickname) {
    return apiReq.post(`${SOCIAL_URL}/profile/${nickname}/subscribe/`)
  },
  unsubscribe (nickname) {
    return apiReq.delete(`${SOCIAL_URL}/profile/${nickname}/subscribe/`)
  },
  getSubscribers (nickname) {
    return apiReq.get(`${SOCIAL_URL}/profile/my/${nickname}/subscribers/`)
  },
  manageSubscriptions (obj) {
    return apiReq.patch(`${SOCIAL_URL}/profile/my/${obj.nickname}/subscribers/${obj.subscriptionId}/`, obj.data)
  },
  deleteSubscription (item) {
    return apiReq.delete(`${SOCIAL_URL}/profile/my/${item.nickname}/subscribers/${item.subscriptionId}`)
  },
  createProfile (data) {
    return apiReq.post(`${SOCIAL_URL}/profile/my/`, data)
  },
  editProfile (nickname, data) {
    return apiReq.patch(`${SOCIAL_URL}/profile/my/${nickname}/`, data)
  },
  addToFavourites (obj) {
    return apiReq.post(`${SOCIAL_URL}/profile/favorite-list/${obj.profile_id}/`, obj.data)
  },
  removeFromFavourites (obj) {
    return apiReq.delete(`${SOCIAL_URL}/profile/favorite-list/${obj.profile_id}/${obj.data.article}`)
  }
}
